import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { ClientError, GraphQLClient } from "graphql-request";
import { getAuthToken, getSuperAuthToken } from "~/features/auth/util";
import { safeJsonParse } from "~/util/safeJsonParse";

const GRAPHQL_PATH = "/graphql";

function resolveGraphqlApi() {
  if (import.meta.env.MODE === "production") {
    return import.meta.env.VITE_API_URL + GRAPHQL_PATH;
  }

  if (import.meta.env.MODE === "test") {
    return `http://localhost/${GRAPHQL_PATH}`;
  }

  return GRAPHQL_PATH;
}

export const gqlClient = new GraphQLClient(resolveGraphqlApi(), {
  headers: () => makeRequestHeaders(),
  requestMiddleware: (request) => {
    request.url = `${request.url}?op=${request.operationName}`;
    return request;
  },
  responseMiddleware: (response) => {
    if (response instanceof ClientError) {
      if (response.response.errors?.[0]?.message) {
        // graphql-request internals append the entire error stack to the error message text,
        // thus we need this hack to re-assign the message field with only the message text to be displayed in the UI
        response.message = response.response.errors?.[0]?.message;
      }
    }
    return response;
  },
});

export const api = createApi({
  baseQuery: graphqlRequestBaseQuery({
    client: gqlClient as any,
  }),
  endpoints: () => ({}),
});

export function makeRequestHeaders() {
  const headers = {};
  const authToken = getAuthToken();
  const superAuthToken = getSuperAuthToken();

  if (authToken) {
    Object.assign(headers, {
      Authorization: `Bearer ${authToken}`,
    });
  }

  if (superAuthToken) {
    Object.assign(headers, {
      _su: `Bearer ${superAuthToken}`,
    });
  }

  const orgSlug = safeJsonParse(sessionStorage.getItem("organization"));
  if (orgSlug) {
    Object.assign(headers, {
      Organization: orgSlug,
    });
  }

  return headers;
}
