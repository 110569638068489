import { SVGProps } from "react";

export const SolarCellWarm = (props: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    viewBox="0 0 256 256"
    {...props}
  >
    <path
      d="M0 0h256v256H0z"
      style={{
        fill: "none",
      }}
    />
    <path
      d="M3 189.257v38.323l22.908 23.909H65.4v-62.232H3Z"
      style={{
        fill: "#fdcd00",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M3 127.666h62.4v61.591H3z"
      style={{
        fill: "#ffc700",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M3 66.102h62.4v61.564H3z"
      style={{
        fill: "#ffbc00",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M3 27.778v38.324h62.4V4.511H26.229L3 27.778Z"
      style={{
        fill: "#ffb200",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M65.4 189.257h62.426v62.233H65.4z"
      style={{
        fill: "#ffc900",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M65.4 127.666h62.426v61.591H65.4z"
      style={{
        fill: "#ffb900",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M65.4 66.102h62.426v61.564H65.4z"
      style={{
        fill: "#ffa100",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M65.4 4.511h62.426v61.591H65.4z"
      style={{
        fill: "#ff8d25",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M127.826 189.257h63.096v62.233h-63.096z"
      style={{
        fill: "#ffbf00",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M127.826 127.666h63.096v61.591h-63.096z"
      style={{
        fill: "#fa0",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M127.826 66.102h63.096v61.564h-63.096z"
      style={{
        fill: "#ff862f",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M127.826 4.511h63.096v61.591h-63.096z"
      style={{
        fill: "#fe6d4c",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M190.922 189.257v62.232h38.822L253 227.58v-38.323h-62.078Z"
      style={{
        fill: "#ffb200",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M190.922 127.666H253v61.591h-62.078z"
      style={{
        fill: "#ff892c",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M190.922 66.102H253v61.564h-62.078z"
      style={{
        fill: "#e4485d",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M190.922 4.511v61.591H253V27.778L230.092 4.511h-39.17Z"
      style={{
        fill: "#d8337c",
        fillRule: "nonzero",
      }}
    />
  </svg>
);

export const SolarCellCool = (props: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    viewBox="0 0 256 256"
    {...props}
  >
    <path
      d="M0 0h256v256H0z"
      style={{
        fill: "none",
      }}
    />
    <path
      d="M65.418 4.286h62.416v61.693H65.418z"
      style={{
        fill: "#b60097",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M127.834 4.286h63.082v61.693h-63.082z"
      style={{
        fill: "#8b00ae",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M190.915 4.286v61.693h62.084V27.584L230.092 4.286h-39.177Z"
      style={{
        fill: "#6b00ac",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M190.916 65.977H253v61.693h-62.084z"
      style={{
        fill: "#6b00ac",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M127.834 65.977h63.082v61.693h-63.082z"
      style={{
        fill: "#a300a3",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M65.418 65.977h62.416v61.693H65.418z"
      style={{
        fill: "#c21594",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M65.418 127.67h62.416v61.693H65.418z"
      style={{
        fill: "#cc1f8b",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M127.834 127.67h63.082v61.693h-63.082z"
      style={{
        fill: "#ae009d",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M190.916 127.67H253v61.693h-62.084z"
      style={{
        fill: "#7f00af",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M190.915 189.364v62.35h38.845l23.239-23.957v-38.393h-62.084Z"
      style={{
        fill: "#8f00af",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M127.834 189.364h63.082v62.35h-63.082z"
      style={{
        fill: "#b3009b",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M65.418 189.364h62.416v62.35H65.418z"
      style={{
        fill: "#d0218c",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M3 189.364v38.393l22.91 23.957h39.507v-62.35H3Z"
      style={{
        fill: "#dd3779",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M3.001 127.67h62.417v61.693H3.001z"
      style={{
        fill: "#d9327b",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M3.001 65.977h62.417v61.693H3.001z"
      style={{
        fill: "#d22a82",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M3 27.585v38.393h62.417V4.287H26.239L3 27.585Z"
      style={{
        fill: "#c41892",
        fillRule: "nonzero",
      }}
    />
  </svg>
);
