import { type AppJwtTokenPayload } from "@duet/api/auth/auth.service";
import Cookies from "js-cookie";
export function getAuthToken() {
  return Cookies.get("auth");
}

export function getSuperAuthToken() {
  return Cookies.get("_su");
}

export interface AuthTokenPayload extends AppJwtTokenPayload {
  userId: string;
  _super?: boolean;
  exp: number;
}

export function parseJwtPayload(token: string): AuthTokenPayload {
  return JSON.parse(atob(token.split(".")[1]));
}
