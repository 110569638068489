import {
  MIN_num_tables,
  MIN_num_tables_per_row,
} from "@duet/shared/pvComponents/projectParameters";
import { sumBy } from "lodash";
import { BlockLayer } from "~/gql/generated";
import { ProjectBlockState } from "../reducers/projectBlockSlice";
import { FrameFeature } from "../sceneImport/frameUtil";
import { BlockModel } from "./blockModel";
import { calculateBlockFrameParams } from "./calculateBlockFrameParams";

interface BlockArrayParams {
  num_rows: number;
  num_total_tables: number;
  num_table_per_row: number;
  scaling_factor?: number;
}

export function calculateBlockArrayParams(
  block: BlockModel,
  blockLayers: BlockLayer[],
  sectionStats: ProjectBlockState["sectionStats"],
  frames: FrameFeature[] | undefined
): BlockArrayParams {
  if (frames && frames.length > 0) {
    return calculateBlockFrameParams(block, frames);
  }

  const selectedBlockSections = blockLayers.filter(
    (l) =>
      (l.type === "section" || l.type === "guide_line") &&
      l.block_id === block.id
  );
  const num_rows = sumBy(selectedBlockSections, (l) => {
    return sectionStats[l.id]?.rows ?? 0;
  });
  const num_total_tables = sumBy(selectedBlockSections, (l) => {
    return sectionStats[l.id]?.tables ?? 0;
  });

  const num_table_per_row =
    num_rows === 0 ? 0 : Math.round(num_total_tables / num_rows);

  if (
    num_total_tables >= MIN_num_tables &&
    num_table_per_row < MIN_num_tables_per_row
  ) {
    const rescaledNumRows = Math.round(
      num_total_tables / MIN_num_tables_per_row
    );

    return {
      num_rows: rescaledNumRows,
      num_total_tables,
      num_table_per_row: MIN_num_tables_per_row,
    };
  }

  return {
    num_rows,
    num_total_tables,
    num_table_per_row,
  };
}
