import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  type FormControlProps,
} from "@chakra-ui/react";
import type { FieldError } from "react-hook-form";

interface FormFieldProps extends FormControlProps {
  label: string;
  labelProps?: FormLabelProps;
  helpText?: string;
  error?: string | string[] | FieldError;
  children: React.ReactNode;
  inline?: boolean;
  labelHidden?: boolean;
  errorLabel?: string;
}

export function FormField({
  children,
  helpText,
  error,
  label,
  labelHidden,
  labelProps,
  errorLabel,
  ...formControlProps
}: FormFieldProps) {
  return (
    <FormControl {...formControlProps} isInvalid={Boolean(error)}>
      <FormLabel hidden={labelHidden} aria-hidden={labelHidden} {...labelProps}>
        {label}
      </FormLabel>
      {children}
      <FormError error={error} label={errorLabel ?? label} />
      {helpText && !error && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
}

function FormError({
  error,
  label = "Field",
}: Pick<FormFieldProps, "error" | "label">) {
  if (!error) return null;

  if (Array.isArray(error)) {
    return (
      <>
        {error.map((msg) => (
          <FormErrorMessage key={msg} maxW="inherit">
            {msg}
          </FormErrorMessage>
        ))}
      </>
    );
  }

  // react-hook-form error object
  // message is an attribute but is empty
  const isStandardHookFormError =
    typeof error === "object" &&
    "type" in error &&
    "message" in error &&
    !error.message;

  if (isStandardHookFormError) {
    if (error.type === "required") {
      return (
        <FormErrorMessage whiteSpace={"normal"} maxW="inherit">
          {`${label} is required`}
        </FormErrorMessage>
      );
    }
  }

  return (
    <FormErrorMessage whiteSpace={"normal"} maxW="inherit">
      {typeof error === "string" ? error : error.message}
    </FormErrorMessage>
  );
}
