import { ReactNode } from "react";
import { useParams } from "react-router-dom";
import { useFeatureFlagsQuery } from "~/gql/generated";

interface FeatureFlagProviderProps {
  children: ReactNode;
}

// this isn't really a provider since we use redux for shared state here,
// but i'm sticking with that react naming convention to signify that some hoisted state/logic is in here
export function FeatureFlagProvider({ children }: FeatureFlagProviderProps) {
  const params = useParams<{ projectId: string }>();

  const projectId = params?.projectId;

  // this call produces a side effect that's captured in the featureFlagSlice reducer
  useFeatureFlagsQuery(
    {
      project_id: projectId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  return <>{children}</>;
}
