/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 3
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/enurgen/Enurgen/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'enurgen';

export const ApiKey: Record<Environment, string> = {
  enurgen: 'fe7fa319a8a72a56a3fee5c6a3bd760f'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '3',
    branch: 'main',
    source: 'web',
    versionId: '046270f6-ce21-4fe6-8346-50ab558c4110'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  impersonator?: string;
  organization?: string;
  projectId?: string;
}

export interface AccountEditProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  fields: string[];
}

export interface BaseMapChangedProperties {
  organization: string;
  projectId: string;
}

export interface BlockCreateProperties {
  organization: string;
  projectId: string;
}

export interface BlockDeleteProperties {
  organization: string;
  projectId: string;
}

export interface BlockLayerCreateProperties {
  organization: string;
  projectId: string;
  type: string;
}

export interface BlockLayerDeleteProperties {
  organization: string;
  projectId: string;
  type: string;
}

export interface BlockLayerUpdateProperties {
  organization: string;
  projectId: string;
  type: string;
}

export interface BlockUpdateProperties {
  organization: string;
  projectId: string;
}

export interface ComponentCreateProperties {
  organization: string;
  projectId: string;
  type: string;
}

export interface ComponentDeleteProperties {
  organization: string;
  projectId: string;
  type: string;
}

export interface ComponentUpdateProperties {
  organization: string;
  projectId: string;
  type: string;
}

export interface DataExportProperties {
  organization: string;
  projectId: string;
  scope: string;
  type: string;
}

export interface InverterDatabaseProperties {
  organization: string;
  projectId: string;
}

export interface MeteoCreateProperties {
  organization: string;
  projectId: string;
  type: string;
}

export interface MeteoDeleteProperties {
  organization: string;
  projectId: string;
}

export interface MeteoUpdateProperties {
  organization: string;
  projectId: string;
}

export interface ModuleIrradianceProfilesSelectionProperties {
  date: string;
  frontRear: string;
  organization: string;
  projectId: string;
  table: string;
  timeOfDayIndex: string;
}

export interface OndUploadProperties {
  applyDefaults: boolean;
  organization: string;
  projectId: string;
}

export interface PageRouteProperties {
  organization: string;
  route: string;
}

export interface PanUploadProperties {
  applyDefaults: boolean;
  organization: string;
  projectId: string;
}

export interface PasswordResetProperties {
  email: string;
}

export interface ProjectCollaboratorAddProperties {
  organization: string;
  projectId: string;
}

export interface ProjectCreateProperties {
  organization: string;
  projectId: string;
}

export interface ProjectUpdateProperties {
  organization: string;
  projectId: string;
}

export interface PvsystImportProperties {
  organization: string;
  projectId: string;
}

export interface RasterLayerCreateProperties {
  organization: string;
  projectId: string;
}

export interface RasterLayerDeleteProperties {
  organization: string;
  projectId: string;
}

export interface RasterLayerUpdateProperties {
  organization: string;
  projectId: string;
}

export interface SignUpProperties {
  email: string;
}

export interface SimulationEndProperties {
  organization: string;
  projectId: string;
  status: string;
}

export interface SimulationStartProperties {
  organization: string;
  projectId: string;
}

export interface TerrainEnabledProperties {
  organization: string;
  projectId: string;
}

export interface TimeSeriesViewerIntervalChangedProperties {
  interval: string;
  organization: string;
  projectId: string;
}

export interface TimeSeriesViewerMeasureChangedProperties {
  aggregate: string;
  measure: string;
  organization: string;
  projectId: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccountEdit implements BaseEvent {
  event_type = 'account-edit';

  constructor(
    public event_properties: AccountEditProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BaseMapChanged implements BaseEvent {
  event_type = 'base-map-changed';

  constructor(
    public event_properties: BaseMapChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BlockCreate implements BaseEvent {
  event_type = 'block-create';

  constructor(
    public event_properties: BlockCreateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BlockDelete implements BaseEvent {
  event_type = 'block-delete';

  constructor(
    public event_properties: BlockDeleteProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BlockLayerCreate implements BaseEvent {
  event_type = 'block-layer-create';

  constructor(
    public event_properties: BlockLayerCreateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BlockLayerDelete implements BaseEvent {
  event_type = 'block-layer-delete';

  constructor(
    public event_properties: BlockLayerDeleteProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BlockLayerUpdate implements BaseEvent {
  event_type = 'block-layer-update';

  constructor(
    public event_properties: BlockLayerUpdateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BlockUpdate implements BaseEvent {
  event_type = 'block-update';

  constructor(
    public event_properties: BlockUpdateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ComponentCreate implements BaseEvent {
  event_type = 'component-create';

  constructor(
    public event_properties: ComponentCreateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ComponentDelete implements BaseEvent {
  event_type = 'component-delete';

  constructor(
    public event_properties: ComponentDeleteProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ComponentUpdate implements BaseEvent {
  event_type = 'component-update';

  constructor(
    public event_properties: ComponentUpdateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataExport implements BaseEvent {
  event_type = 'data-export';

  constructor(
    public event_properties: DataExportProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InverterDatabase implements BaseEvent {
  event_type = 'inverter-database';

  constructor(
    public event_properties: InverterDatabaseProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MeteoCreate implements BaseEvent {
  event_type = 'meteo-create';

  constructor(
    public event_properties: MeteoCreateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MeteoDelete implements BaseEvent {
  event_type = 'meteo-delete';

  constructor(
    public event_properties: MeteoDeleteProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MeteoUpdate implements BaseEvent {
  event_type = 'meteo-update';

  constructor(
    public event_properties: MeteoUpdateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ModuleIrradianceProfilesSelection implements BaseEvent {
  event_type = 'module-irradiance-profiles-selection';

  constructor(
    public event_properties: ModuleIrradianceProfilesSelectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OndUpload implements BaseEvent {
  event_type = 'ond-upload';

  constructor(
    public event_properties: OndUploadProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PageRoute implements BaseEvent {
  event_type = 'page-route';

  constructor(
    public event_properties: PageRouteProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PanUpload implements BaseEvent {
  event_type = 'pan-upload';

  constructor(
    public event_properties: PanUploadProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PasswordReset implements BaseEvent {
  event_type = 'password-reset';

  constructor(
    public event_properties: PasswordResetProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProjectCollaboratorAdd implements BaseEvent {
  event_type = 'project-collaborator-add';

  constructor(
    public event_properties: ProjectCollaboratorAddProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProjectCreate implements BaseEvent {
  event_type = 'project-create';

  constructor(
    public event_properties: ProjectCreateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProjectUpdate implements BaseEvent {
  event_type = 'project-update';

  constructor(
    public event_properties: ProjectUpdateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PvsystImport implements BaseEvent {
  event_type = 'pvsyst-import';

  constructor(
    public event_properties: PvsystImportProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RasterLayerCreate implements BaseEvent {
  event_type = 'raster-layer-create';

  constructor(
    public event_properties: RasterLayerCreateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RasterLayerDelete implements BaseEvent {
  event_type = 'raster-layer-delete';

  constructor(
    public event_properties: RasterLayerDeleteProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RasterLayerUpdate implements BaseEvent {
  event_type = 'raster-layer-update';

  constructor(
    public event_properties: RasterLayerUpdateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SignUp implements BaseEvent {
  event_type = 'sign-up';

  constructor(
    public event_properties: SignUpProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SimulationEnd implements BaseEvent {
  event_type = 'simulation-end';

  constructor(
    public event_properties: SimulationEndProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SimulationStart implements BaseEvent {
  event_type = 'simulation-start';

  constructor(
    public event_properties: SimulationStartProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TerrainEnabled implements BaseEvent {
  event_type = 'terrain-enabled';

  constructor(
    public event_properties: TerrainEnabledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TimeSeriesViewerIntervalChanged implements BaseEvent {
  event_type = 'time-series-viewer-interval-changed';

  constructor(
    public event_properties: TimeSeriesViewerIntervalChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TimeSeriesViewerMeasureChanged implements BaseEvent {
  event_type = 'time-series-viewer-measure-changed';

  constructor(
    public event_properties: TimeSeriesViewerMeasureChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * account-edit
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/account-edit)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. fields)
   * @param options Amplitude event options.
   */
  accountEdit(
    properties: AccountEditProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountEdit(properties), options);
  }

  /**
   * base-map-changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/base-map-changed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  baseMapChanged(
    properties: BaseMapChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BaseMapChanged(properties), options);
  }

  /**
   * block-create
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/block-create)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  blockCreate(
    properties: BlockCreateProperties,
    options?: EventOptions,
  ) {
    return this.track(new BlockCreate(properties), options);
  }

  /**
   * block-delete
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/block-delete)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  blockDelete(
    properties: BlockDeleteProperties,
    options?: EventOptions,
  ) {
    return this.track(new BlockDelete(properties), options);
  }

  /**
   * block-layer-create
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/block-layer-create)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  blockLayerCreate(
    properties: BlockLayerCreateProperties,
    options?: EventOptions,
  ) {
    return this.track(new BlockLayerCreate(properties), options);
  }

  /**
   * block-layer-delete
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/block-layer-delete)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  blockLayerDelete(
    properties: BlockLayerDeleteProperties,
    options?: EventOptions,
  ) {
    return this.track(new BlockLayerDelete(properties), options);
  }

  /**
   * block-layer-update
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/block-layer-update)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  blockLayerUpdate(
    properties: BlockLayerUpdateProperties,
    options?: EventOptions,
  ) {
    return this.track(new BlockLayerUpdate(properties), options);
  }

  /**
   * block-update
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/block-update)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  blockUpdate(
    properties: BlockUpdateProperties,
    options?: EventOptions,
  ) {
    return this.track(new BlockUpdate(properties), options);
  }

  /**
   * component-create
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/component-create)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  componentCreate(
    properties: ComponentCreateProperties,
    options?: EventOptions,
  ) {
    return this.track(new ComponentCreate(properties), options);
  }

  /**
   * component-delete
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/component-delete)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  componentDelete(
    properties: ComponentDeleteProperties,
    options?: EventOptions,
  ) {
    return this.track(new ComponentDelete(properties), options);
  }

  /**
   * component-update
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/component-update)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  componentUpdate(
    properties: ComponentUpdateProperties,
    options?: EventOptions,
  ) {
    return this.track(new ComponentUpdate(properties), options);
  }

  /**
   * data-export
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/data-export)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  dataExport(
    properties: DataExportProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataExport(properties), options);
  }

  /**
   * inverter-database
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/inverter-database)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  inverterDatabase(
    properties: InverterDatabaseProperties,
    options?: EventOptions,
  ) {
    return this.track(new InverterDatabase(properties), options);
  }

  /**
   * meteo-create
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/meteo-create)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  meteoCreate(
    properties: MeteoCreateProperties,
    options?: EventOptions,
  ) {
    return this.track(new MeteoCreate(properties), options);
  }

  /**
   * meteo-delete
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/meteo-delete)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  meteoDelete(
    properties: MeteoDeleteProperties,
    options?: EventOptions,
  ) {
    return this.track(new MeteoDelete(properties), options);
  }

  /**
   * meteo-update
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/meteo-update)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  meteoUpdate(
    properties: MeteoUpdateProperties,
    options?: EventOptions,
  ) {
    return this.track(new MeteoUpdate(properties), options);
  }

  /**
   * module-irradiance-profiles-selection
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/module-irradiance-profiles-selection)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. date)
   * @param options Amplitude event options.
   */
  moduleIrradianceProfilesSelection(
    properties: ModuleIrradianceProfilesSelectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new ModuleIrradianceProfilesSelection(properties), options);
  }

  /**
   * ond-upload
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/ond-upload)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. applyDefaults)
   * @param options Amplitude event options.
   */
  ondUpload(
    properties: OndUploadProperties,
    options?: EventOptions,
  ) {
    return this.track(new OndUpload(properties), options);
  }

  /**
   * page-route
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/page-route)
   *
   * Tracks the parameterized route name
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  pageRoute(
    properties: PageRouteProperties,
    options?: EventOptions,
  ) {
    return this.track(new PageRoute(properties), options);
  }

  /**
   * pan-upload
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/pan-upload)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. applyDefaults)
   * @param options Amplitude event options.
   */
  panUpload(
    properties: PanUploadProperties,
    options?: EventOptions,
  ) {
    return this.track(new PanUpload(properties), options);
  }

  /**
   * password-reset
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/password-reset)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  passwordReset(
    properties: PasswordResetProperties,
    options?: EventOptions,
  ) {
    return this.track(new PasswordReset(properties), options);
  }

  /**
   * project-collaborator-add
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/project-collaborator-add)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  projectCollaboratorAdd(
    properties: ProjectCollaboratorAddProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProjectCollaboratorAdd(properties), options);
  }

  /**
   * project-create
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/project-create)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  projectCreate(
    properties: ProjectCreateProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProjectCreate(properties), options);
  }

  /**
   * project-update
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/project-update)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  projectUpdate(
    properties: ProjectUpdateProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProjectUpdate(properties), options);
  }

  /**
   * pvsyst-import
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/pvsyst-import)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  pvsystImport(
    properties: PvsystImportProperties,
    options?: EventOptions,
  ) {
    return this.track(new PvsystImport(properties), options);
  }

  /**
   * raster-layer-create
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/raster-layer-create)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  rasterLayerCreate(
    properties: RasterLayerCreateProperties,
    options?: EventOptions,
  ) {
    return this.track(new RasterLayerCreate(properties), options);
  }

  /**
   * raster-layer-delete
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/raster-layer-delete)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  rasterLayerDelete(
    properties: RasterLayerDeleteProperties,
    options?: EventOptions,
  ) {
    return this.track(new RasterLayerDelete(properties), options);
  }

  /**
   * raster-layer-update
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/raster-layer-update)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  rasterLayerUpdate(
    properties: RasterLayerUpdateProperties,
    options?: EventOptions,
  ) {
    return this.track(new RasterLayerUpdate(properties), options);
  }

  /**
   * sign-up
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/sign-up)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  signUp(
    properties: SignUpProperties,
    options?: EventOptions,
  ) {
    return this.track(new SignUp(properties), options);
  }

  /**
   * simulation-end
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/simulation-end)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  simulationEnd(
    properties: SimulationEndProperties,
    options?: EventOptions,
  ) {
    return this.track(new SimulationEnd(properties), options);
  }

  /**
   * simulation-start
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/simulation-start)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  simulationStart(
    properties: SimulationStartProperties,
    options?: EventOptions,
  ) {
    return this.track(new SimulationStart(properties), options);
  }

  /**
   * terrain-enabled
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/terrain-enabled)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organization)
   * @param options Amplitude event options.
   */
  terrainEnabled(
    properties: TerrainEnabledProperties,
    options?: EventOptions,
  ) {
    return this.track(new TerrainEnabled(properties), options);
  }

  /**
   * time-series-viewer-interval-changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/time-series-viewer-interval-changed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. interval)
   * @param options Amplitude event options.
   */
  timeSeriesViewerIntervalChanged(
    properties: TimeSeriesViewerIntervalChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TimeSeriesViewerIntervalChanged(properties), options);
  }

  /**
   * time-series-viewer-measure-changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/enurgen/Enurgen/events/main/latest/time-series-viewer-measure-changed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. aggregate)
   * @param options Amplitude event options.
   */
  timeSeriesViewerMeasureChanged(
    properties: TimeSeriesViewerMeasureChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TimeSeriesViewerMeasureChanged(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
