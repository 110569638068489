import { SVGProps } from "react";

export const PanelSvgIcon = (p: SVGProps<any>) => {
  return (
    <svg
      id="svg5"
      version="1.1"
      viewBox="0 0 51.379673 36.205673"
      height="20"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g
        id="layer1"
        style={{ display: "inline" }}
        transform="translate(-40.76518,-249.51447)"
      >
        <g id="g3229" transform="translate(0,-8.3623677)">
          <path
            id="rect463"
            style={{ fill: "none", stroke: "#000000", strokeWidth: "2" }}
            d="m 49.381143,258.87683 34.147736,0 c 1.008321,0 1.920322,0.55721 2.046339,1.25763 l 5.540914,30.79762 c 0.212849,1.18307 -0.689261,2.15044 -2.026009,2.15044 l -45.270216,0 c -1.336747,0 -2.238858,-0.96737 -2.02601,-2.15044 l 5.540907,-30.79762 c 0.126017,-0.70042 1.038019,-1.25763 2.046339,-1.25763 z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#000000",
              strokeWidth: "1.5",
              strokeDasharray: "none",
              strokeOpacity: "1",
            }}
            d="M 59.910773,258.79366 57.772335,293.0825"
            id="path2824"
          />
          <path
            style={{
              fill: "none",
              stroke: "#000000",
              strokeWidth: "1.5",
              strokeDasharray: "none",
              strokeOpacity: "1",
            }}
            d="m 72.999225,258.79366 2.138438,34.28884"
            id="path3206"
          />
          <path
            style={{
              fill: "none",
              stroke: "#000000",
              strokeWidth: "1.5",
              strokeDasharray: "none",
              strokeOpacity: "1",
            }}
            d="m 87.064006,268.04125 -41.218015,0"
            id="path3212"
          />
          <path
            style={{
              fill: "none",
              stroke: "#000000",
              strokeWidth: "1.5",
              strokeDasharray: "none",
              strokeOpacity: "1",
            }}
            d="m 89.161707,279.66325 -45.413417,0"
            id="path3218"
          />
        </g>
      </g>
    </svg>
  );
};
