import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { makeRequestHeaders } from "~/gql/baseApi";
import { parseDemoDataCSV } from "./parser";

export const baseUrl =
  import.meta.env.MODE === "production"
    ? `${import.meta.env.VITE_API_URL}/sentinel-demo`
    : "/api/sentinel-demo";

export const sentinelDemoApi = createApi({
  reducerPath: "sentinelDemoApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders(headers, api) {
      const authHeaders = makeRequestHeaders();

      Object.entries(authHeaders).forEach(([key, value]) => {
        headers.set(key, value as string);
      });
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getInverterPowerPerformance: builder.query<
      Array<{
        ts: string;
        dc_power: number;
        ac_power: number;
        loss: number;
      }>,
      any
    >({
      query: () => ({
        url: "/inverter-losses-demo.json",
      }),
    }),
    getStringsetInstantaneousData: builder.query({
      query: () => ({
        url: "/stringset-instantaneous-demo.csv",
        responseHandler: "text",
      }),
      transformResponse: async (response: string) => {
        return parseDemoDataCSV(response);
      },
    }),
    getPlaybackDemo: builder.query({
      query: () => ({
        url: "/playback-demo.csv",
        responseHandler: "text",
      }),
      transformResponse: async (response: string) => {
        return parseDemoDataCSV(response);
      },
    }),
  }),
});

export const {
  useGetInverterPowerPerformanceQuery,
  useGetStringsetInstantaneousDataQuery,
  useGetPlaybackDemoQuery,
} = sentinelDemoApi;
