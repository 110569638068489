import { isNil, padStart, range, sum } from "lodash";
import * as luxon from "luxon";

export const DateTimeFormatTMY = "LLLL d T";
export const DateTimeFormatSequential = "yyyy-LL-dd hh:mm a";

export const daysInMonth: readonly number[] = [
  31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31,
];
export const dayOptions = (month: number) => {
  return range(1, daysInMonth[month - 1] + 1);
};
export const monthOptions: readonly string[] = luxon.Info.months();
export const hours: readonly number[] = Array.from(Array(24).keys());
export const hourOptions = hours.map((_val, i) => {
  return `${padStart(String(i), 2, "0")}:00`;
});

export const toNDay = (month: number, day: number) => {
  return sum(daysInMonth.slice(0, month - 1)) + day;
};

export const toLuxonDate = (
  {
    year,
    month,
    day,
    hour,
    minute = 0,
  }: {
    year?: number;
    month: number;
    day: number;
    hour: number;
    minute?: number;
  },
  { ignoreYear }: { ignoreYear?: boolean } = {}
) => {
  return luxon.DateTime.fromObject({
    year: !ignoreYear && !isNil(year) && !isNaN(year) ? year : undefined,
    month: !isNaN(month) ? month : undefined,
    day: !isNaN(day) ? day : undefined,
    hour: !isNaN(hour) ? hour : undefined,
    minute: !isNaN(minute) ? minute : undefined,
  });
};
