import { ComponentType } from "@duet/shared/pvComponents/projectParameters";
import {
  ComponentFormValue,
  DefaultStateFields,
} from "@duet/shared/pvComponents/types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  compact,
  concat,
  find,
  keys,
  mapValues,
  pick,
  remove,
  set,
} from "lodash";
import {
  PvAcWire,
  PvAcWireUpdateInput,
  PvDcWire,
  PvDcWireUpdateInput,
  PvInverter,
  PvInverterUpdateInput,
  PvPanel,
  PvPanelUpdateInput,
  PvTable,
  PvTableUpdateInput,
  PvTransformer,
  PvTransformerUpdateInput,
} from "~/gql/generated";
import {
  componentTypeMeta,
  validateComponentParameters,
} from "../pvComponents";
import {
  ComponentFieldError,
  ComponentFormField,
  TopLevelComponentFormField,
} from "../pvComponents/types";

export { ComponentType };

export interface ComponentCommon {
  id: string;
  name: string;
  isNew?: boolean;
  hasFieldErrors: boolean;
  fieldErrors: Partial<Record<ComponentFormField, ComponentFieldError>>;
  errorFields: TopLevelComponentFormField[];
}

export interface ComponentModelBase<T extends ComponentType>
  extends ComponentCommon {
  type: T;
  parameters: ComponentFormValue<T>;
  defaultStateFields: DefaultStateFields<T>;
}

export type ComponentModel<T extends ComponentType = any> =
  T extends ComponentType.Panel
    ? ComponentModelBase<ComponentType.Panel>
    : T extends ComponentType.Table
      ? ComponentModelBase<ComponentType.Table>
      : T extends ComponentType.Inverter
        ? ComponentModelBase<ComponentType.Inverter>
        : T extends ComponentType.Transformer
          ? ComponentModelBase<ComponentType.Transformer>
          : T extends ComponentType.HvTransformer
            ? ComponentModelBase<ComponentType.HvTransformer>
            : T extends ComponentType.DcWire
              ? ComponentModelBase<ComponentType.DcWire>
              : T extends ComponentType.AcWire
                ? ComponentModelBase<ComponentType.AcWire>
                : never;

export type RawComponent<T extends ComponentType = any> =
  T extends ComponentType.Panel
    ? PvPanel
    : T extends ComponentType.Table
      ? PvTable
      : T extends ComponentType.Inverter
        ? PvInverter
        : T extends ComponentType.Transformer
          ? PvTransformer
          : T extends ComponentType.HvTransformer
            ? PvTransformer
            : T extends ComponentType.DcWire
              ? PvDcWire
              : T extends ComponentType.AcWire
                ? PvAcWire
                : never;

export type PvComponentUpdateInput<T extends ComponentType = any> =
  T extends ComponentType.Panel
    ? PvPanelUpdateInput
    : T extends ComponentType.Table
      ? PvTableUpdateInput
      : T extends ComponentType.Inverter
        ? PvInverterUpdateInput
        : T extends ComponentType.Transformer
          ? PvTransformerUpdateInput
          : T extends ComponentType.HvTransformer
            ? PvTransformerUpdateInput
            : T extends ComponentType.DcWire
              ? PvDcWireUpdateInput
              : T extends ComponentType.AcWire
                ? PvAcWireUpdateInput
                : never;

export type ComponentData = {
  [K in ComponentType]: {
    items: Array<ComponentModel<K>>;
    loaded: boolean;
  };
};

export interface ProjectComponentState {
  selectedComponent: ComponentModel | null;
  componentsProjectId: string | null;
  componentData: ComponentData;
}

const initialState: ProjectComponentState = {
  selectedComponent: null,
  componentsProjectId: null,
  componentData: {
    [ComponentType.Panel]: { items: [], loaded: false },
    [ComponentType.Table]: { items: [], loaded: false },
    [ComponentType.Inverter]: { items: [], loaded: false },
    [ComponentType.Transformer]: { items: [], loaded: false },
    [ComponentType.HvTransformer]: { items: [], loaded: false },
    [ComponentType.DcWire]: { items: [], loaded: false },
    [ComponentType.AcWire]: { items: [], loaded: false },
  },
};

function findComponent(
  state: ProjectComponentState,
  id: string,
  type: ComponentType
) {
  return find<ComponentModel>(
    state.componentData[type].items,
    (component) => component.id === id
  );
}

function addComponentToState<T extends ComponentType>(
  state: ProjectComponentState,
  type: T,
  component: ComponentModel<T>
) {
  state.componentData[type].items = concat(
    state.componentData[type].items,
    component
  );
}

function removeComponentFromState(
  state: ProjectComponentState,
  id: string,
  type: ComponentType
) {
  remove<ComponentModel>(
    state.componentData[type].items,
    (item) => item.id === id
  );
}

function mapComponent<T extends ComponentType>(
  type: T,
  rawComponent: RawComponent<T>
) {
  const { formSchema } = componentTypeMeta[type];
  const parameters = mapValues(
    pick(rawComponent, keys(formSchema.shape)),
    (val) => val ?? undefined
  ) as ComponentFormValue<T>;

  const { fieldErrors, errorFields, hasFieldErrors } =
    validateComponentParameters(type, parameters);
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const component: ComponentModel = {
    id: rawComponent.id,
    name: rawComponent.name,
    hasFieldErrors,
    fieldErrors,
    errorFields,
    parameters,
    type,
    defaultStateFields: rawComponent.default_state_fields ?? {},
  } as ComponentModel;

  return component as ComponentModel<T>;
}

export const projectComponentSlice = createSlice({
  name: "projectComponent",
  initialState,
  reducers: {
    reloadComponents<T extends ComponentType = any>(
      state: ProjectComponentState,
      action: PayloadAction<{
        type: T;
        items: Array<RawComponent<T>>;
      }>
    ) {
      state.componentData[action.payload.type].items = compact(
        action.payload.items.map((item) =>
          mapComponent(action.payload.type, item)
        )
      );
      state.componentData[action.payload.type].loaded = true;
      if (state.selectedComponent?.type === action.payload.type) {
        state.selectedComponent = null;
      }
    },
    upsertComponents<T extends ComponentType = any>(
      state: ProjectComponentState,
      action: PayloadAction<{
        type: T;
        items: Array<RawComponent<T>>;
      }>
    ) {
      action.payload.items.forEach((rawComponent) => {
        const component = mapComponent(action.payload.type, rawComponent);
        if (!component) {
          return;
        }
        const existingComponent = findComponent(
          state,
          component.id,
          action.payload.type
        );
        if (existingComponent) {
          Object.assign(existingComponent, component);
        } else {
          addComponentToState(state, action.payload.type, component);
        }
      });
    },
    selectComponent(
      state,
      action: PayloadAction<{ type: ComponentType; id: string }>
    ) {
      state.selectedComponent =
        findComponent(state, action.payload.id, action.payload.type) ?? null;
    },

    updateComponent(
      state,
      action: PayloadAction<{
        id: string;
        type: ComponentType;
        data: Partial<Omit<ComponentModel, "id" | "type">>;
      }>
    ) {
      const component = findComponent(
        state,
        action.payload.id,
        action.payload.type
      );
      if (component) {
        Object.assign(component, action.payload.data);
      }
      if (
        state.selectedComponent?.type === action.payload.type &&
        state.selectedComponent.id === action.payload.id
      ) {
        Object.assign(state.selectedComponent, action.payload.data);
      }
    },
    deleteComponent(
      state,
      action: PayloadAction<{ type: ComponentType; id: string }>
    ) {
      removeComponentFromState(state, action.payload.id, action.payload.type);
      if (
        state.selectedComponent?.type === action.payload.type &&
        state.selectedComponent.id === action.payload.id
      ) {
        state.selectedComponent = null;
      }
    },
    clearComponents(state) {
      state.componentData = initialState.componentData;
      state.selectedComponent = null;
    },
    updateSelectedComponentParameter(
      state,
      action: PayloadAction<{
        name: ComponentFormField;
        value: any;
      }>
    ) {
      if (state.selectedComponent) {
        const componentParameters = state.selectedComponent.parameters;
        set(componentParameters, action.payload.name, action.payload.value);
      }
    },
    setComponentsProjectId(state, action: PayloadAction<string>) {
      state.componentsProjectId = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  upsertComponents,
  reloadComponents,
  selectComponent,
  updateComponent,
  deleteComponent,
  clearComponents,
  updateSelectedComponentParameter,
  setComponentsProjectId,
} = projectComponentSlice.actions;
