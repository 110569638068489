import { Center, CenterProps } from "@chakra-ui/react";
import { enurgenDarkActive } from "@duet/ui/theme/color";
import { Path, useLocation } from "react-router-dom";
import { isPathnameActive } from "~/appUrls";
import { RouterLink } from "../Link";

interface AppBarItemProps extends CenterProps {
  to?: string | Path;
  isActive?: (pathname: string | Path, to?: string | Path) => boolean;
}

export default function AppBarItem({
  children,
  to,
  isActive = (a, b) => isPathnameActive(a, b),
  ...props
}: AppBarItemProps) {
  const location = useLocation();

  return (
    <Center
      as={to ? RouterLink : undefined}
      to={to}
      color="white"
      px={4}
      py={1}
      cursor={"pointer"}
      minW={75}
      _hover={{
        background: to ? enurgenDarkActive.toHexString() : undefined,
        cursor: to ? "pointer" : "default",
      }}
      fontSize={"sm"}
      fontFamily={"monospace"}
      textTransform={"uppercase"}
      borderRadius={4}
      background={
        isActive(location.pathname, typeof to === "string" ? to : to?.pathname)
          ? enurgenDarkActive.toHexString()
          : undefined
      }
      {...props}
    >
      {children}
    </Center>
  );
}
