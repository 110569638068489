import { useMemo } from "react";
import { Location, Path } from "react-router-dom";
import { useOrganization } from "./features/organization/useOrganization";

interface AppURLsInit {
  organizationSlug: string;
}
export class AppURLs {
  searchParams: URLSearchParams;
  organizationSlug: string;

  constructor({ organizationSlug }: AppURLsInit) {
    this.searchParams = new URLSearchParams();
    this.organizationSlug = organizationSlug;
  }

  setSearchParams(
    searchParams: ConstructorParameters<typeof URLSearchParams>[0]
  ) {
    this.searchParams = new URLSearchParams(searchParams);
    return this;
  }

  setOrganizationSlug(organizationSlug: string) {
    this.organizationSlug = organizationSlug;
    return this;
  }

  private readonly route = (
    route: string,
    query?: Record<string, string | undefined>
  ) => {
    const validQuery = Object.entries(query ?? {}).reduce<
      Record<string, string>
    >((acc, [key, value]) => {
      if (value) {
        acc[key] = value;
      }
      return acc;
    }, {});

    const searchParams = query
      ? new URLSearchParams(validQuery)
      : this.searchParams;

    return makeRouteBuilder({
      pathname: route,
      search: searchParams.toString(),
      hash: "",
    });
  };

  adminFlags = () => {
    return this.route("/admin/flags");
  };

  adminInviteUser = () => {
    return this.route("/admin/invite");
  };

  adminImpersonateUser = () => {
    return this.route("/admin/impersonate");
  };

  adminInverterDataset = () => {
    return this.route("/admin/inverter");
  };

  indexRoute = () => {
    return this.route("/");
  };

  loginRoute = (referer?: string) => {
    return this.route("/auth/login", {
      referer,
    });
  };

  registerRoute = () => {
    return this.route("/auth/register");
  };

  passwordReset = () => {
    return this.route("/auth/password-reset");
  };

  invitationRegister = (token: string) => {
    return this.route(`/auth/invitation/${token}`);
  };

  emailVerification = () => {
    return this.route("/auth/email-verification");
  };

  private readonly organizationBaseRoute = (pathname: string) => {
    return this.route(`/${this.organizationSlug}${pathname}`);
  };

  private readonly projectBaseRoute = (projectId: string, pathname: string) => {
    return this.organizationBaseRoute(`/project/${projectId}${pathname}`);
  };

  projectCreateRoute = () => {
    return this.organizationBaseRoute("/project-create");
  };

  projectOpenRoute = () => {
    return this.organizationBaseRoute("/project-open");
  };

  projectRoute = (projectId: string) => {
    return this.projectBaseRoute(projectId, "");
  };

  projectDesignRoute = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/design");
  };

  projectDesignBlockRoute = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/design/blocks");
  };

  projectDesignComponentRoute = (
    projectId: string,
    componentType?: string,
    componentId?: string
  ) => {
    return this.projectBaseRoute(
      projectId,
      "/design/components" +
        (componentType ? `/${componentType}` : "") +
        (componentId ? `/${componentId}` : "")
    );
  };

  projectSimRoute = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/compute");
  };

  projectSimBlocksRoute = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/compute/blocks");
  };

  projectSimMeteoRoute = (projectId: string, enviroDatasetId?: string) => {
    return this.projectBaseRoute(
      projectId,
      "/compute/meteo" + (enviroDatasetId ? `/${enviroDatasetId}` : "")
    );
  };

  projectSimulationsIndexRoute = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/simulations");
  };

  projectSimulationRoute(projectId: string, simulationId: string) {
    return this.projectBaseRoute(projectId, `/simulations/${simulationId}`);
  }

  projectSimulationsDetailRoute(
    projectId: string,
    simulationId: string,
    stage?: string
  ) {
    return this.projectBaseRoute(
      projectId,
      `/simulations/${simulationId}` + (stage ? `/${stage}` : "/energy-yields")
    );
  }

  projectCompareIndexRoute = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/compare");
  };

  projectCompareWorkbookRoute = (projectId: string, workbookId: string) => {
    return this.projectBaseRoute(projectId, `/compare/${workbookId}`);
  };

  projectMonitorIndexRoute = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/monitor");
  };

  projectMonitorPlantExplorer = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/monitor/plant-explorer");
  };

  projectMonitorPerformance = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/monitor/performance");
  };

  projectMonitorModelVsActual = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/monitor/model-vs-actual");
  };

  projectMonitorIrradiance = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/monitor/irradiance");
  };

  projectMonitorAlerts = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/monitor/alerts");
  };

  projectMonitorIssues = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/monitor/issues");
  };

  projectMonitorOverview = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/monitor/overview");
  };

  projectMonitorTimeSeriesAnalysis = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/monitor/time-series-analysis");
  };

  projectMonitorStubPage = (projectId: string, title: string) => {
    return this.projectBaseRoute(projectId, `/monitor/${title}`);
  };

  projectMonitorTableIrradianceProfile = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/monitor/table-irradiance");
  };

  projectMonitorPlantPlayback = (projectId: string, date: string) => {
    return this.projectBaseRoute(projectId, `/monitor/plant-playback/${date}`);
  };

  projectPlantPlayback = (projectId: string, id?: string) => {
    return this.projectBaseRoute(
      projectId,
      `/plant-playback${id ? `/${id}` : ""}`
    );
  };

  settingsIndexPage = () => {
    return this.route("/settings");
  };

  settingsUserApiTokens = () => {
    return this.route("/settings/user/api-tokens");
  };

  settingsUserAccountEdit = () => {
    return this.route("/settings/user/account/edit");
  };

  settingsOrganizationEdit = () => {
    return this.organizationBaseRoute("/settings/edit");
  };

  pv3Playground = (scene?: string) => {
    return this.route(`/pv3${scene ? `/${scene}` : ""}`);
  };

  onboardingIntakeForm = () => {
    return this.route("/onboarding/intake-form");
  };

  organizationPicker = () => {
    return this.route("/organization-picker");
  };
}

export function useAppUrls() {
  // purposefully not using useOrganization here as appUrls can be used outside of the OrganizationProvider
  const { organization } = useOrganization();
  return useMemo(
    () =>
      new AppURLs({
        organizationSlug: organization?.slug ?? "organization",
      }),
    [organization?.slug]
  );
}

function stripQuerystring(url: string) {
  return url.replace(/(\?.*)/g, "");
}

export function isPathnameActive(
  aUrl: string | Path,
  bUrl: string | Path = "",
  { startsWith } = { startsWith: false }
) {
  const a = typeof aUrl === "string" ? aUrl : aUrl.pathname;
  const b = typeof bUrl === "string" ? bUrl : bUrl.pathname;
  if (startsWith) {
    return stripQuerystring(a).startsWith(stripQuerystring(b));
  }
  return stripQuerystring(a) === stripQuerystring(b);
}

export function serializeRouterLocation(location: Location) {
  const { pathname, search, hash } = location;
  return `${pathname}${search}${hash}`;
}

interface RouteBuilder extends Path {
  query: (params: Record<string, string | undefined>) => RouteBuilder;
  stripQuery: () => RouteBuilder;
  toString: () => string;
}

function makeRouteBuilder({ pathname, search, hash }: Path): RouteBuilder {
  return {
    pathname,
    search,
    hash,
    query(params: Record<string, string | undefined>) {
      const searchParams = new URLSearchParams(search);
      Object.entries(params).forEach(([key, value]) => {
        if (value) {
          searchParams.set(key, value);
        }
      });
      return makeRouteBuilder({
        pathname,
        search: `?${searchParams.toString()}`,
        hash,
      });
    },
    stripQuery() {
      return makeRouteBuilder({
        pathname,
        search: "",
        hash,
      });
    },
    toString() {
      return `${pathname}${search}${hash}`;
    },
  };
}
