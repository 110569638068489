import { Image, type ImageProps } from "@chakra-ui/react";
import logoSrc from "@duet/ui/assets/enurgen-logo.svg";

export function EnurgenLogo({
  size = "md",
  ...imageProps
}: { size?: "sm" | "md" | "lg" } & ImageProps) {
  let px: string;
  switch (size) {
    case "sm":
      px = "25px";
      break;
    case "md":
      px = "40px";
      break;
    case "lg":
      px = "60px";
      break;
  }
  return <Image src={logoSrc} width={px} height={px} {...imageProps} />;
}

export function DuetLogo(props: ImageProps) {
  return <Image {...props} src="/duet-nue-white.png" />;
}
