import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { ScatterStats } from "../ProjectCompareWorkbookPage";

export type OptionalTuple<T> = [T | undefined, T | undefined];

export const EMPTY_TUPLE: OptionalTuple<any> = [undefined, undefined];

export interface BaseDataset {
  // TODO: support backend src
  source: "filesystem";
  name: string;
  measures: string[];
  dimensions: string[];
}

export interface FileSystemDataset extends BaseDataset {
  source: "filesystem";
  handle: FileSystemFileHandle;
}

export type Dataset = FileSystemDataset;

export interface ProjectCompareState {
  // rn its just local
  cwd: FileSystemDirectoryHandle | undefined;
  datasets: OptionalTuple<Dataset>;
  parameters: OptionalTuple<CompareParams>;
  tags: DataTag[];
}

export type CompareIdbState = Pick<
  ProjectCompareState,
  "cwd" | "datasets" | "parameters" | "tags"
> & {
  id: string;
  idbKey: string;
  name: string;
  createdAt: Date | string;
  stats?: ScatterStats;
};

export interface CompareParams {
  timeDimension?: string;
  timeOffset?: number;
  measure?: string;
  scaleBy?: number;
}

interface BaseCompareDataTag {
  id: string;
  createdAt: Date | string;
  type: "point_selection" | "date_selection" | "local_input_file";
  name: string;
  color: number[];
  isFiltered: boolean;
  isVisible: boolean;
}

export interface PointSelectionDataTag extends BaseCompareDataTag {
  type: "point_selection";
  timestamps: Array<string | Date>;
}

export interface DateSelectionDataTag extends BaseCompareDataTag {
  type: "date_selection";
  dates: Array<string | Date>;
}

export interface InputFileDataTag extends BaseCompareDataTag {
  type: "local_input_file";
  handle: FileSystemFileHandle;
  timeDimension: string;
  timeOffset?: number;
  flagColumn: string;
  criteria?: string;
}

export type DataTag =
  | PointSelectionDataTag
  | DateSelectionDataTag
  | InputFileDataTag;

function getInitialState() {
  const initialState: ProjectCompareState = {
    cwd: undefined,
    datasets: EMPTY_TUPLE,
    parameters: EMPTY_TUPLE,
    tags: [],
  };
  return initialState;
}

type CompareIdx = 0 | 1;

export const projectCompareSlice = createSlice({
  name: "projectCompare",
  initialState: getInitialState,
  reducers: {
    setCwd(
      state,
      action: PayloadAction<FileSystemDirectoryHandle | undefined>
    ) {
      state.cwd = action.payload;
    },

    updateDataset(
      state,
      action: PayloadAction<{
        idx: CompareIdx;
        dataset: Dataset | undefined;
      }>
    ) {
      const { idx, dataset } = action.payload;
      state.datasets[idx] = dataset;
    },

    setDatasets(
      state,
      action: PayloadAction<{
        datasets: OptionalTuple<Dataset>;
      }>
    ) {
      const { datasets } = action.payload;
      state.datasets = datasets;
    },

    setDatasetParameters(
      state,
      action: PayloadAction<{
        parameters: OptionalTuple<CompareParams>;
      }>
    ) {
      const { parameters } = action.payload;
      state.parameters = parameters;
    },

    updateDatasetParameters(
      state,
      action: PayloadAction<{
        idx: CompareIdx;
        parameters: Partial<CompareParams> | undefined;
      }>
    ) {
      const { idx, parameters } = action.payload;
      if (!parameters) {
        state.parameters[idx] = parameters;
        return;
      }
      const cur = state.parameters[idx];

      state.parameters[idx] = {
        ...cur,
        ...parameters,
      };
    },

    swapDatasets(state) {
      state.datasets = [state.datasets[1], state.datasets[0]];
      state.parameters = [state.parameters[1], state.parameters[0]];
    },

    createDataTag(
      state,
      action: PayloadAction<Omit<DataTag, "id" | "name" | "createdAt">>
    ) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const tag = {
        id: uuid(),
        name: `Tag #${state.tags.length + 1}`,
        createdAt: new Date(),
        ...action.payload,
      } as DataTag;

      state.tags.push(tag);
    },
    setDataTags(state, action: PayloadAction<DataTag[]>) {
      state.tags = action.payload;
    },
    updateDataTag(state, action: PayloadAction<Partial<DataTag>>) {
      const idx = state.tags.findIndex((t) => t.id === action.payload.id);
      if (idx < 0) return;

      Object.assign(state.tags[idx], action.payload);
    },
    removeDataTag(state, action: PayloadAction<string>) {
      state.tags = state.tags.filter((t) => t.id !== action.payload);
    },
    resetWorkbookState(state) {
      return getInitialState();
    },
  },
  extraReducers: () => {},
});

export const {
  updateDataset,
  setDatasets,
  setDatasetParameters,
  updateDatasetParameters,
  swapDatasets,
  setCwd,
  createDataTag,
  setDataTags,
  updateDataTag,
  removeDataTag,
  resetWorkbookState,
} = projectCompareSlice.actions;
