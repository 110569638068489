import { defineStyleConfig } from "@chakra-ui/react";

export const tabTheme = defineStyleConfig({
  baseStyle: {
    color: "black",
    _selected: {
      color: "black",
      borderColor: "black",
    },
  },
});
