import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "@duet/ui/theme/theme";
import { Global, css } from "@emotion/react";
import { ErrorBoundary } from "@sentry/react";
import { type ReactNode } from "react";
import { Provider } from "react-redux";
import { Outlet } from "react-router-dom";
import { AuthProvider } from "~/features/auth/AuthProvider";
import { FeatureFlagProvider } from "~/features/featureFlag/FeatureFlagProvider";
import { TrackingRoute } from "~/tracking/TrackingRoute";
import { FallbackFullPage } from "./components/Fallback/Fallback";
import { AppLayout } from "./components/Layout/AppLayout";
import { OrganizationProvider } from "./features/organization/OrganizationProvider";
import { store } from "./redux/store";
import { TrackingAuthUser } from "./tracking/TrackingAuthUser";

// RootProviders is for foundational providers that are required for the app to function
export function RootProviders({ children }: { children: ReactNode }) {
  return (
    <ErrorBoundary fallback={FallbackFullPage}>
      <ChakraProvider
        theme={theme}
        toastOptions={{
          defaultOptions: {
            position: "bottom-right",
            variant: "subtle",
          },
        }}
      >
        <Provider store={store}>
          <Global
            styles={css`
              .stats-gl {
                position: fixed !important;
                margin-top: 120px;
                left: 20px !important;
              }
            `}
          />
          {children}
        </Provider>
      </ChakraProvider>
    </ErrorBoundary>
  );
}

// AppProviders is the root react-router element for the app
// application specific providers, routing dependent providers go here
export function AppProviders({ children }: { children?: ReactNode }) {
  return (
    <AuthProvider>
      <FeatureFlagProvider>
        <OrganizationProvider>
          <AppLayout>
            {children}
            <Outlet />
          </AppLayout>
        </OrganizationProvider>
      </FeatureFlagProvider>

      {/* tracking */}
      <TrackingAuthUser />
      <TrackingRoute />
    </AuthProvider>
  );
}
