import {
  Feature,
  FeatureCollection,
  Polygon,
  bbox,
  bearing,
  booleanCrosses,
  center,
  destination,
  distance,
  envelope,
  featureCollection,
  lineString,
  nearestPoint,
  transformRotate,
} from "@turf/turf";
import { countBy, isNil, map, uniqBy } from "lodash";

import {
  divideIfDefined,
  multiplyIfDefined,
  sumIfDefined,
} from "@duet/shared/utils";
import { FrameFeature, FrameProperties } from "../sceneImport/frameUtil";
import { BlockModel } from "./blockModel";

export function calculateBlockFrameParams(
  block: BlockModel,
  frames: FrameFeature[]
) {
  const design = {
    tables: countBlockFrameTables(block, frames),
    rows: getApproximateRowCount(frames),
  };

  if (isNil(design.tables)) {
    return {
      num_rows: 0,
      num_total_tables: 0,
      num_table_per_row: 0,
    };
  }

  // The bounding box is aligned with the frame's azimuth, so the "width"
  // is the distance spanned by the block's rows.
  const bboxDimensions = getAlignedBboxDimensions(frames);
  const aspectRatio = bboxDimensions.height / bboxDimensions.width;

  const scaledRows = Math.round(Math.sqrt(design.tables / aspectRatio));
  const scaledTables = Math.round(Math.pow(scaledRows, 2) * aspectRatio);
  const scaledTablesPerRow = Math.round(aspectRatio * scaledRows);

  return {
    num_rows: scaledRows,
    num_total_tables: scaledTables,
    num_table_per_row: scaledTablesPerRow,
    scaling_factor: scaledTables / design.tables,
  };
}

export function countBlockFrameTables(
  block: BlockModel,
  frames: FrameFeature[]
) {
  const framePropertyGroups = uniqBy(
    map(frames, "properties"),
    "frameDimensions.group"
  );

  const frameCountsByGroup = countBy(
    frames,
    "properties.frameDimensions.group"
  );

  const tableCounts = map(
    framePropertyGroups,
    ({ frameDimensions, tableMetadata }: FrameProperties) => {
      const framesPerGroup = frameCountsByGroup[frameDimensions.group];
      const tablesPerFrame = divideIfDefined(
        tableMetadata.num_cols,
        block.pv_table?.num_cols
      );

      return multiplyIfDefined(framesPerGroup, tablesPerFrame);
    }
  );

  return tableCounts.reduce(sumIfDefined, 0);
}

export function getApproximateRowCount(frames: FrameFeature[]) {
  // Assuming all frames have the same azimuth
  const bearing = getBearing(frames[0].geometry);
  const bbox = envelope(featureCollection(frames) as FeatureCollection);
  const frameCenters = frames.map((f) => center(f as Feature));
  const origin = nearestPoint(center(bbox), featureCollection(frameCenters));

  // In the worst case the frame nearest to the bounding box center may
  // still be somewhat far away so we'll cast maximum distance of the
  // bounding box.
  const castDistance = distance(
    bbox.geometry.coordinates[0][0],
    bbox.geometry.coordinates[0][1],
    { units: "meters" }
  );

  const start = destination(
    origin.geometry.coordinates,
    castDistance,
    bearing,
    { units: "meters" }
  );

  const end = destination(
    origin.geometry.coordinates,
    castDistance,
    bearing + 180,
    { units: "meters" }
  );

  const line = lineString([
    start.geometry.coordinates,
    end.geometry.coordinates,
  ]);

  const intersectedFrames = frames.filter((feature) => {
    return booleanCrosses(feature, line);
  });

  return intersectedFrames.length;
}

export function getBearing(polygon: Polygon) {
  const [[p0, p1, p2]] = polygon.coordinates;
  const [first, second] = [distance(p0, p1), distance(p1, p2)];

  if (first < second) {
    return bearing(p0, p1);
  }

  return bearing(p1, p2);
}

/**
 * Return the dimensions (in meters) of a bounding box aligned with the frame azimuth
 * @param frames
 * @returns
 */
function getAlignedBboxDimensions(frames: Array<Feature<Polygon>>): {
  width: number;
  height: number;
} {
  const bearing = getBearing(frames[0].geometry);
  const alignedFeatures = transformRotate(
    featureCollection(frames),
    90 - bearing
  );

  const [minX, minY, maxX, maxY] = bbox(alignedFeatures);

  return {
    width: distance([minX, minY], [maxX, minY], { units: "meters" }),
    height: distance([minX, minY], [minX, maxY], { units: "meters" }),
  };
}
