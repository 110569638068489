import { assign } from "lodash";
import { ComponentType } from "../projectParameters";
import { ComponentInputMetadata } from "../types";
import { acWireInputMetadata } from "./acWireInputMetadata";
import { dcWireInputMetadata } from "./dcWireInputMetadata";
import { inverterInputMetadata } from "./inverterInputMetadata";
import { panelInputMetadata } from "./panelInputMetadata";
import { tableInputMetadata } from "./tableInputMetadata";
import { transformerInputMetadata } from "./transformerInputMetadata";

export {
  acWireInputMetadata,
  dcWireInputMetadata,
  inverterInputMetadata,
  panelInputMetadata,
  tableInputMetadata,
  transformerInputMetadata,
};

export const componentInputMetadata: {
  [T in ComponentType]: ComponentInputMetadata<T>;
} = {
  [ComponentType.Panel]: panelInputMetadata,
  [ComponentType.Table]: tableInputMetadata,
  [ComponentType.Inverter]: inverterInputMetadata,
  [ComponentType.Transformer]: transformerInputMetadata,
  [ComponentType.HvTransformer]: transformerInputMetadata,
  [ComponentType.DcWire]: dcWireInputMetadata,
  [ComponentType.AcWire]: acWireInputMetadata,
};

export const inputMetadata: ComponentInputMetadata<ComponentType.Panel> &
  ComponentInputMetadata<ComponentType.Table> &
  ComponentInputMetadata<ComponentType.Inverter> &
  ComponentInputMetadata<ComponentType.Transformer> &
  ComponentInputMetadata<ComponentType.DcWire> &
  ComponentInputMetadata<ComponentType.AcWire> = assign(
  {},
  panelInputMetadata,
  tableInputMetadata,
  inverterInputMetadata,
  transformerInputMetadata,
  dcWireInputMetadata,
  acWireInputMetadata
);
