import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import "./firebase";
import { ampli } from "./tracking/amplitude";
import { sentryInit } from "./tracking/sentry/init";

async function main() {
  sentryInit();

  ampli.load({
    environment: "enurgen",
    disabled: import.meta.env.DEV,
  });
  ampli.client.add(sessionReplayPlugin());

  /**
   * When a new deployment occurs, the hosting service may delete the assets from previous deployments.
   * As a result, a user who visited your site before the new deployment might encounter an import error.
   * This error happens because the assets running on that user's device are outdated and it tries to import the corresponding old chunk, which is deleted.
   * This event is useful for addressing this situation.
   * see https://vite.dev/guide/build#load-error-handling
   * --
   * the root cause of this issue really is an issue with firebase not allowing us to keep previous builds deployed
   * something to look out for when we move to AWS in 2025
   */
  window.addEventListener("vite:preloadError", (event) => {
    event.preventDefault(); // don't throw an error
    window.location.reload(); // for example, refresh the page
  });

  createRoot(document.getElementById("root") as HTMLElement).render(<App />);
}

main().catch(console.error);
