import { parse } from "papaparse";

export function parseDemoDataCSV(
  fileBlob: Blob | File | string
): Promise<Record<string, Array<[string, number]>>> {
  return new Promise((resolve) => {
    const results: Record<string, Array<[string, number]>> = {};
    parse<[string, string, string]>(fileBlob as File, {
      complete: () => {
        resolve(results);
      },
      step: (row) => {
        const [id, ts, val] = row.data;
        if (!results[ts]) {
          results[ts] = [];
        }
        results[ts].push([id, parseFloat(val)]);
      },
    });
  });
}
