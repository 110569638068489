import { Box, Flex } from "@chakra-ui/react";
import { ReactNode } from "react";

interface AppLayoutProps {
  children: ReactNode;
}

export const AppLayout = function AppLayout({ children }: AppLayoutProps) {
  return (
    <>
      <BackgroundSurface />
      <Flex grow={1}>{children}</Flex>
    </>
  );
};

const BackgroundSurface = () => (
  <Box
    position={"fixed"}
    top={0}
    left={0}
    bg="gray.50"
    height="100vh"
    width="100vw"
    zIndex={-1}
    overflow={"hidden"}
  />
);
