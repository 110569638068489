import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  HStack,
  Input,
  Link,
  Spacer,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { FaGoogle, FaMicrosoft } from "react-icons/fa";
import { Navigate, useSearchParams } from "react-router-dom";
import { useAppUrls } from "~/appUrls";
import { FormField } from "~/components/FormField";
import { PasswordInput } from "~/components/FormField/PasswordInput";
import { RouterLink } from "~/components/Link";
import { useAuth } from "~/features/auth/AuthContext";
import { useDocumentTitle } from "~/hooks/useDocumentTitle";
import {
  useFeatureFlags,
  useIsFeatureFlagsLoaded,
} from "../../featureFlag/hooks";
import { AuthError } from "../components/AuthError";
import { AuthFormContainer } from "../components/AuthFormContainer";
import { AuthPageContainer } from "../components/AuthPageContainer";
import { useDevLogin } from "../useDevLogin";

export function LoginPage() {
  const urls = useAppUrls();
  const form = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const { isDirty, isSubmitted } = form.formState;

  const auth = useAuth();

  useDevLogin();

  const { isGaOpenRegistrationEnabled, isGaThirdPartyLoginEnabled } =
    useFeatureFlags(["ga-open-registration", "ga-third-party-login"]);

  const isFeatureFlagsLoaded = useIsFeatureFlagsLoaded();

  useDocumentTitle("Log In");
  const [searchParams] = useSearchParams();
  const referer = searchParams.get("referer");
  if (auth.isLoggedIn) {
    return <Navigate to={referer ?? "/"} />;
  }

  return (
    <AuthPageContainer>
      <AuthFormContainer title="Log In">
        {!isGaOpenRegistrationEnabled && isFeatureFlagsLoaded && (
          <HStack
            as={Link}
            href="https://enurgen.com/duet-designer-waitlist"
            bgColor={"purple.50"}
            py={1}
            px={1}
            borderRadius={"25px"}
            justify={"space-between"}
            _hover={{
              textDecoration: "none",
            }}
          >
            <Tag colorScheme="purple" borderRadius={"25px"}>
              Limited Access
            </Tag>
            <Text fontSize={"sm"} pr={2}>
              Click here to register for limited access.
            </Text>
          </HStack>
        )}

        <Box w="100%">
          <form
            onSubmit={form.handleSubmit(({ username, password }) => {
              auth.firebaseSignInWithEmailAndPassword(username, password);
            })}
          >
            <Stack direction="column" spacing={5}>
              <FormField label="Email">
                <Input
                  type="email"
                  placeholder="user@enurgen.com"
                  {...form.register("username", { required: true })}
                />
              </FormField>

              <FormField label="Password">
                <PasswordInput
                  placeholder="Password"
                  {...form.register("password", { required: true })}
                />
              </FormField>
              <HStack>
                <Spacer />
                <Link as={RouterLink} to={urls.passwordReset()}>
                  Forgot password?
                </Link>
              </HStack>
              {isSubmitted && isDirty && !auth.isLoading && !!auth.err && (
                <AuthError err={auth.err} />
              )}

              <Button
                type="submit"
                variant={"primary"}
                isLoading={auth.isLoading}
              >
                Log In
              </Button>
              {isGaThirdPartyLoginEnabled && (
                <>
                  <Box position="relative" px={10} py={4}>
                    <Divider />
                    <AbsoluteCenter bg="white" px="4">
                      Log In With
                    </AbsoluteCenter>
                  </Box>
                  <Button variant="outline" rightIcon={<FaMicrosoft />}>
                    Microsoft
                  </Button>
                  <Button variant="outline" rightIcon={<FaGoogle />}>
                    Google
                  </Button>
                </>
              )}

              {isGaOpenRegistrationEnabled && (
                <HStack w="100%" justify="center">
                  <Text textAlign={"center"}>Don&lsquo;t have an account?</Text>
                  <Link
                    as={RouterLink}
                    to={urls.registerRoute()}
                    fontWeight={"bold"}
                    textDecoration={"underline"}
                  >
                    Sign up
                  </Link>
                </HStack>
              )}
            </Stack>
          </form>
        </Box>
      </AuthFormContainer>
    </AuthPageContainer>
  );
}
