import * as parameterInfo from "@duet/shared/app_data/DUET-Designer_ParameterInfo.json";
import { ComponentType } from "@duet/shared/pvComponents/projectParameters";
import { ComponentInputMetadata, Unit } from "@duet/shared/pvComponents/types";
import { isNumber } from "lodash";

export const inverterInputMetadata: ComponentInputMetadata<ComponentType.Inverter> =
  {
    ond_file_import: {
      labelText: "Ond File Import",
      hidden: true,
    },
    inverter_data_source: {
      labelText: "Inverter Data Source",
      hidden: true,
    },
    inverter_id: {
      labelText: "Selected Inverter",
      disabledIf: ({ inverter_data_source }) =>
        inverter_data_source === "custom",
    },
    output_ac_voltage: {
      labelText: parameterInfo.output_ac_voltage.display_name,
      helpText: parameterInfo.output_ac_voltage.description,
      unit: parameterInfo.output_ac_voltage.units as Unit,
      disabledIf: ({ inverter_data_source }) => inverter_data_source === "cec",
    },
    rated_output_ac_power: {
      labelText: parameterInfo.rated_ac_output_power.display_name,
      helpText: parameterInfo.rated_ac_output_power.description,
      unit: parameterInfo.rated_ac_output_power.units as Unit,
      displayUnit: "kW",
      disabledIf: ({ inverter_data_source }) => inverter_data_source === "cec",
    },
    reference_dc_power: {
      labelText: parameterInfo.reference_dc_power.display_name,
      helpText: parameterInfo.reference_dc_power.description,
      unit: parameterInfo.reference_dc_power.units as Unit,
      displayUnit: "kW",
      disabledIf: ({ inverter_data_source }) => inverter_data_source === "cec",
    },
    maximum_dc_power: {
      labelText: parameterInfo.maximum_dc_power.display_name,
      helpText: parameterInfo.maximum_dc_power.description,
      unit: parameterInfo.maximum_dc_power.units as Unit,
      displayUnit: "kW",
    },
    self_consumption_power: {
      labelText:
        parameterInfo.power_of_self_consumption_minimum_power.display_name,
      helpText:
        parameterInfo.power_of_self_consumption_minimum_power.description,
      unit: parameterInfo.power_of_self_consumption_minimum_power.units as Unit,
      displayUnit: "kW",
      disabledIf: ({ inverter_data_source }) => inverter_data_source === "cec",
    },
    reference_dc_voltage: {
      labelText: parameterInfo.reference_dc_voltage.display_name,
      helpText: parameterInfo.reference_dc_voltage.description,
      unit: parameterInfo.reference_dc_voltage.units as Unit,
      disabledIf: ({ inverter_data_source }) => inverter_data_source === "cec",
    },
    night_tare_power: {
      labelText: parameterInfo.night_tare_power.display_name,
      helpText: parameterInfo.night_tare_power.description,
      unit: parameterInfo.night_tare_power.units as Unit,
      displayUnit: "kW",
      disabledIf: ({ inverter_data_source }) => inverter_data_source === "cec",
    },
    minimum_dc_voltage: {
      labelText: parameterInfo.minimum_dc_voltage.display_name,
      helpText: parameterInfo.minimum_dc_voltage.description,
      unit: parameterInfo.minimum_dc_voltage.units as Unit,
      disabledIf: ({ inverter_data_source }) => inverter_data_source === "cec",
    },
    maximum_dc_voltage: {
      labelText: parameterInfo.maximum_dc_voltage.display_name,
      helpText: parameterInfo.maximum_dc_voltage.description,
      unit: parameterInfo.maximum_dc_voltage.units as Unit,
      disabledIf: ({ inverter_data_source }) => inverter_data_source === "cec",
    },
    maximum_dc_current: {
      labelText: parameterInfo.maximum_dc_current.display_name,
      helpText: parameterInfo.maximum_dc_current.description,
      unit: parameterInfo.maximum_dc_current.units as Unit,
      disabledIf: ({ inverter_data_source }) => inverter_data_source === "cec",
    },
    maximum_ac_power: {
      labelText: parameterInfo.maximum_ac_power.display_name,
      helpText: parameterInfo.maximum_ac_power.description,
      unit: parameterInfo.maximum_ac_power.units as Unit,
      displayUnit: "kW",
      hideIf: ({ inverter_data_source }) => inverter_data_source === "cec",
      errorIf: ({ maximum_ac_power, rated_output_ac_power }) => {
        if (
          isNumber(maximum_ac_power) &&
          isNumber(rated_output_ac_power) &&
          maximum_ac_power < rated_output_ac_power
        ) {
          return {
            message: `must be no less than ${parameterInfo.rated_ac_output_power.display_name}`,
          };
        }
      },
    },
    minimum_operating_temperature: {
      labelText: parameterInfo.minimum_operating_temperature.display_name,
      helpText: parameterInfo.minimum_operating_temperature.description,
      unit: parameterInfo.minimum_operating_temperature.units as Unit,
    },
    maximum_operating_temperature: {
      labelText: parameterInfo.maximum_operating_temperature.display_name,
      helpText: parameterInfo.maximum_operating_temperature.description,
      unit: parameterInfo.maximum_operating_temperature.units as Unit,
    },
    sandia_model_c0: {
      labelText: parameterInfo.sandia_model_coefficient_c0.display_name,
      helpText: parameterInfo.sandia_model_coefficient_c0.description,
      unit: parameterInfo.sandia_model_coefficient_c0.units as Unit,
      disabledIf: ({ inverter_data_source }) => inverter_data_source === "cec",
    },
    sandia_model_c1: {
      labelText: parameterInfo.sandia_model_coefficient_c1.display_name,
      helpText: parameterInfo.sandia_model_coefficient_c1.description,
      unit: parameterInfo.sandia_model_coefficient_c1.units as Unit,
      disabledIf: ({ inverter_data_source }) => inverter_data_source === "cec",
    },
    sandia_model_c2: {
      labelText: parameterInfo.sandia_model_coefficient_c2.display_name,
      helpText: parameterInfo.sandia_model_coefficient_c2.description,
      unit: parameterInfo.sandia_model_coefficient_c2.units as Unit,
      disabledIf: ({ inverter_data_source }) => inverter_data_source === "cec",
    },
    sandia_model_c3: {
      labelText: parameterInfo.sandia_model_coefficient_c3.display_name,
      helpText: parameterInfo.sandia_model_coefficient_c3.description,
      unit: parameterInfo.sandia_model_coefficient_c3.units as Unit,
      disabledIf: ({ inverter_data_source }) => inverter_data_source === "cec",
    },
    cec_efficiency_curves: {
      labelText: "CEC Efficiency Curves",
      disabledIf: ({ inverter_data_source }) => inverter_data_source === "cec",
    },
    temp_derate_curves: {
      labelText: "Temperature Derate Curves",
      disabledIf: ({ inverter_data_source }) => inverter_data_source === "cec",
    },
  };
