import { SVGProps } from "react";
export const DcWireSvgIcon = (p: SVGProps<any>) => {
  return (
    <svg
      id="svg5"
      version="1.1"
      viewBox="0 0 74.233086 25.950174"
      height="20"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <defs id="defs2" />
      <g
        id="layer1"
        style={{ display: "inline" }}
        transform="translate(-40.35492,-13.548303)"
      >
        <g
          id="g7140"
          transform="translate(-1.9835073,-35.669889)"
          style={{ strokeWidth: "2", strokeDasharray: "none" }}
        >
          <path
            style={{
              fill: "none",
              fillOpacity: "1",
              stroke: "#000000",
              strokeWidth: "2",
              strokeDasharray: "none",
            }}
            d="m 114.57152,62.193279 h -9.71199 L 101.24141,72.22175 94.005185,52.164809 86.768956,72.22175 79.532726,52.164809 72.296499,72.22175 65.060269,52.164809 57.824041,72.22175 54.205928,62.193277 l -9.866357,0.02256"
            id="path4167"
          />
          <circle
            style={{
              fill: "#ffffff",
              fillOpacity: "1",
              stroke: "#000000",
              strokeWidth: "2",
              strokeDasharray: "none",
            }}
            id="path6407"
            cx="44.338428"
            cy="62.193279"
            r="1"
          />
          <circle
            style={{
              fill: "#ffffff",
              fillOpacity: "1",
              stroke: "#000000",
              strokeWidth: "2",
              strokeDasharray: "none",
            }}
            id="circle6409"
            cx="114.57152"
            cy="62.193279"
            r="1"
          />
        </g>
      </g>
    </svg>
  );
};
