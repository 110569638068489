import * as parameterInfo from "@duet/shared/app_data/DUET-Designer_ParameterInfo.json";
import {
  ComponentType,
  TransformerPowerLossDataSource,
} from "@duet/shared/pvComponents/projectParameters";
import { ComponentInputMetadata, Unit } from "@duet/shared/pvComponents/types";

export const transformerInputMetadata: ComponentInputMetadata<
  ComponentType.Transformer | ComponentType.HvTransformer
> = {
  night_disconnect: {
    labelText: parameterInfo.night_disconnect.display_name,
    helpText: parameterInfo.night_disconnect.description,
  },
  nominal_low_side_voltage: {
    labelText: parameterInfo.nominal_lowside_voltage.display_name,
    helpText: parameterInfo.nominal_lowside_voltage.description,
    unit: parameterInfo.nominal_lowside_voltage.units as Unit,
  },
  nominal_high_side_voltage: {
    labelText: parameterInfo.nominal_highside_voltage.display_name,
    helpText: parameterInfo.nominal_highside_voltage.description,
    unit: parameterInfo.nominal_highside_voltage.units as Unit,
  },
  iron_power_loss_data_source: {
    labelText: "Iron Power Loss Data Source",
  },
  iron_power_loss: {
    labelText: "Iron Power Losses",
    unit: "W",
    disabledIf: ({ iron_power_loss_data_source }) =>
      iron_power_loss_data_source === TransformerPowerLossDataSource.Generic,
  },
  iron_power_loss_percentage: {
    labelText: "Iron Power Loss Percentage at STC",
    unit: "%",
    disabledIf: ({ iron_power_loss_data_source }) =>
      iron_power_loss_data_source === TransformerPowerLossDataSource.Datasheet,
  },
  copper_power_loss_data_source: {
    labelText: "Copper Power Loss Data Source",
  },
  low_voltage_winding_resistance: {
    labelText: "Low Voltage Winding Resistance",
    unit: "Ω",
    disabledIf: ({ copper_power_loss_data_source }) =>
      copper_power_loss_data_source === TransformerPowerLossDataSource.Generic,
  },
  high_voltage_winding_resistance: {
    labelText: "High Voltage Winding Resistance",
    unit: "Ω",
    disabledIf: ({ copper_power_loss_data_source }) =>
      copper_power_loss_data_source === TransformerPowerLossDataSource.Generic,
  },
  copper_power_loss_percentage: {
    labelText: "Copper Power Loss Percentage at STC",
    unit: "%",
    disabledIf: ({ copper_power_loss_data_source }) =>
      copper_power_loss_data_source ===
      TransformerPowerLossDataSource.Datasheet,
  },
};
