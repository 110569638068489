import { useActiveUser } from "~/features/user/hooks";
import { useAppSelector } from "~/redux/store";

export function useUserOrganizations() {
  const { user, isLoading } = useActiveUser();
  const userOrganizations =
    user?.user_organization.map((uo) => uo.organization) ?? [];

  return {
    userOrganizations,
    isLoading,
  };
}

export function useOrganization() {
  return useAppSelector((state) => state.organization);
}
