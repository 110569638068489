import * as Sentry from "@sentry/react";

const echartsNoisyErrors = [
  "Cannot read properties of undefined (reading 'getRawIndex')",
  "Cannot read properties of undefined (reading 'getItemModel')",
  "Failed to execute 'addColorStop' on 'CanvasGradient': The value provided ('undefined') could not be parsed as a color.",
];

export function sentryInit() {
  const isSuper = document.cookie.includes("_su=");
  Sentry.init({
    dsn: "https://0dd552d4fe2f44c89d78221afcfde616@o4505128366833664.ingest.sentry.io/4505128370176000",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 0.25,
    replaysSessionSampleRate: isSuper ? 0 : 0.25,
    replaysOnErrorSampleRate: 1.0,
    environment:
      import.meta.env.MODE === "production" ? "production" : "development",
    enabled: import.meta.env.MODE === "production",

    ignoreErrors: [...echartsNoisyErrors],
  });
}
