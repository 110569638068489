import { useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { serializeRouterLocation, useAppUrls } from "~/appUrls";
import { LoadingOverlay } from "~/components/LoadingOverlay/LoadingOverlay";
import { useFeatureFlags } from "../featureFlag/hooks";
import { useActiveUser } from "../user/hooks";
import { useAuth } from "./AuthContext";

export function AuthRoute({ component }: { component: React.ReactNode }) {
  const { isLoggedIn, authTokenParsed } = useAuth();
  const { isGaOpenRegistrationEnabled } = useFeatureFlags([
    "ga-open-registration",
  ]);
  const urls = useAppUrls();
  const location = useLocation();
  const { user, isLoading } = useActiveUser();
  const wasLoggedIn = useRef(false);
  const pageContent = <>{component}</>;

  if (isLoggedIn) {
    wasLoggedIn.current = true;
  }

  if (!isLoggedIn) {
    if (wasLoggedIn.current) {
      // if we were previously logged in and now we're not, we should redirect to the login page and ignore the current location
      return <Navigate to={urls.loginRoute()} />;
    }
    // if we're visiting a link directily and not logged in we should redirect to the login page
    // we are passing the current location as the referer
    return <Navigate to={urls.loginRoute(serializeRouterLocation(location))} />;
  }

  if (isLoading) {
    return <LoadingOverlay isLoading />;
  }

  if (!isGaOpenRegistrationEnabled) {
    return pageContent;
  }

  // all the following checks are for the open registration onboarding flow
  const shouldDoEmailVerification =
    authTokenParsed?.intercept === "email_verification";

  if (shouldDoEmailVerification) {
    if (location.pathname === urls.emailVerification().pathname) {
      return pageContent;
    }

    return <Navigate to={urls.emailVerification()} />;
  }

  const shouldDoOnboardingIntake = !user?.has_lead_intake_form;

  if (shouldDoOnboardingIntake) {
    if (location.pathname === urls.onboardingIntakeForm().pathname) {
      return pageContent;
    }
    return <Navigate to={urls.onboardingIntakeForm().toString()} />;
  }

  return pageContent;
}
