import { Alert, AlertDescription, AlertTitle } from "@chakra-ui/react";
import { FirebaseError } from "firebase/app";
import { AuthErrorCode } from "~/gql/generated";
import { AuthContextValue } from "../AuthContext";

interface AuthErrorProps {
  err: AuthContextValue["err"];
}

export function AuthError({ err }: AuthErrorProps) {
  if (!err) return null;

  const { code, message } = getErrorContent(err);

  return (
    <Alert status="error">
      <AlertTitle>{code}</AlertTitle>
      <AlertDescription>{message}</AlertDescription>
    </Alert>
  );
}

function getErrorContent(err: AuthErrorProps["err"]) {
  if (err && "__typename" in err) {
    switch (err.code) {
      case AuthErrorCode.IdentityNotFound:
      case AuthErrorCode.LocalPasswordNotSet:
      case AuthErrorCode.IncorrectPassword:
      case AuthErrorCode.UserNotFound:
        return {
          code: "INVALID_LOGIN",
          message: "Invalid login credentials.",
        };
      case AuthErrorCode.UserAlreadyExists:
        return {
          code: AuthErrorCode.UserAlreadyExists,
          message: "A user with this email already exists.",
        };
      case AuthErrorCode.InvalidEmailDomain:
        return {
          code: AuthErrorCode.InvalidEmailDomain,
          message: "Free and public email domains are currently restricted.",
        };
      case AuthErrorCode.InvalidInvite:
        return {
          code: AuthErrorCode.InvalidInvite,
          message: "The invitation code provided is invalid.",
        };
      case AuthErrorCode.InvalidPassword:
        return {
          code: AuthErrorCode.InvalidPassword,
          message: err.message,
        };
      case AuthErrorCode.InviteEmailMismatch:
        return {
          code: AuthErrorCode.InviteEmailMismatch,
          message:
            "The invitation code provided does not match the provided email.",
        };
    }
  }

  if (err instanceof FirebaseError) {
    switch (err.code) {
      case "auth/weak-password":
        return {
          code: "Password Strength",
          message: "Password must be at least 6 characters",
        };

      case "auth/email-already-in-use":
        return {
          code: AuthErrorCode.UserAlreadyExists,
          message: "A user with this email already exists.",
        };

      case "auth/invalid-login-credentials":
        return {
          code: AuthErrorCode.InvalidPassword,
          message: "Invalid username or password",
        };

      default:
        return {
          code: err.code,
          message: err.message,
        };
    }
  }

  return {
    code: "Unknown",
    message: "An unknown error has occurred.",
  };
}
