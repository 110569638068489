import { isEmpty, uniq } from "lodash";
import { FieldPath } from "react-hook-form";
import { z } from "zod";
import { IVCurveInputField, PanelFormValue } from "./types";
import { isEmptyNumberInput } from "./utils";

export const MIN_num_rows = 3; // minimum num_rows supported by the RepArray paradigm in duet
export const MIN_num_tables_per_row = 3; // minimum numSimTables supported by the RepArray paradigm in duet
export const MIN_num_tables = MIN_num_rows * MIN_num_tables_per_row;
export const MAX_num_post_cols = 9;
export const NUM_CEC_EFFICIENCY_CURVES = 3;
export const NUM_TEMP_DERATE_CURVES = 5;
export const DEFAULT_latitude = 45.4096858;
export const DEFAULT_longitude = -75.7280263;
export const DEFAULT_cells = [12, 6];
export const dateOfMostRecentCleaningFormat = "yyyy-MM-dd";

export enum ComponentType {
  Panel = "panel",
  Table = "table",
  Inverter = "inverter",
  Transformer = "transformer",
  HvTransformer = "hvTransformer",
  DcWire = "dcWire",
  AcWire = "acWire",
}

export enum MechanicalShapeEnum {
  Rect = "rect",
  Round = "round",
}

export enum CellShapeEnum {
  RECT = "rect",
  OCT = "oct",
}

export enum CellType {
  Full = "full",
  Half = "half",
}

export enum PanelOrientationEnum {
  PORTRAIT = "portrait",
  LANDSCAPE = "landscape",
}

export enum PanelTypeEnum {
  Bifacial = "bi",
  Monofacial = "mono",
}

export enum PanelTechnologyEnum {
  Silicon = "silicon",
  CdTe = "cdte",
}

export enum LineMetalType {
  Al = "Al",
  Cu = "Cu",
}

export enum TransformerPowerLossDataSource {
  Datasheet = "Datasheet",
  Generic = "Generic",
}

export enum LineLossCalculationMethod {
  PercentageLoss = "percentage_loss",
  ComputedResistance = "computed_resistance",
  TotalResistance = "total_resistance",
}

export const ivCurveInputFields: Readonly<
  Array<FieldPath<Pick<PanelFormValue, IVCurveInputField>>>
> = [
  "ideality_factor",
  "short_circuit_current",
  "open_circuit_voltage",
  "series_resistance",
  "shunt_resistance",
  "cells.0",
  "cells.1",
  "parallel_string_per_diode.0",
  "parallel_string_per_diode.1",
];

export const tempC = z.number().gte(-273.15);

export const pvArraySchema = z.object({
  orientation_str: z.nativeEnum(PanelOrientationEnum),
  azimuth: z.number().min(-180).max(180),
  ground_clearance: z.number().min(0),
  row_spacing: z.number().nonnegative(),
  // TODO: support later
  // row_offset: z.array(z.number().nonnegative()).optional(),
  column_gap: z.number().min(0),
  num_tiers: z.number().positive().int(),
  tier_gap: z.number().nonnegative(),
  num_cols: z.number().positive().min(3).int(),
  table_gap: z.number().positive(),
});

export const postsSchema = z.object({
  vertical_post_enabled: z.boolean(),
  vertical_post_shape: z.nativeEnum(MechanicalShapeEnum),
  num_post_cols: z.number().nonnegative().max(MAX_num_post_cols).int(),
  num_post_tiers: z.number().nonnegative().int(),
  vertical_post_radius: z.number().positive(),
  vertical_post_dimensions: z.tuple([
    z.number().nonnegative(),
    z.number().nonnegative(),
  ]),
  vertical_post_distance: z.array(z.number()).optional(),
});

export const rackingSchema = z.object({
  in_plane_racking_enabled: z.boolean(),
  in_plane_racking_shape: z.nativeEnum(MechanicalShapeEnum),
  in_plane_racking_dimensions: z.tuple([
    z.number().nonnegative(),
    z.number().nonnegative(),
    z.number().nonnegative(),
  ]),
});

export const torqueTubeSchema = z.object({
  torque_tube_enabled: z.boolean(),
  torque_tube_shape: z.nativeEnum(MechanicalShapeEnum),
  torque_tube_radius: z.number().min(0),
  torque_tube_rect_dimensions: z.tuple([z.number().min(0), z.number().min(0)]),
  rotation_radius: z.number().min(0), // TODO: must also be greater than or equal to torque_tube_radius
});

export const trackerSchema = z.object({
  backtracking_enabled: z.boolean(),
  stow_angle: z.number().min(-90).max(90),
  track_angle_bounds: z.tuple([
    z.number().min(-90).max(90),
    z.number().min(-90).max(90),
  ]),
  tilt: z.number().min(0).max(90),
  tracking_type: z.number().min(0).max(2).int(),
  torque_tube_height: z.number().min(0),
});

export const cellSchema = z.object({
  parallel_string_per_diode: z.tuple([
    z.number().positive().int(),
    z.number().positive().int(),
  ]),
  bypass_diodes: z.tuple([
    z.number().positive().int(),
    z.number().positive().int(),
  ]),
  bifaciality_current_ratio: z.number().min(0).max(1),
  temperature_coefficient_isc: z.number(),
  temperature_coefficient_voc: z.number(),
  bypass_diode_max_neg_v: z.number().nonpositive(),
  nominal_operating_cell_temp: tempC,
  irradiance_coefficients_temp: z.tuple([
    z.number().nonnegative(),
    z.number().nonnegative(),
  ]),
});

export const incidenceAngle = z.number().gte(0).lte(90);
export const iam = z.number().lte(1).gte(0);

const iamPoint = z.tuple([incidenceAngle, iam]);

function assertXValuesUnique(points: Array<[number, number]>) {
  if (!points) {
    return true;
  }
  const xValues = points.map((point) => point[0]);
  return uniq(xValues).length === xValues.length;
}

export const iamProfileFieldSchema = z
  .array(iamPoint)
  .min(4, { message: "Must contain at least 4 points" })
  .refine((points) => !points || assertXValuesUnique(points), {
    message: "Incidence angles values must be unique",
  });

export const layoutSchema = z.object({
  panel_technology: z.nativeEnum(PanelTechnologyEnum),
  panel_type: z.nativeEnum(PanelTypeEnum),
  cells: z.tuple([z.number().int().positive(), z.number().int().positive()]),
  cell_spacing_lr: z.number().nonnegative(),
  cell_spacing_tb: z.number().nonnegative(),
  half_cut_center_space: z.number().nonnegative(),
  cell_length: z.number().positive(),
  cell_width: z.number().positive(),
  cell_type: z.enum(["full", "half"]),
  cell_shape: z.nativeEnum(CellShapeEnum),
  corner_cut_off: z.number().positive(),
  panel_edge_b: z.number().nonnegative(),
  panel_edge_t: z.number().nonnegative(),
  panel_edge_lr: z.number().nonnegative(),
  frame: z.boolean(),
  frame_dimensions: z.tuple([
    z.number().positive(),
    z.number().positive(),
    z.number().positive(),
  ]),
});

export const ivSchema = z.object({
  short_circuit_current: z.number(),
  open_circuit_voltage: z.number(),
  shunt_resistance: z.number(),
  series_resistance: z.number(),
  ideality_factor: z.number(),
});

export const iamSchema = z.object({
  pan_file_import: z.any().nullish(),
  iam_profile: iamProfileFieldSchema,
});

export const dcPower = z.number().gt(0);
export const acPower = z.number().gte(0);

export const inverterSchema = z.object({
  ond_file_import: z.any().nullish(),
  inverter_data_source: z.union([z.literal("cec"), z.literal("custom")]),
  inverter_id: z.string(),
  output_ac_voltage: z.number().positive(),
  rated_output_ac_power: z.number().positive(),
  maximum_ac_power: z.number().positive().nullish(),
  reference_dc_power: z.number().positive(),
  reference_dc_voltage: z.number().positive(),
  maximum_dc_power: z.number().positive().nullish(),
  self_consumption_power: z.number().nonnegative(),
  night_tare_power: z.number().positive(),
  maximum_dc_current: z.number().positive().nullish(),
  minimum_dc_voltage: z.number().positive().nullish(),
  maximum_dc_voltage: z.number().positive().nullish(),
  minimum_operating_temperature: tempC.nullish(),
  maximum_operating_temperature: tempC.nullish(),
  sandia_model_c0: z.number().optional(),
  sandia_model_c1: z.number().optional(),
  sandia_model_c2: z.number().optional(),
  sandia_model_c3: z.number().optional(),
  cec_efficiency_curves: z
    .array(
      z
        .object({
          input_voltage: z.number().positive().optional().nullable(),
          points: z
            .array(
              z.tuple([dcPower, acPower]).refine(
                ([dc, ac]) => ac <= dc,
                () => ({
                  message: "must be equal or less than DC power",
                  path: ["1"],
                })
              )
            )
            .refine((points) => !points || assertXValuesUnique(points), {
              message: "DC power values must be unique",
            }),
        })
        .refine(
          ({ input_voltage, points }) =>
            !(!isEmpty(points) && isEmptyNumberInput(input_voltage)),
          () => {
            return {
              path: [`input_voltage`],
              message: " ",
            };
          }
        )
        .refine(
          ({ points, input_voltage }) =>
            !(isEmpty(points) && !isEmptyNumberInput(input_voltage)),
          () => {
            return {
              message: "Must contain at least 1 point",
              path: [`points`],
            };
          }
        )
    )
    .optional(),
  temp_derate_curves: z
    .array(
      z
        .object({
          input_voltage: z.number().positive().optional().nullable(),
          points: z
            .array(z.tuple([tempC, acPower]))
            .optional()
            .refine((points) => !points || assertXValuesUnique(points), {
              message: "Temperature values must be unique",
            }),
        })
        .refine(
          ({ points, input_voltage }) =>
            !(!isEmpty(points) && isEmptyNumberInput(input_voltage)),
          () => {
            return {
              path: [`input_voltage`],
              message: " ",
            };
          }
        )
        .refine(
          ({ points, input_voltage }) =>
            !(isEmpty(points) && !isEmptyNumberInput(input_voltage)),
          () => {
            return {
              message: "Must contain at least 1 point",
              path: [`points`],
            };
          }
        )
    )
    .optional(),
});

export const transformerSchema = z.object({
  night_disconnect: z.boolean(),
  nominal_low_side_voltage: z.number().positive(),
  nominal_high_side_voltage: z.number().positive(),
  iron_power_loss_data_source: z.nativeEnum(TransformerPowerLossDataSource),
  iron_power_loss_percentage: z.number().gte(0).lte(100),
  iron_power_loss: z.number().gte(0),
  copper_power_loss_data_source: z.nativeEnum(TransformerPowerLossDataSource),
  copper_power_loss_percentage: z.number().gte(0).lte(100),
  low_voltage_winding_resistance: z.number().positive(),
  high_voltage_winding_resistance: z.number().positive(),
});

export const acWiringSchema = z.object({
  ac_line_metal_type: z.nativeEnum(LineMetalType),
  ac_line_cross_section: z.number().positive(),
  ac_line_length: z.number().positive(),
  ac_per_km_resistance: z.number().nonnegative(),
  total_ac_resistance_override: z.number().nonnegative(),
  ac_line_loss_percentage: z.number().gte(0).lte(100),
  ac_line_calculation_method: z.nativeEnum(LineLossCalculationMethod),
});

export const dcWiringSchema = z.object({
  dc_line_metal_type: z.nativeEnum(LineMetalType),
  dc_line_cross_section: z.number().positive(),
  dc_line_length: z.number().positive(),
  dc_per_km_resistance: z.number().nonnegative(),
  total_dc_resistance_override: z.number().nonnegative(),
  dc_line_loss_percentage: z.number().gte(0).lte(100),
  dc_line_calculation_method: z.nativeEnum(LineLossCalculationMethod),
});

export const degradationSchema = z.object({
  first_year_degradation_rate: z.number().gte(0).lt(1),
  remaining_years_degradation_rate: z.number().gte(0).lt(1),
});

export const panelFormSchema = layoutSchema
  .merge(cellSchema)
  .merge(ivSchema)
  .merge(iamSchema)
  .merge(degradationSchema);

export const tableFormSchema = pvArraySchema
  .merge(trackerSchema)
  .merge(torqueTubeSchema)
  .merge(rackingSchema)
  .merge(postsSchema);

export const inverterFormSchema = inverterSchema;

export const transformerFormSchema = transformerSchema;

export const dcWireFormSchema = dcWiringSchema;

export const acWireFormSchema = acWiringSchema;
