/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { featureFlags as ApiFeatureFlags } from "@duet/api/src/feature-flags/flags";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import uniq from "lodash/uniq";
import { api } from "~/gql/generated";

export type FlagId = (typeof ApiFeatureFlags)[number];

const defaultDevFlags = [
  "rep-array-scaling-factor",
  "panel-technology",
  "pvsyst-empirical-factors",
  "pdf-report",
  "compare",
  "guide-line",
] as FlagId[];

interface FeatureFlagState {
  flags: FlagId[];
  sessionFlags: FlagId[];
  isLoaded: boolean;
}

const STORAGE_KEY = "featureFlags";

function getSessionFlags(): FlagId[] {
  const flags = sessionStorage.getItem(STORAGE_KEY);
  if (!flags) {
    return [];
  }
  try {
    return uniq(JSON.parse(flags));
  } catch (err) {
    sessionStorage.removeItem(STORAGE_KEY);
    return [];
  }
}

export let enabledFlags = new Set<FlagId>();

function getInitialState(): FeatureFlagState {
  if (import.meta.env.DEV && sessionStorage.getItem(STORAGE_KEY) === null) {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(defaultDevFlags));
  }
  const sessionFlags = getSessionFlags();
  return { flags: sessionFlags, sessionFlags, isLoaded: false };
}
export const featureFlagSlice = createSlice({
  name: "featureFlags",
  initialState: getInitialState,
  reducers: {
    setSessionFeatureFlags: (state, action: PayloadAction<FlagId[]>) => {
      const flags = uniq([...state.flags, ...action.payload]);
      state.flags = flags;
      state.sessionFlags = uniq([...state.sessionFlags, ...action.payload]);

      sessionStorage.setItem(
        "featureFlags",
        JSON.stringify(state.sessionFlags)
      );
    },
    removeSessionFeatureFlags: (state, action: PayloadAction<FlagId[]>) => {
      state.flags = state.flags.filter((id) => !action.payload.includes(id));
      state.sessionFlags = state.sessionFlags.filter(
        (id) => !action.payload.includes(id)
      );

      sessionStorage.setItem(
        "featureFlags",
        JSON.stringify(state.sessionFlags)
      );
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.FeatureFlags.matchFulfilled,
      (state, { payload }) => {
        const sessionFlags = getSessionFlags();

        state.flags = uniq([
          ...payload.featureFlags.map((f) => f.id),
          ...sessionFlags,
        ]) as FlagId[];

        enabledFlags = new Set(state.flags);

        state.isLoaded = true;
      }
    );
  },
});

export const { setSessionFeatureFlags, removeSessionFeatureFlags } =
  featureFlagSlice.actions;
