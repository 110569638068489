import { Flex, Image, Stack } from "@chakra-ui/react";
import { ErrorBoundary } from "@sentry/react";
import { FallbackFullPage } from "~/components/Fallback/Fallback";

const darkGradient = "linear-gradient(180deg, #050514 0%, #1A1A31 100%)";

export function AuthPageContainer({ children }: { children: React.ReactNode }) {
  return (
    <ErrorBoundary fallback={FallbackFullPage}>
      <Flex w="100%" h="100vh" p={4}>
        <Flex
          grow={1}
          justify="center"
          h="100%"
          bgGradient={darkGradient}
          borderRadius={"3em"}
          borderWidth={1}
          overflow="hidden"
          position={"relative"}
        >
          <Image
            src="/ripple-duet.png"
            position={"absolute"}
            top={0}
            objectPosition={"0px -300px"}
            width={"1200px"}
            minWidth={"1200px"}
            mx="auto"
          />

          <Stack
            w="100%"
            direction={"column"}
            justify={"center"}
            align="center"
            spacing={6}
          >
            {children}
          </Stack>
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
}
