import { extendTheme } from "@chakra-ui/react";
import { buttonTheme } from "./buttonTheme";
import {
  enurgenBifacial,
  enurgenMonofacial,
  enurgenPrimary,
  enurgenPrimaryAccent,
} from "./color";
import { dividerTheme } from "./dividerTheme";
import { headingTheme } from "./headingTheme";
import { inputTheme } from "./inputTheme";
import { modalTheme } from "./modalTheme";
import { switchTheme } from "./switchTheme";
import { tabTheme } from "./tabTheme";

export const theme = extendTheme({
  styles: {
    global: {
      // this is a really annoying hack to fix an bug in the prod build
      "*, *::before, ::after": {
        borderColor: "gray.200",
      },
    },
  },
  fonts: {
    heading: "'IBM Plex Sans Light', sans-serif",
    body: "'IBM Plex Sans', sans-serif",
    mono: "'JetBrains Mono'",
  },
  colors: {
    primary: enurgenPrimary.hex(),
    primaryAccent: {
      50: enurgenPrimaryAccent.lighten(0.95).hex(),
      500: enurgenPrimaryAccent.lighten(0.9).hex(),
    },
    gray: {
      75: "rgb(242,246,250)", // mid-point of gray.50 and gray.100
    },
    monofacial: { 100: enurgenMonofacial.lighten(0.1).hex() },
    bifacial: { 100: enurgenBifacial.lighten(0.1).hex() },
  },
  initialColorMode: "light",
  components: {
    Button: buttonTheme,
    Tab: tabTheme,
    Input: inputTheme,
    Heading: headingTheme,
    Modal: modalTheme,
    Switch: switchTheme,
    Divider: dividerTheme,
  },
});
