import { useEffect } from "react";
import { useMatches } from "react-router-dom";
import { ampli } from "./amplitude";
import { useProjectTrackingProperties } from "./useProjectTrackingProperties";

export function TrackingRoute() {
  const matches = useMatches();
  const lastMatch = matches[matches.length - 1];
  const trackingProps = useProjectTrackingProperties();

  useEffect(() => {
    if (!lastMatch) return;
    const { pathname, params } = lastMatch;

    const pageRoute = Object.entries(params).reduce((acc, [key, value]) => {
      if (!value) return acc;
      return acc.replace(value, `:${key}`);
    }, pathname);

    ampli.pageRoute({ ...trackingProps, route: pageRoute });
  }, [lastMatch]);

  return null;
}
