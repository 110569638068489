import * as parameterInfo from "@duet/shared/app_data/DUET-Designer_ParameterInfo.json";
import {
  CellShapeEnum,
  CellType,
  ComponentType,
  PanelTechnologyEnum,
  PanelTypeEnum,
} from "@duet/shared/pvComponents/projectParameters";
import { ComponentInputMetadata, Unit } from "@duet/shared/pvComponents/types";
import {
  isEmptyNumberInput,
  isEmptyStringInput,
} from "@duet/shared/pvComponents/utils";

export const panelInputMetadata: ComponentInputMetadata<ComponentType.Panel> = {
  panel_technology: {
    labelText: "Panel Technology",
    options: [
      { value: PanelTechnologyEnum.Silicon, label: "Silicon" },
      { value: PanelTechnologyEnum.CdTe, label: "CdTe" },
    ],
  },
  panel_type: {
    labelText: "Panel Type",
    options: [
      { value: PanelTypeEnum.Bifacial, label: "Bifacial" },
      { value: PanelTypeEnum.Monofacial, label: "Monofacial" },
    ],
  },
  cells: {
    labelText: parameterInfo.number_of_cells.display_name,
    helpText: parameterInfo.number_of_cells.description,
  },
  "cells.0": {
    labelText: "Length",
    fullLabelText: parameterInfo.number_of_cells_length.display_name,
    helpText: parameterInfo.number_of_cells_length.description,
    disabledIf: ({ panel_technology }) =>
      panel_technology === PanelTechnologyEnum.CdTe,
  },
  "cells.1": {
    labelText: "Width",
    fullLabelText: parameterInfo.number_of_cells_width.display_name,
    helpText: parameterInfo.number_of_cells_width.description,
  },
  cell_type: {
    labelText: parameterInfo.cell_type.display_name,
    helpText: parameterInfo.cell_type.description,
    disabledIf: ({ panel_technology }) =>
      panel_technology === PanelTechnologyEnum.CdTe,
    options: [
      { value: CellType.Full, label: "Full" },
      { value: CellType.Half, label: "Half Cut" },
    ],
  },
  cell_shape: {
    labelText: parameterInfo.solar_cell_shape.display_name,
    helpText: parameterInfo.solar_cell_shape.description,
    disabledIf: ({ panel_technology }) =>
      panel_technology === PanelTechnologyEnum.CdTe,
    options: [
      { value: CellShapeEnum.RECT, label: "Rectangular" },
      { value: CellShapeEnum.OCT, label: "Octagonal" },
    ],
    errorIf: ({ cell_shape, cell_type }) => {
      if (cell_shape === CellShapeEnum.OCT && cell_type === CellType.Half) {
        return {
          message:
            "Half-cut cells with octagonal shape is currently not supported",
        };
      }
    },
  },
  cell_length: {
    labelText: parameterInfo.solar_cell_length.display_name,
    helpText: parameterInfo.solar_cell_length.description,
    unit: parameterInfo.solar_cell_length.units as Unit,
    displayUnit: "mm",
    errorIf: ({ corner_cut_off, cell_type, cell_shape, cell_length }) => {
      if (
        cell_shape === CellShapeEnum.OCT &&
        !isEmptyNumberInput(corner_cut_off) &&
        !isEmptyNumberInput(cell_length) &&
        !isEmptyStringInput(cell_type)
      ) {
        const patch_div_length = cell_type === "half" ? 2 : 4;
        if (cell_length / patch_div_length < corner_cut_off) {
          return {
            message:
              "Panel patches too small. Area in corner patches cannot be calculated",
          };
        }
      }
    },
  },
  cell_width: {
    labelText: parameterInfo.solar_cell_width.display_name,
    helpText: parameterInfo.solar_cell_width.description,
    unit: parameterInfo.solar_cell_width.units as Unit,
    displayUnit: "mm",
    errorIf: ({ corner_cut_off, cell_type, cell_shape, cell_width }) => {
      if (
        cell_shape === CellShapeEnum.OCT &&
        !isEmptyNumberInput(corner_cut_off) &&
        !isEmptyNumberInput(cell_width) &&
        !isEmptyStringInput(cell_type)
      ) {
        const patch_div_width = 4;
        if (cell_width / patch_div_width < corner_cut_off) {
          return {
            message:
              "Panel patches too small. Area in corner patches cannot be calculated",
          };
        }
      }
    },
  },
  cell_spacing_lr: {
    labelText: parameterInfo.cell_spacing__left_to_right.display_name,
    helpText: parameterInfo.cell_spacing__left_to_right.description,
    displayUnit: "mm",
    unit: parameterInfo.cell_spacing__left_to_right.units as Unit,
    disabledIf: ({ panel_technology }) =>
      panel_technology === PanelTechnologyEnum.CdTe,
  },
  cell_spacing_tb: {
    labelText: parameterInfo.cell_spacing__left_to_right.display_name,
    helpText: parameterInfo.cell_spacing__top_to_bottom.description,
    displayUnit: "mm",
    unit: parameterInfo.cell_spacing__top_to_bottom.units as Unit,
    disabledIf: ({ panel_technology }) =>
      panel_technology === PanelTechnologyEnum.CdTe,
  },
  corner_cut_off: {
    labelText: parameterInfo.solar_cell_corner_cut_off_length.display_name,
    helpText: parameterInfo.solar_cell_corner_cut_off_length.description,
    unit: parameterInfo.solar_cell_corner_cut_off_length.units as Unit,
    displayUnit: "mm",
    disabledIf: ({ cell_shape }) => cell_shape === CellShapeEnum.RECT,
  },
  half_cut_center_space: {
    labelText: parameterInfo.cell_spacing__center_gap.display_name,
    helpText: parameterInfo.cell_spacing__center_gap.description,
    unit: parameterInfo.cell_spacing__center_gap.units as Unit,
    displayUnit: "mm",
    disabledIf: ({ cell_type, panel_technology }) =>
      cell_type === CellType.Full ||
      panel_technology === PanelTechnologyEnum.CdTe,
  },
  panel_edge_b: {
    labelText: parameterInfo.panel_edge__bottom.display_name,
    helpText: parameterInfo.panel_edge__bottom.description,
    unit: parameterInfo.panel_edge__bottom.units as Unit,
    displayUnit: "mm",
    disabledIf: ({ panel_technology }) =>
      panel_technology === PanelTechnologyEnum.CdTe,
  },
  panel_edge_t: {
    labelText: parameterInfo.panel_edge__top.display_name,
    helpText: parameterInfo.panel_edge__top.description,
    unit: parameterInfo.panel_edge__top.units as Unit,
    displayUnit: "mm",
    disabledIf: ({ panel_technology }) =>
      panel_technology === PanelTechnologyEnum.CdTe,
  },
  panel_edge_lr: {
    labelText: parameterInfo.panel_edge__side.display_name,
    helpText: parameterInfo.panel_edge__side.description,
    unit: parameterInfo.panel_edge__side.units as Unit,
    displayUnit: "mm",
    disabledIf: ({ panel_technology }) =>
      panel_technology === PanelTechnologyEnum.CdTe,
  },
  frame: {
    labelText: parameterInfo.frame.display_name,
    helpText: parameterInfo.frame.description,
  },
  frame_dimensions: {
    labelText: parameterInfo.frame_dimensions.display_name,
    helpText: parameterInfo.frame_dimensions.description,
    disabledIf: ({ frame }) => !frame,
  },
  "frame_dimensions.0": {
    labelText: parameterInfo.frame_long.display_name,
    helpText: parameterInfo.frame_long.description,
    unit: parameterInfo.frame_dimensions.units as Unit,
    displayUnit: "mm",
    disabledIf: ({ frame }) => !frame,
  },
  "frame_dimensions.1": {
    labelText: parameterInfo.frame_short.display_name,
    helpText: parameterInfo.frame_short.description,
    unit: parameterInfo.frame_dimensions.units as Unit,
    displayUnit: "mm",
    disabledIf: ({ frame }) => !frame,
  },
  "frame_dimensions.2": {
    labelText: parameterInfo.frame_depth.display_name,
    helpText: parameterInfo.frame_depth.description,
    unit: parameterInfo.frame_dimensions.units as Unit,
    displayUnit: "mm",
    disabledIf: ({ frame }) => !frame,
  },
  parallel_string_per_diode: {
    labelText: parameterInfo.parallel_strings_per_diode.display_name,
    helpText: parameterInfo.parallel_strings_per_diode.description,
    disabledIf: ({ panel_technology }) =>
      panel_technology === PanelTechnologyEnum.CdTe,
  },
  "parallel_string_per_diode.0": {
    labelText: "Rows",
    fullLabelText: "Parallel Strings Per Diode - Rows",
    disabledIf: ({ panel_technology }) =>
      panel_technology === PanelTechnologyEnum.CdTe,
    errorIf: ({ cells, bypass_diodes, parallel_string_per_diode }) => {
      if (
        cells &&
        bypass_diodes &&
        parallel_string_per_diode &&
        !isEmptyNumberInput(cells?.[0]) &&
        !isEmptyNumberInput(bypass_diodes?.[0]) &&
        !isEmptyNumberInput(parallel_string_per_diode?.[0])
      ) {
        if (
          (cells[0] / bypass_diodes[0]) % parallel_string_per_diode[0] !==
          0
        ) {
          return {
            message:
              "must be factor of number of cell rows on the panel divided by number of bypass diode rows",
          };
        }
      }
    },
  },
  "parallel_string_per_diode.1": {
    labelText: "Columns",
    fullLabelText: "Parallel Strings Per Diode - Columns",
    disabledIf: ({ panel_technology }) =>
      panel_technology === PanelTechnologyEnum.CdTe,
    errorIf: ({ cells, bypass_diodes, parallel_string_per_diode }) => {
      if (
        cells &&
        bypass_diodes &&
        parallel_string_per_diode &&
        !isEmptyNumberInput(cells?.[1]) &&
        !isEmptyNumberInput(bypass_diodes?.[1]) &&
        !isEmptyNumberInput(parallel_string_per_diode?.[1])
      ) {
        if (
          (cells[1] / bypass_diodes[1]) % parallel_string_per_diode[1] !==
          0
        ) {
          return {
            message:
              "must be factor of number of cell columns on the panel divided by number of bypass diode columns",
          };
        }
      }
    },
  },
  bypass_diodes: {
    labelText: parameterInfo.bypass_diodes.display_name,
    helpText: parameterInfo.bypass_diodes.description,
    disabledIf: ({ panel_technology }) =>
      panel_technology === PanelTechnologyEnum.CdTe,
  },
  "bypass_diodes.0": {
    labelText: "Rows",
    disabledIf: ({ panel_technology }) =>
      panel_technology === PanelTechnologyEnum.CdTe,
    errorIf: ({ cells, bypass_diodes }) => {
      if (
        cells &&
        bypass_diodes &&
        !isEmptyNumberInput(cells?.[0]) &&
        !isEmptyNumberInput(bypass_diodes?.[0])
      ) {
        if (cells[0] % bypass_diodes[0] !== 0) {
          return {
            message: "must be factor of number of cell rows on the panel",
          };
        }
      }
    },
  },
  "bypass_diodes.1": {
    labelText: "Columns",
    disabledIf: ({ panel_technology }) =>
      panel_technology === PanelTechnologyEnum.CdTe,
    errorIf: ({ cells, bypass_diodes }) => {
      if (
        cells &&
        bypass_diodes &&
        !isEmptyNumberInput(cells?.[1]) &&
        !isEmptyNumberInput(bypass_diodes?.[1])
      ) {
        if (cells[1] % bypass_diodes[1] !== 0) {
          return {
            message: "must be factor of number of cell columns on the panel",
          };
        }
      }
    },
  },
  temperature_coefficient_isc: {
    labelText: "Temperature Coefficients (Isc)",
    helpText:
      parameterInfo.temperature_coefficient__short_circuit_current.description,
    unit: parameterInfo.temperature_coefficient__short_circuit_current
      .units as Unit,
  },
  temperature_coefficient_voc: {
    labelText: "Temperature Coefficients (Voc)",
    helpText:
      parameterInfo.temperature_coefficient__open_circuit_voltage.description,
    unit: parameterInfo.temperature_coefficient__open_circuit_voltage
      .units as Unit,
  },
  nominal_operating_cell_temp: {
    labelText: parameterInfo.nominal_operating_cell_temperature.display_name,
    helpText: parameterInfo.nominal_operating_cell_temperature.description,
    unit: parameterInfo.nominal_operating_cell_temperature.units as Unit,
  },
  irradiance_coefficients_temp: {
    labelText:
      parameterInfo.irradiance_coefficients_of_temperature.display_name,
    helpText: parameterInfo.irradiance_coefficients_of_temperature.description,
  },
  "irradiance_coefficients_temp.0": {
    labelText:
      parameterInfo.irradiance_coefficients_of_temperature__temp.display_name,
    helpText:
      parameterInfo.irradiance_coefficients_of_temperature__temp.description,
    unit: parameterInfo.irradiance_coefficients_of_temperature__temp
      .units as Unit,
  },
  "irradiance_coefficients_temp.1": {
    labelText:
      parameterInfo.irradiance_coefficients_of_temperature__irr.display_name,
    helpText:
      parameterInfo.irradiance_coefficients_of_temperature__irr.description,
    unit: parameterInfo.irradiance_coefficients_of_temperature__irr
      .units as Unit,
  },
  bypass_diode_max_neg_v: {
    labelText: parameterInfo.bypass_diode_negative_voltage.display_name,
    helpText: parameterInfo.bypass_diode_negative_voltage.description,
    unit: parameterInfo.bypass_diode_negative_voltage.units as Unit,
  },
  bifaciality_current_ratio: {
    labelText: parameterInfo.bifaciality.display_name,
    helpText: parameterInfo.bifaciality.description,
  },
  short_circuit_current: {
    labelText: parameterInfo.short_circuit_current_of_the_cell.display_name,
    helpText: parameterInfo.short_circuit_current_of_the_cell.description,
    unit: parameterInfo.short_circuit_current_of_the_cell.units as Unit,
  },
  open_circuit_voltage: {
    labelText: parameterInfo.open_circuit_voltage_of_the_cell.display_name,
    helpText: parameterInfo.open_circuit_voltage_of_the_cell.description,
    unit: parameterInfo.open_circuit_voltage_of_the_cell.units as Unit,
  },
  series_resistance: {
    labelText: parameterInfo.series_resistance_of_the_cell.display_name,
    helpText: parameterInfo.series_resistance_of_the_cell.description,
    unit: parameterInfo.series_resistance_of_the_cell.units as Unit,
  },
  shunt_resistance: {
    labelText: parameterInfo.shunt_resistance_of_the_cell.display_name,
    helpText: parameterInfo.shunt_resistance_of_the_cell.description,
    unit: parameterInfo.shunt_resistance_of_the_cell.units as Unit,
  },
  ideality_factor: {
    labelText: parameterInfo.ideality_factor_of_the_cell.display_name,
    helpText: parameterInfo.ideality_factor_of_the_cell.description,
  },
  pan_file_import: {
    labelText: "Pan File Import",
    hidden: true,
  },
  iam_profile: {
    labelText: "Incidence Angle Modifier (IAM) Profile",
  },
  first_year_degradation_rate: {
    labelText: parameterInfo.panel_degradation__first_year.display_name,
    helpText: parameterInfo.panel_degradation__first_year.description,
    unit: undefined,
    displayUnit: "%",
  },
  remaining_years_degradation_rate: {
    labelText: parameterInfo.panel_degradation__yearly.display_name,
    helpText: parameterInfo.panel_degradation__yearly.description,
    unit: undefined,
    displayUnit: "%",
  },
};
