import path from "path";

export function ensureUniqueName(
  existingNames: string[],
  name: string,
  { includeSuffixOnFirstInstance = true } = {}
) {
  const pattern = new RegExp(`${name}(\\s+\\d+)?$`, "i");
  const lastCount = existingNames.reduce<number | undefined>(
    (count: number | undefined, name: string) => {
      const match = name.match(pattern);
      if (match) {
        return Math.max(count ?? 0, Number(match[1]) || 0);
      }
      return count;
    },
    undefined
  );

  const suffix =
    lastCount !== undefined || includeSuffixOnFirstInstance
      ? ` ${(lastCount ?? 0) + 1}`
      : "";

  return name + suffix;
}

export function ensureUniqueFilename(
  existingFilenames: string[],
  filename: string
) {
  const { name: baseName, ext } = path.parse(filename);

  let i = 1;
  while (existingFilenames.includes(filename)) {
    filename = `${baseName} ${i}${ext ?? ""}`;
    i++;
  }
  return filename;
}
