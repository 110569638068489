import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { uniqBy } from "lodash";
import { useAppSelector } from "~/redux/store";
import { ProjectEnviroDataset } from "../utils/meteoDataset";

interface ProjectMeteoState {
  projectEnviroDatasets: ProjectEnviroDataset[];
  projectEnviroDatasetsLoading: boolean;
  enviroDatasetsProjectId: string | null;
  selectedEnviroDataset: ProjectEnviroDataset | null;
}

const initialState: ProjectMeteoState = {
  projectEnviroDatasets: [],
  projectEnviroDatasetsLoading: false,
  enviroDatasetsProjectId: null,
  selectedEnviroDataset: null,
};

export const projectMeteoSlice = createSlice({
  name: "projectMeteo",
  initialState,
  reducers: {
    reloadMeteoDatasets(
      state,
      action: PayloadAction<{
        items: ProjectEnviroDataset[];
        projectId: string;
      }>
    ) {
      state.projectEnviroDatasets = action.payload.items;
      state.enviroDatasetsProjectId = action.payload.projectId;
    },
    setMeteoDatasetsLoading(state, action: PayloadAction<boolean>) {
      state.projectEnviroDatasetsLoading = action.payload;
    },
    selectMeteoDataset(state, action: PayloadAction<string>) {
      state.selectedEnviroDataset =
        state.projectEnviroDatasets.find(({ id }) => id === action.payload) ??
        null;
    },
    insertMeteoDataset(state, action: PayloadAction<ProjectEnviroDataset>) {
      state.projectEnviroDatasets = uniqBy(
        [...state.projectEnviroDatasets, action.payload],
        "id"
      );
    },
    updateMeteoDataset(
      state,
      action: PayloadAction<{
        id: string;
        data: { name: string };
      }>
    ) {
      const meteoDataset = state.projectEnviroDatasets.find(
        ({ id }) => id === action.payload.id
      );
      if (meteoDataset) {
        Object.assign(meteoDataset, {
          enviro_dataset: {
            ...meteoDataset.enviro_dataset,
            name: action.payload.data.name,
          },
        });
        if (
          state.selectedEnviroDataset &&
          state.selectedEnviroDataset.id === meteoDataset.id
        ) {
          state.selectedEnviroDataset = meteoDataset;
        }
      }
    },

    deleteMeteoDataset(state, action: PayloadAction<string>) {
      state.projectEnviroDatasets = state.projectEnviroDatasets.filter(
        (m) => m.id !== action.payload
      );
      if (
        state.selectedEnviroDataset &&
        state.selectedEnviroDataset.id === action.payload
      ) {
        state.selectedEnviroDataset = null;
      }
    },
  },
  extraReducers: (builder) => {},
});

export const {
  reloadMeteoDatasets,
  setMeteoDatasetsLoading,
  selectMeteoDataset,
  updateMeteoDataset,
  insertMeteoDataset,
  deleteMeteoDataset,
} = projectMeteoSlice.actions;

export const useProjectMeteoState = () => {
  return useAppSelector((state) => {
    return {
      ...state.projectMeteo,
    };
  });
};
