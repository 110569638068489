import { ensureUniqueName } from "@duet/shared/utils";
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { booleanContains } from "@turf/turf";
import {
  cloneDeep,
  compact,
  filter,
  isEqual,
  keyBy,
  map,
  omit,
  pull,
  startCase,
  uniq,
} from "lodash";
import { SetOptional } from "type-fest";
import { v4 as uuid } from "uuid";
import { BlockInput, BlockLayer, BlockLayerInput, api } from "~/gql/generated";
import { RootState, useAppSelector } from "~/redux/store";
import { BlockModel, makeBlockModel } from "../blocks/blockModel";
import { FrameFeature } from "../sceneImport/frameUtil";

interface BlockMutation {
  id: string;
  mutation: "add" | "update" | "delete";
  type: "block" | "block_layer";
  data: Omit<BlockInput, "project_id"> | Omit<BlockLayerInput, "project_id">;
}

export type BlockSectionStats = Record<
  string,
  {
    tables: number;
    rows: number;
  }
>;

function makeBlockMutation(payload: Omit<BlockMutation, "id">): BlockMutation {
  return {
    id: uuid(),
    ...payload,
  };
}

export function getBlockIdsForBlockLayerIds(
  blockLayerIds: string[],
  blockLayers: BlockLayer[]
) {
  const blockIds = blockLayerIds.reduce<string[]>((acc, id) => {
    const blockId = blockLayers.find((l) => l.id === id)?.block_id;
    if (blockId) {
      acc.push(blockId);
    }
    return acc;
  }, []);
  return uniq(blockIds);
}

export interface ProjectBlockState {
  isInitialLoaded: boolean;

  unassignedLayersVisible: boolean;
  blockMutationQueue: BlockMutation[];
  clipboardBlocks: BlockModel[];
  sectionStats: BlockSectionStats;
  sectionConflictIds: string[];

  //
  blockIds: string[];
  blocksById: Record<string, BlockModel | undefined>;
  blockLayerIds: string[];
  blockLayersById: Record<string, BlockLayer | undefined>;
  blockLayerIdsByBlockId: Record<string, string[]>;
}

const initialState: ProjectBlockState = {
  isInitialLoaded: false,
  unassignedLayersVisible: true,
  blockMutationQueue: [],
  clipboardBlocks: [],
  sectionStats: {},
  sectionConflictIds: [],

  //
  blockIds: [],
  blocksById: {},
  blockLayerIds: [],
  blockLayersById: {},
  blockLayerIdsByBlockId: {},
};

export type BlockLayerPayload = Pick<
  BlockLayer,
  "id" | "block_id" | "project_id" | "geojson" | "type" | "name"
> & {
  type: "perimeter" | "section" | "frame" | "guide_line" | "exclusion" | string;
};

export const UNASSIGNED_BLOCK = makeBlockModel({
  id: "unassigned",
  name: "Unassigned",
});

export const projectBlockSlice = createSlice({
  name: "projectBlock",
  initialState,
  reducers: {
    createBlock(state, action: PayloadAction<{ id?: string }>) {
      const existingNames = compact(
        Object.values(state.blocksById).map((b) => b?.name)
      );
      const name = ensureUniqueName(existingNames, "Block");
      const block = makeBlockModel({
        name,
        ...action.payload,
      });

      state.blockMutationQueue.push(
        makeBlockMutation({
          mutation: "add",
          type: "block",
          data: block,
        })
      );

      // block-sync-v2
      state.blocksById[block.id] = block;
      state.blockIds.push(block.id);
    },
    addBlockLayer(
      state,
      action: PayloadAction<SetOptional<BlockLayerPayload, "name">>
    ) {
      const blockId = action.payload.block_id;
      if (!blockId) return;
      const block = state.blocksById[blockId];
      if (!block) return;

      const blockLayers = filter(Object.values(state.blockLayersById), {
        block_id: block.id,
        type: action.payload.type,
      });
      const blockLayerNames = map(blockLayers, "name");
      const blockLayer = {
        name: ensureUniqueName(blockLayerNames, startCase(action.payload.type)),
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        ...action.payload,
      };

      if (blockLayer.type === "guide_line") {
        const associatedPerimeters = blockLayer.geojson.properties.polygonIds;
        blockLayer.geojson.properties = {};

        for (const perimeterId of associatedPerimeters) {
          const perimeter = state.blockLayersById[perimeterId];
          if (!perimeter) continue;
          perimeter.geojson.properties.guideLineId = blockLayer.id;
          state.blockMutationQueue.push(
            makeBlockMutation({
              mutation: "update",
              type: "block_layer",
              data: perimeter,
            })
          );
        }
      }

      state.blockMutationQueue.push(
        makeBlockMutation({
          mutation: "add",
          type: "block_layer",
          data: blockLayer,
        })
      );

      state.blockLayersById[blockLayer.id] = blockLayer;
      state.blockLayerIds.push(blockLayer.id);
      const blockLayerIdsByBlockId = (state.blockLayerIdsByBlockId[block.id] ??=
        []);
      blockLayerIdsByBlockId.push(blockLayer.id);
    },
    addBatchBlockLayers(state, action: PayloadAction<BlockLayerPayload[]>) {
      for (const layerPayload of action.payload) {
        const names = compact(
          map(Object.values(state.blockLayersById), "name")
        );
        const name = ensureUniqueName(names, layerPayload.name, {
          includeSuffixOnFirstInstance: false,
        });

        const layer = {
          ...layerPayload,
          name,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        };
        state.blockLayersById[layer.id] = layer;
        state.blockLayerIds.push(layer.id);
        const blockId = layer.block_id ?? UNASSIGNED_BLOCK.id;
        const blockLayerIdsByBlockId = (state.blockLayerIdsByBlockId[
          blockId
        ] ??= []);
        blockLayerIdsByBlockId.push(layer.id);

        state.blockMutationQueue.push(
          makeBlockMutation({
            mutation: "add",
            type: "block_layer",
            data: layer,
          })
        );
      }
    },
    updateBlockLayers(state, action: PayloadAction<BlockLayerPayload[]>) {
      for (const blockLayer of action.payload) {
        const existingBlockLayer = state.blockLayersById[blockLayer.id];

        if (!isEqual(existingBlockLayer, blockLayer)) {
          state.blockMutationQueue.push(
            makeBlockMutation({
              mutation: "update",
              type: "block_layer",
              data: blockLayer,
            })
          );
        }

        if (
          existingBlockLayer &&
          existingBlockLayer.block_id !== blockLayer.block_id
        ) {
          const blockId = existingBlockLayer.block_id;
          if (blockId) {
            state.blockLayerIdsByBlockId[blockId] =
              state.blockLayerIdsByBlockId[blockId].filter(
                (i) => i !== blockLayer.id
              );
          }

          if (!blockId) {
            state.blockLayerIdsByBlockId.unassigned =
              state.blockLayerIdsByBlockId.unassigned.filter(
                (i) => i !== blockLayer.id
              );
          }

          state.blockLayerIdsByBlockId[blockId ?? UNASSIGNED_BLOCK.id].push(
            blockLayer.id
          );
        }

        if (existingBlockLayer) {
          Object.assign(existingBlockLayer, blockLayer);
        }
      }
    },
    deleteBlockLayers(state, action: PayloadAction<string[]>) {
      const idsToDelete = Array.from(action.payload);

      // while we can support 1:n guide lines to perimeters, we are not currently and will
      // delete the guide line along with the perimeter keeping it 1:1
      const blockLayers = idsToDelete.map((id) => state.blockLayersById[id]);
      const optionalGuideLineIds = compact(
        blockLayers.map((l) => l?.geojson?.properties?.guideLineId)
      );
      if (optionalGuideLineIds.length > 0) {
        idsToDelete.push(...optionalGuideLineIds);
      }

      state.blockLayerIds = state.blockLayerIds.filter(
        (id) => !idsToDelete.includes(id)
      );

      for (const id of idsToDelete) {
        const layer = state.blockLayersById[id];

        if (!layer) continue;

        state.blockMutationQueue = state.blockMutationQueue.filter(
          (bm) => !(bm.type === "block_layer" && bm.data.id === id)
        );

        state.blockMutationQueue.push(
          makeBlockMutation({
            mutation: "delete",
            type: "block_layer",
            data: layer,
          })
        );

        const blockId = layer.block_id ?? UNASSIGNED_BLOCK.id;
        state.blockLayerIdsByBlockId[blockId] = state.blockLayerIdsByBlockId[
          blockId
        ].filter((i) => i !== id);
        state.blockLayersById[id] = undefined;

        if (layer.type === "guide_line") {
          const associatedPerimeters = Object.values(
            state.blockLayersById
          ).filter((l) => l && l.geojson.properties?.guideLineId === id);

          for (const perimeter of associatedPerimeters) {
            if (!perimeter) continue;
            perimeter.geojson.properties.guideLineId = undefined;
            state.blockMutationQueue.push(
              makeBlockMutation({
                mutation: "update",
                type: "block_layer",
                data: perimeter,
              })
            );
          }
        }
      }
    },
    deleteBlocks(state, action: PayloadAction<string[]>) {
      state.blockIds = state.blockIds.filter(
        (id) => !action.payload.includes(id)
      );
      for (const id of action.payload) {
        const block = state.blocksById[id];
        if (!block) continue;

        state.blockMutationQueue = state.blockMutationQueue.filter(
          (bm) => !(bm.type === "block" && bm.data.id === id)
        );

        state.blockMutationQueue.push(
          makeBlockMutation({
            mutation: "delete",
            type: "block",
            data: block,
          })
        );
        state.blocksById[id] = undefined;
        const blockLayerIds = state.blockLayerIdsByBlockId[id];
        if (!blockLayerIds) continue;
        for (const layerId of blockLayerIds) {
          state.blockLayersById[layerId] = undefined;
        }
        state.blockLayerIdsByBlockId[id] = [];
      }
    },
    updateBlocks(state, action: PayloadAction<BlockModel[]>) {
      for (const block of action.payload) {
        const existingBlock = state.blocksById[block.id];

        if (
          !isEqual(
            omit(existingBlock, "block_layers"),
            omit(block, "block_layers")
          )
        ) {
          state.blockMutationQueue.push(
            makeBlockMutation({
              mutation: "update",
              type: "block",
              data: block,
            })
          );
        }
        if (existingBlock) {
          Object.assign(existingBlock, cloneDeep(block));
        }
      }
    },
    updateBlockLayerVisibility(
      state,
      action: PayloadAction<{
        blocks?: Array<Pick<BlockModel, "id">>;
        includeUnassignedLayers?: boolean;
        isVisible: boolean;
      }>
    ) {
      if (action.payload.includeUnassignedLayers) {
        state.unassignedLayersVisible = action.payload.isVisible;
      }
      if (!action.payload.blocks) return;
      for (const { id } of action.payload.blocks) {
        const block = state.blocksById[id];
        if (!block) continue;
        Object.assign(block, {
          isVisible: action.payload.isVisible,
        });
      }
    },
    setBlocks(state, action: PayloadAction<BlockModel[]>) {
      state.blockIds = action.payload.map((b) => b.id);
      state.blocksById = keyBy(action.payload, "id");

      state.isInitialLoaded = true;
    },
    removeFromBlockMutationQueue(state, action: PayloadAction<string[]>) {
      state.blockMutationQueue = state.blockMutationQueue.filter(
        (mutation) => !action.payload.includes(mutation.id)
      );
    },
    addToBlockMutationQueue(state, action: PayloadAction<BlockMutation[]>) {
      state.blockMutationQueue.push(...action.payload);
    },
    updateSectionStats(state, action: PayloadAction<BlockSectionStats>) {
      Object.assign(state.sectionStats, action.payload);
    },
    addSectionConflictId(state, action: PayloadAction<string>) {
      if (state.sectionConflictIds.includes(action.payload)) return;
      state.sectionConflictIds.push(action.payload);
    },
    removeSectionConflictId(state, action: PayloadAction<string>) {
      state.sectionConflictIds = state.sectionConflictIds.filter(
        (id) => id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.GetProjectBlocks.matchFulfilled,
      (state, { payload }) => {
        //
        //
        const serverBlockIds = payload.blockIds.map((b) => b.id);
        const serverBlockLayerIds = payload.blockLayerIds.map((b) => b.id);

        // Note on determining "addedBlockIds" and "addedBlockLayerIds":
        //
        // payload.`blockIds`/`blockLayerIds` are records currently in the database.
        // payload.`blocks`/`blockLayers` are records that have changed since `updated_after`.
        //
        // If we're receiving a "new" record it will exist in both arrays.
        // If it's in the `*Ids` array only that indicates it is an "old".
        // record that has been seen locally and deleted locally while this
        // GetProjectBlocks query was in flight.

        for (const block of payload.blocks) {
          const exists = state.blocksById[block.id];
          if (exists) {
            Object.assign(exists, block);
            continue;
          }

          state.blocksById[block.id] = Object.assign(
            { isVisible: true },
            block
          );
        }

        for (const blockLayer of payload.blockLayers) {
          state.blockLayersById[blockLayer.id] = blockLayer;
        }

        const deletedBlockIds = state.blockIds.filter(
          (id) => !serverBlockIds.includes(id)
        );

        const addedBlockIds = serverBlockIds.filter(
          (id) => !state.blockIds.includes(id) && state.blocksById[id]
        );

        state.blockIds.push(...addedBlockIds);
        pull(state.blockIds, ...deletedBlockIds);

        for (const id of deletedBlockIds) {
          if (!state.blocksById[id]) continue;

          state.blocksById[id] = undefined;
        }

        const deletedBlockLayerIds = state.blockLayerIds.filter(
          (id) => !serverBlockLayerIds.includes(id)
        );

        const addedBlockLayerIds = serverBlockLayerIds.filter(
          (id) => !state.blockLayerIds.includes(id) && state.blockLayersById[id]
        );

        state.blockLayerIds.push(...addedBlockLayerIds);
        pull(state.blockLayerIds, ...deletedBlockLayerIds);

        for (const id of deletedBlockLayerIds) {
          if (!state.blockLayersById[id]) continue;

          state.blockLayersById[id] = undefined;
        }

        state.blockLayerIdsByBlockId = state.blockIds.reduce<
          Record<string, string[]>
        >((acc, blockId) => {
          const blockLayerIds = payload.blockLayerIds
            .filter(
              (bl) => state.blockLayersById[bl.id] && bl.block_id === blockId
            )
            .map((bl) => bl.id);

          acc[blockId] = blockLayerIds;
          return acc;
        }, {});
        state.blockLayerIdsByBlockId[UNASSIGNED_BLOCK.id] =
          payload.blockLayerIds
            .filter((bl) => state.blockLayersById[bl.id] && !bl.block_id)
            .map((bl) => bl.id);

        if (!state.isInitialLoaded) {
          state.isInitialLoaded = true;
        }
      }
    );

    builder.addMatcher(
      api.endpoints.UpsertBlocks.matchFulfilled,
      (state, { payload }) => {
        for (const block of payload.upsertBlocks) {
          const existingBlock = state.blocksById[block.id];
          if (existingBlock) {
            Object.assign(existingBlock, block);
          }
        }
      }
    );
  },
});

export const {
  createBlock,
  updateSectionStats,
  deleteBlocks,
  updateBlocks,
  setBlocks,
  removeFromBlockMutationQueue,
  addToBlockMutationQueue,
  addBlockLayer,
  addBatchBlockLayers,
  updateBlockLayers,
  updateBlockLayerVisibility,
  deleteBlockLayers,
  addSectionConflictId,
  removeSectionConflictId,
} = projectBlockSlice.actions;

/**
 * this is a performance pitfall
 * @deprecated - write a specific useAppSelector to avoid unnecessary re-renders
 */
export const useProjectBlockState = () => {
  return useAppSelector((state) => {
    const blocks = blocksSelector(state);

    const blockLayers = blockLayersSelector(state);

    return {
      blocks,
      blockLayers,
      ...state.projectBlock,
    };
  });
};

export function blocksSelector(state: RootState) {
  return compact(
    state.projectBlock.blockIds.map((id) => state.projectBlock.blocksById[id])
  );
}

export function blockLayersSelector(state: RootState) {
  return compact(
    state.projectBlock.blockLayerIds.map(
      (id) => state.projectBlock.blockLayersById[id]
    )
  );
}

const assignedPerimeterLayersSelector = createSelector(
  (state: RootState) => state.projectBlock.blockLayersById,
  (layersById) =>
    Object.values(layersById).filter(
      (layer): layer is BlockLayer => layer?.type === "perimeter"
    )
);

const framesLayerSelector = createSelector(
  (state: RootState) => state.projectBlock.blockLayersById,
  (layersById) =>
    Object.values(layersById).find(
      (layer): layer is BlockLayer => layer?.type === "frame"
    )
);

export const partitionedFramesSelector = createSelector(
  [assignedPerimeterLayersSelector, framesLayerSelector],
  function partitionedFramesSelector(
    blockPerimeterLayers: BlockLayer[],
    framesLayer: BlockLayer | undefined
  ) {
    const frames: FrameFeature[] = framesLayer?.geojson.features || [];

    if (!frames.length) {
      return {};
    }

    return frames.reduce<Record<string, typeof frames>>(
      (acc, frame: FrameFeature) => {
        const perimeter = blockPerimeterLayers.find((perimeter) =>
          booleanContains(perimeter.geojson, frame)
        );

        const blockId = perimeter?.block_id || UNASSIGNED_BLOCK.id;
        if (!acc[blockId]) {
          acc[blockId] = [];
        }

        acc[blockId].push(frame);

        return acc;
      },
      {}
    );
  }
);
