import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useActiveUser } from "../features/user/hooks";

export function useProjectTrackingProperties() {
  const { user } = useActiveUser();

  const { projectId = "" } = useParams<{ projectId: string }>();
  // TODO: fix this when we implement organizations
  const organization = user?.email?.split("@")[1] ?? "";

  return useMemo(
    () => ({
      projectId,
      organization,
    }),
    [projectId, organization]
  );
}
