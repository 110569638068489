import { useLayoutEffect } from "react";

const SITE_NAME = "Enurgen";
export function useDocumentTitle(title: string) {
  useLayoutEffect(() => {
    if (title) {
      document.title = `${title} | ${SITE_NAME}`;
    }
    return () => {
      document.title = SITE_NAME;
    };
  }, [title]);
}
