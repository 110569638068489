import {
  Button,
  Input,
  InputGroup,
  InputRightElement,
  type InputProps,
} from "@chakra-ui/react";
import { forwardRef, useState } from "react";
import { TbEye, TbEyeClosed } from "react-icons/tb";

export const PasswordInput = forwardRef(function PasswordInput(
  props: InputProps,
  ref
) {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      <Input
        pr="4.5rem"
        type={show ? "text" : "password"}
        placeholder="Enter password"
        {...props}
        ref={ref}
      />
      <InputRightElement width="3rem">
        <Button
          h="1.75rem"
          size="sm"
          onClick={handleClick}
          tabIndex={-1}
          variant="ghost"
        >
          {show ? <TbEyeClosed /> : <TbEye />}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
});
