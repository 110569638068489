import { HStack, Stack } from "@chakra-ui/react";
import { enurgenDark } from "@duet/ui/theme/color";
import { ReactNode } from "react";
import { useAppUrls } from "~/appUrls";
import { AppBarItem } from ".";
import { AdminImpersonateAppBar } from "../../features/admin/AdminImpersonateAppBar";
import { DuetLogo } from "../EnurgenLogo/EnurgenLogo";
import { AppBarUserAvatar } from "./AppBarUserAvatar";

interface AppBarProps {
  children?: ReactNode;
}

export default function AppBar({ children }: AppBarProps) {
  const url = useAppUrls();

  return (
    <Stack spacing={0}>
      <AdminImpersonateAppBar />

      <HStack
        h={12}
        minH={12}
        w="100vw"
        px={2}
        background={enurgenDark.toHexString()}
        zIndex={99}
        spacing={2}
        justify="space-between"
      >
        <HStack w="100%">
          <AppBarItem
            px={4}
            w="fit-content"
            minW="fit-content"
            to={url.indexRoute()}
            _hover={undefined}
            isActive={() => false}
          >
            <DuetLogo w={14} />
          </AppBarItem>

          {children}
        </HStack>
        <HStack>
          <AppBarItem p={0}>
            <AppBarUserAvatar />
          </AppBarItem>
        </HStack>
      </HStack>
    </Stack>
  );
}
