import Color from "color";
import tc from "tinycolor2";
import tinygradient from "tinygradient";

export { tc };
// move these to tinycolor

export const enurgenDark = tc("#000437");
export const enurgenDarkActive = enurgenDark.clone().lighten(15).desaturate(75);

export const enurgenPrimary = Color("#070C27");
export const enurgenPrimary10 = Color("#EEF0FE");
export const enurgenPrimaryAccent = Color("#4250B6");

export const enurgenGradient0 = Color("#B34FF7");
export const enurgenGradient75 = Color("#ED72C0");
export const enurgenGradient100 = Color("#FADE70");

export const enurgenLogoPurple = Color("#8E38DE");
export const enurgenLogoPink = Color("#DC54AA");
export const enurgenLogoYellow = Color("#E8C53F");

export const enurgenMonofacial = Color("#fca636");
export const enurgenBifacial = Color("#ed72c0");

export const enurgenPurple = tc("#8E38DE");
export const enurgenPink = tc("#DC54AA");

export const enurgenYellow = tc("#E8C53F", {
  format: "hex",
});
export const enurgenRed = tc("#f46d43", {
  format: "hex",
});
export const enurgenOrange = tc("#fca636", {
  format: "hex",
});

export const enurgenBlue = tc("#0b99ff", {
  format: "hex",
});

export const enurgenWhite = tc("#FFFFFF", {
  format: "hex",
});

// https://waldyrious.net/viridis-palette-generator/

// from https://matplotlib.org/stable/tutorials/colors/colormaps.html#sequential
export const plasmaGradientColorStops = [
  "#0d0887",
  "#6a00a8",
  "#b12a90",
  "#e16462",
  "#fca636",
  "#f0f921",
];
export const plasmaGradient = tinygradient(plasmaGradientColorStops);

// from https://matplotlib.org/stable/tutorials/colors/colormaps.html#sequential
export const viridisGradient = tinygradient([
  "#440154",
  "#414487",
  "#2a788e",
  "#22a884",
  "#7ad151",
  "#fde725",
]);

export const enurgenTeal = tc("#2a788e", {
  format: "hex",
});

export const enurgenGradient = tinygradient([
  "#B34FF7",
  "#ED72C0",
  "#E8C53F",
  "#f46d43",
]);

export const enurgenCategoryChartPalette = [
  tc("#fca636"),
  // tc("#ff8748"),
  // tc("#ff6a5f"),
  tc("#f85278"),
  tc("#df4590"),
  tc("#bb45a4"),
  tc("#894bb2"),
  // tc("#4250b6"),
];

export const enurgenBrandIndigo = tc("#2F308D");
export const enurgenBrandMagenta = tc("#862F8B");
export const enurgenBrandOrange = tc("#E68953");
export const enurgenBrandYellow = tc("#F7ED4C");

export const enurgenBrandGradient = tinygradient([
  enurgenBrandIndigo.toHexString(),
  enurgenBrandMagenta.toHexString(),
  enurgenBrandOrange.toHexString(),
  enurgenBrandYellow.toHexString(),
]);

export function toRgbaArray(color: tc.Instance) {
  const { r, g, b, a } = color.toRgb();
  return [r, g, b, a];
}

export function toRgbArray(color: tc.Instance) {
  const arr = toRgbaArray(color);
  arr.pop();
  return arr;
}

export const jetGradient = tinygradient([
  "#00007F",
  "#0000FF",
  "#007FFF",
  "#00FFFF",
  "#7FFF7F",
  "#FFFF00",
  "#FF7F00",
  "#FF0000",
  "#7F0000",
]);
