import { Box, Button, HStack, Spacer, Text, useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppUrls } from "~/appUrls";
import { useAuth } from "~/features/auth/AuthContext";
import { useActiveUser } from "~/features/user/hooks";
import {
  UserFragmentFragment,
  useAdminCopyProjectToSelfMutation,
  useAdminUserMeQuery,
  useEndImpersonateUserMutation,
} from "~/gql/generated";
import { formatDate } from "~/util/formatDate";
import { useProjectContext } from "../projectsCommon/components/ProjectProvider";

export function AdminImpersonateAppBar() {
  const { isImpersonating } = useAuth();

  if (!isImpersonating) return null;

  return <AdminImpersonateAppBarContent />;
}

function AdminImpersonateAppBarContent() {
  const { projectId } = useParams<{ projectId: string }>();
  const { endImpersonate } = useAuth();
  const user = useActiveUser();
  const navigate = useNavigate();
  const urls = useAppUrls();
  const { data } = useAdminUserMeQuery();
  const [endImpersonateMutation] = useEndImpersonateUserMutation();

  const superUser = data?.adminMe;

  if (!superUser) return null;

  return (
    <Box
      position="sticky"
      top={0}
      zIndex={99999}
      bg="gray.50"
      fontSize="sm"
      p={2}
    >
      <HStack>
        <Text>
          <b>Admin:</b> {superUser?.email}
        </Text>
        <Text>
          <b>Impersonating:</b> {user.user?.email}
        </Text>
        <Spacer />
        {projectId && <AdminCopyProjectToSelf superUser={superUser} />}
        <Button
          size="xs"
          variant="outline"
          onClick={async () => {
            await endImpersonateMutation();
            endImpersonate();
            navigate(urls.adminImpersonateUser());
            window.location.reload();
          }}
        >
          End Impersonate Session
        </Button>
      </HStack>
    </Box>
  );
}

interface AdminUserProp {
  superUser: UserFragmentFragment;
}
function AdminCopyProjectToSelf({ superUser }: AdminUserProp) {
  const [copyProjectToSelfMutation, copyProjectToSelfMutationQuery] =
    useAdminCopyProjectToSelfMutation();
  const toast = useToast();
  const activeUser = useActiveUser();
  const { project } = useProjectContext();
  const ts = formatDate(new Date(), "yyyy-MM-dd HH:mm:ss");

  async function handleCopyProject() {
    if (!project) return;
    const copyProjectName = `Copy from ${activeUser.user?.email}: ${project.name} @ ${ts}`;
    await copyProjectToSelfMutation({
      sourceProjectId: project?.id,
      copyProjectName,
    });
    toast({
      status: "success",
      isClosable: true,
      description: (
        <Text>
          Successfully copied project as {copyProjectName} to {superUser.email}
        </Text>
      ),
    });
  }

  return (
    <>
      <Button
        size="xs"
        variant="outline"
        isLoading={copyProjectToSelfMutationQuery.isLoading}
        onClick={() => handleCopyProject()}
      >
        Copy Project to Self
      </Button>
    </>
  );
}
