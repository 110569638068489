import { SVGProps } from "react";

export const TableSvgIcon = (p: SVGProps<any>) => {
  return (
    <svg
      id="svg5"
      version="1.1"
      viewBox="0 0 73.479645 47.099564"
      height="20"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g
        id="layer1"
        style={{ display: "inline" }}
        transform="translate(-40.766194,-226.45402)"
      >
        <g
          id="g9048"
          transform="matrix(1.0150334,0,0,1.0150334,-0.60771082,-3.3992324)"
          style={{ strokeWidth: "1.97038", strokeDasharray: "none" }}
        >
          <rect
            style={{
              fill: "none",
              fillOpacity: "1",
              stroke: "#000000",
              strokeWidth: "1.97038",
              strokeDasharray: "none",
            }}
            id="rect357"
            width="13.355396"
            height="19.223314"
            x="-55.101711"
            y="227.43427"
            transform="scale(-1,1)"
          />
          <rect
            style={{
              fill: "none",
              fillOpacity: "1",
              stroke: "#000000",
              strokeWidth: "1.97038",
              strokeDasharray: "none",
            }}
            id="rect913"
            width="13.355387"
            height="19.223299"
            x="-74.123558"
            y="227.43414"
            transform="scale(-1,1)"
          />
          <rect
            style={{
              fill: "none",
              fillOpacity: "1",
              stroke: "#000000",
              strokeWidth: "1.97038",
              strokeDasharray: "none",
            }}
            id="rect915"
            width="13.355387"
            height="19.223299"
            x="-93.145416"
            y="227.43414"
            transform="scale(-1,1)"
          />
          <rect
            style={{
              fill: "none",
              fillOpacity: "1",
              stroke: "#000000",
              strokeWidth: "1.97038",
              strokeDasharray: "none",
            }}
            id="rect917"
            width="13.355387"
            height="19.223299"
            x="-55.101704"
            y="252.64244"
            transform="scale(-1,1)"
          />
          <rect
            style={{
              fill: "none",
              fillOpacity: "1",
              stroke: "#000000",
              strokeWidth: "1.97038",
              strokeDasharray: "none",
            }}
            id="rect919"
            width="13.355387"
            height="19.223299"
            x="-74.123558"
            y="252.64244"
            transform="scale(-1,1)"
          />
          <rect
            style={{
              fill: "none",
              fillOpacity: "1",
              stroke: "#000000",
              strokeWidth: "1.97038",
              strokeDasharray: "none",
            }}
            id="rect921"
            width="13.355387"
            height="19.223299"
            x="-93.145416"
            y="252.64244"
            transform="scale(-1,1)"
          />
          <rect
            style={{
              fill: "none",
              fillOpacity: "1",
              stroke: "#000000",
              strokeWidth: "1.97038",
              strokeDasharray: "none",
            }}
            id="rect923"
            width="13.355387"
            height="19.223299"
            x="-112.16728"
            y="227.43414"
            transform="scale(-1,1)"
          />
          <rect
            style={{
              fill: "none",
              fillOpacity: "1",
              stroke: "#000000",
              strokeWidth: "1.97038",
              strokeDasharray: "none",
            }}
            id="rect925"
            width="13.355387"
            height="19.223299"
            x="-112.16728"
            y="252.64244"
            transform="scale(-1,1)"
          />
        </g>
      </g>
    </svg>
  );
};
