import * as parameterInfo from "@duet/shared/app_data/DUET-Designer_ParameterInfo.json";
import {
  ComponentType,
  LineLossCalculationMethod,
  LineMetalType,
} from "@duet/shared/pvComponents/projectParameters";
import { ComponentInputMetadata, Unit } from "@duet/shared/pvComponents/types";

export const dcWireInputMetadata: ComponentInputMetadata<ComponentType.DcWire> =
  {
    dc_line_metal_type: {
      labelText: parameterInfo.dc_line_metal_type.display_name,
      fullLabelText: parameterInfo.dc_line_metal_type.parameter_name,
      helpText: parameterInfo.dc_line_metal_type.description,
      hideIf: ({ dc_line_calculation_method }) => {
        return dc_line_calculation_method !== "computed_resistance";
      },
      options: [
        { value: LineMetalType.Cu, label: "Copper" },
        { value: LineMetalType.Al, label: "Aluminum" },
      ],
    },
    dc_line_length: {
      labelText: parameterInfo.avg_dc_string_cabling_length.display_name,
      helpText: parameterInfo.avg_dc_string_cabling_length.description,
      unit: parameterInfo.avg_dc_string_cabling_length.units as Unit,
      hideIf: ({ dc_line_calculation_method }) => {
        return dc_line_calculation_method !== "computed_resistance";
      },
    },
    dc_per_km_resistance: {
      labelText: parameterInfo.dc_resistance_per_km.display_name,
      fullLabelText: parameterInfo.dc_resistance_per_km.parameter_name,
      helpText: parameterInfo.dc_resistance_per_km.description,
      unit: parameterInfo.dc_resistance_per_km.units as Unit,
      hideIf: ({ dc_line_calculation_method }) => {
        return dc_line_calculation_method !== "computed_resistance";
      },
    },
    dc_line_cross_section: {
      labelText: parameterInfo.dc_line_cross_section.display_name,
      fullLabelText: parameterInfo.dc_line_cross_section.parameter_name,
      helpText: parameterInfo.dc_line_cross_section.description,
      unit: parameterInfo.dc_line_cross_section.units as Unit,
      hideIf: ({ dc_line_calculation_method }) => {
        return dc_line_calculation_method !== "computed_resistance";
      },
    },
    total_dc_resistance_override: {
      labelText: parameterInfo.dc_total_string_resistance.display_name,
      helpText: parameterInfo.dc_total_string_resistance.description,
      unit: parameterInfo.dc_total_string_resistance.units as Unit,
      hideIf: ({ dc_line_calculation_method }) => {
        return dc_line_calculation_method !== "total_resistance";
      },
    },
    dc_line_loss_percentage: {
      labelText: parameterInfo.dc_loss_percentage_STC.display_name,
      fullLabelText: parameterInfo.dc_loss_percentage_STC.parameter_name,
      helpText: parameterInfo.dc_loss_percentage_STC.description,
      unit: parameterInfo.dc_loss_percentage_STC.units as Unit,
      hideIf: ({ dc_line_calculation_method }) => {
        return dc_line_calculation_method !== "percentage_loss";
      },
    },
    dc_line_calculation_method: {
      labelText: parameterInfo.dc_calc_method.display_name,
      helpText: parameterInfo.dc_calc_method.description,
      options: [
        {
          value: LineLossCalculationMethod.PercentageLoss,
          label: "Percentage Loss",
        },
        {
          value: LineLossCalculationMethod.TotalResistance,
          label: "Custom Resistance",
        },
        {
          value: LineLossCalculationMethod.ComputedResistance,
          label: "Computed Resistance",
        },
      ],
    },
  };
