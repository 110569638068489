import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import { enurgenPrimary } from "./color";

export const buttonTheme = defineStyleConfig({
  variants: {
    primary: defineStyle({
      bg: enurgenPrimary.hex(),
      color: "white",
      zIndex: 1,
      _hover: {
        bg: enurgenPrimary.lighten(0.1).hex(),
        _disabled: {
          bg: "grey",
        },
      },
      _active: {
        bg: enurgenPrimary.lighten(0.1).hex(),
      },
      _loading: {
        bg: enurgenPrimary.lighten(0.1).hex(),
        _hover: {
          bg: enurgenPrimary.lighten(0.1).hex(),
        },
      },
    }),
    wwwOutline: defineStyle({
      borderWidth: "thin",
      borderColor: "gray.900",
    }),
    link: defineStyle({
      color: "gray.900",
    }),
  },
});
