import { createSlice, isAnyOf, type PayloadAction } from "@reduxjs/toolkit";
import { assign } from "lodash";
import { SetOptional } from "type-fest";
import { api, OrganizationFragmentFragment } from "~/gql/generated";

export interface OrganizationState {
  organization: OrganizationFragmentFragment | null;
}

const initialState: OrganizationState = {
  organization: null,
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setOrganization: (
      state,
      action: PayloadAction<SetOptional<
        OrganizationFragmentFragment,
        "allowed_domains" | "user_organization"
      > | null>
    ) => {
      state.organization = assign(
        { allowed_domains: [], user_organization: [] },
        state.organization,
        action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(api.endpoints.GetOrganization.matchFulfilled),
      (state, action) => {
        state.organization = action.payload.organization;
      }
    );
  },
});

export const { setOrganization } = organizationSlice.actions;
