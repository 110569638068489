import "@fontsource/ibm-plex-mono/index.css";
import "@fontsource/ibm-plex-sans/index.css";
import "@fontsource/jetbrains-mono/index.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { useMemo } from "react";
import "react-day-picker/dist/style.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { RootProviders } from "./AppProviders";
import { AppURLs } from "./appUrls";
import { AuthRoute } from "./features/auth/AuthRoute";
import { InvitationRegisterPage } from "./features/auth/pages/InvitationRegisterPage";
import { LoginPage } from "./features/auth/pages/LoginPage";
import { PasswordResetPage } from "./features/auth/pages/PasswordResetPage";
import { RegisterPage } from "./features/auth/pages/RegisterPage";

export function App() {
  return (
    <RootProviders>
      <AppRouter />
    </RootProviders>
  );
}

function makeLazyAuthRoute<T extends () => Promise<React.ComponentType>>(
  importer: T
) {
  return makeLazyRoute(importer, AuthRoute);
}

function makeLazyRoute<T extends () => Promise<React.ComponentType>>(
  importer: T,
  Container?: React.ComponentType<{ component: React.ReactNode }>
) {
  return async function lazy() {
    const LazyComponent = await importer();

    function Component() {
      if (!Container) {
        return <LazyComponent />;
      }
      return <Container component={<LazyComponent />} />;
    }

    return { Component };
  };
}

const lazyNotFoundPage = makeLazyAuthRoute(() =>
  import("./components/NotFound/NotFound").then(
    (m) =>
      function NotFoundPage() {
        return <m.NotFound resource="page" />;
      }
  )
);

function AppRouter() {
  const url = useMemo(
    () =>
      new AppURLs({
        organizationSlug: ":organization",
      }),
    []
  );
  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          lazy: () =>
            import("./AppProviders").then((m) => ({
              Component: m.AppProviders,
            })),
          children: [
            {
              path: url.indexRoute().toString(),
              lazy: makeLazyAuthRoute(() =>
                import("./features/projectOpen/ProjectOpenPage").then(
                  (m) => m.ProjectOpenPage
                )
              ),
            },
            {
              path: url.adminFlags().toString(),
              lazy: makeLazyAuthRoute(() =>
                import("./features/admin/AdminFlags").then((m) => m.AdminFlags)
              ),
            },
            {
              path: url.adminInviteUser().toString(),
              lazy: makeLazyAuthRoute(() =>
                import("./features/admin/AdminInviteUser").then(
                  (m) => m.AdminInviteUser
                )
              ),
            },
            {
              path: url.adminInverterDataset().toString(),
              lazy: makeLazyAuthRoute(() =>
                import("./features/admin/AdminInverterDataset").then(
                  (m) => m.AdminInverterDataset
                )
              ),
            },
            {
              path: url.adminImpersonateUser().toString(),
              lazy: makeLazyAuthRoute(() =>
                import("./features/admin/AdminImpersonateUser").then(
                  (m) => m.AdminImpersonateUser
                )
              ),
            },
            {
              path: url.projectOpenRoute().toString(),
              lazy: makeLazyAuthRoute(() =>
                import("./features/projectOpen/ProjectOpenPage").then(
                  (m) => m.ProjectOpenPage
                )
              ),
            },
            {
              path: url.projectRoute(":projectId").toString(),
              lazy: () =>
                import(
                  "./features/projectsCommon/components/ProjectProvider"
                ).then((m) => ({
                  Component: m.ProjectProvider,
                })),
              children: [
                {
                  path: url.projectDesignBlockRoute(":projectId").toString(),
                  lazy: makeLazyAuthRoute(() =>
                    import(
                      "./features/projectDesign/pages/ProjectDesignBlockPage/ProjectDesignBlockPage"
                    ).then((m) => m.ProjectDesignBlockPage)
                  ),
                },
                {
                  path: url
                    .projectDesignComponentRoute(
                      ":projectId",
                      ":componentType?",
                      ":componentId?"
                    )
                    .toString(),
                  lazy: makeLazyAuthRoute(() =>
                    import(
                      "./features/projectDesign/pages/ProjectDesignComponentPage/ProjectDesignComponentPage"
                    ).then((m) => m.ProjectDesignComponentPage)
                  ),
                },
                {
                  path: url.projectSimBlocksRoute(":projectId").toString(),
                  lazy: makeLazyAuthRoute(() =>
                    import(
                      "./features/projectDesign/pages/ProjectSimBlockPage/ProjectSimBlockPage"
                    ).then((m) => m.ProjectSimBlockPage)
                  ),
                },
                {
                  path: url
                    .projectSimMeteoRoute(":projectId", ":enviroDatasetId?")
                    .toString(),
                  lazy: makeLazyAuthRoute(() =>
                    import(
                      "./features/projectDesign/pages/ProjectSimMeteoPage/ProjectSimMeteoPage"
                    ).then((m) => m.ProjectSimMeteoPage)
                  ),
                },
                {
                  path: url
                    .projectSimulationsIndexRoute(":projectId")
                    .toString(),
                  lazy: makeLazyAuthRoute(() =>
                    import(
                      "./features/projectSimResults/ProjectSimIndexPage"
                    ).then((m) => m.ProjectSimIndexPage)
                  ),
                },
                {
                  path: url
                    .projectSimulationRoute(":projectId", ":simulationId")
                    .toString(),
                  lazy: () =>
                    import(
                      "./features/projectSimDashboards/SimDashboardContextProvider"
                    ).then((m) => ({
                      Component: m.SimDashboardContextProvider,
                    })),
                  children: [
                    {
                      path: url
                        .projectSimulationsDetailRoute(
                          ":projectId",
                          ":simulationId",
                          "energy-yields"
                        )
                        .toString(),
                      lazy: makeLazyAuthRoute(() =>
                        import(
                          "./features/projectSimDashboards/ProjectSimEnergyYieldDashboard"
                        ).then((m) => m.ProjectSimEnergyYieldDashboard)
                      ),
                    },
                    {
                      path: url
                        .projectSimulationsDetailRoute(
                          ":projectId",
                          ":simulationId",
                          "dc-energy-yields"
                        )
                        .toString(),
                      lazy: makeLazyAuthRoute(() =>
                        import(
                          "./features/projectSimDashboards/monthlyEnergyYields/MonthlyDCEnergyPage"
                        ).then((m) => m.MonthlyDCEnergyYields)
                      ),
                    },
                    {
                      path: url
                        .projectSimulationsDetailRoute(
                          ":projectId",
                          ":simulationId",
                          "ac-energy-yields"
                        )
                        .toString(),
                      lazy: makeLazyAuthRoute(() =>
                        import(
                          "./features/projectSimDashboards/monthlyEnergyYields/MonthlyACEnergyPage"
                        ).then((m) => m.MonthlyACEnergy)
                      ),
                    },
                    {
                      path: url
                        .projectSimulationsDetailRoute(
                          ":projectId",
                          ":simulationId",
                          "energy-losses"
                        )
                        .toString(),
                      lazy: makeLazyAuthRoute(() =>
                        import(
                          "./features/projectSimDashboards/lossDiagram/ProjectSimLosses"
                        ).then((m) => m.ProjectSimDashboardLosses)
                      ),
                    },
                    {
                      path: url
                        .projectSimulationsDetailRoute(
                          ":projectId",
                          ":simulationId",
                          "drill-down"
                        )
                        .toString(),
                      lazy: makeLazyAuthRoute(() =>
                        import(
                          "./features/projectSimDashboards/timeSeriesAnalysis/TimeSeriesAnalysisPage"
                        ).then((m) => m.ProjectSimTimeSeriesAnalysisPage)
                      ),
                    },
                    {
                      path: url
                        .projectSimulationsDetailRoute(
                          ":projectId",
                          ":simulationId",
                          "irradiance"
                        )
                        .toString(),
                      lazy: makeLazyAuthRoute(() =>
                        import(
                          "./features/projectSimDashboards/ProjectIrradianceDashboard"
                        ).then((m) => m.ProjectIrradianceDashboard)
                      ),
                    },
                    {
                      path: url
                        .projectSimulationsDetailRoute(
                          ":projectId",
                          ":simulationId",
                          "data-export"
                        )
                        .toString(),
                      lazy: makeLazyAuthRoute(() =>
                        import(
                          "./features/projectSimDashboards/simExport/ProjectSimDataExportPage"
                        ).then((m) => m.ProjectSimDataExportPage)
                      ),
                    },
                    {
                      path: url
                        .projectSimulationsDetailRoute(
                          ":projectId",
                          ":simulationId",
                          "pdf-report"
                        )
                        .toString(),
                      lazy: makeLazyAuthRoute(() =>
                        import(
                          "./features/projectSimDashboards/PDFReportPage"
                        ).then((m) => m.PDFReportPage)
                      ),
                    },
                    {
                      path: url
                        .projectSimulationsDetailRoute(
                          ":projectId",
                          ":simulationId",
                          "module-irradiance"
                        )
                        .toString(),
                      lazy: makeLazyAuthRoute(() =>
                        import(
                          "./features/projectSimDashboards/moduleIrradianceProfiles/ModuleIrradianceProfilesPage"
                        ).then((m) => m.ModuleIrradianceProfilesPage)
                      ),
                    },
                    {
                      path: url
                        .projectSimulationsDetailRoute(
                          ":projectId",
                          ":simulationId",
                          "pvsyst-factors"
                        )
                        .toString(),
                      lazy: makeLazyAuthRoute(() =>
                        import(
                          "./features/projectSimDashboards/ProjectSimPvsystFactors"
                        ).then((m) => m.ProjectSimPvsystFactors)
                      ),
                    },
                  ],
                },
                {
                  path: url.projectCompareIndexRoute(":projectId").toString(),
                  lazy: makeLazyAuthRoute(() =>
                    import(
                      "./features/projectCompare/ProjectCompareIndexPage"
                    ).then((m) => m.ProjectCompareIndexPage)
                  ),
                },
                {
                  path: url
                    .projectCompareWorkbookRoute(":projectId", ":workbookId")
                    .toString(),
                  lazy: makeLazyAuthRoute(() =>
                    import("./features/projectCompare/CompareAppEmbedded").then(
                      (m) => m.CompareAppEmbedded
                    )
                  ),
                },
                {
                  path: url.projectMonitorIndexRoute(":projectId").toString(),
                  lazy: makeLazyAuthRoute(() =>
                    import(
                      "./features/sentinelDashboards/SentinelPlantDashboard"
                    ).then((m) => m.SentinelPlantDashboard)
                  ),
                },
                {
                  path: url
                    .projectMonitorTimeSeriesAnalysis(":projectId")
                    .toString(),
                  lazy: makeLazyAuthRoute(() =>
                    import(
                      "./features/sentinelDashboards/SentinelTimeSeriesAnalysis"
                    ).then((m) => m.SentinelTimeSeriesAnalysisPage)
                  ),
                },
                {
                  path: url
                    .projectMonitorPlantPlayback(":projectId", ":date")
                    .toString(),
                  lazy: makeLazyAuthRoute(() =>
                    import(
                      "./features/sentinelDashboards/SentinelPlantPlayback"
                    ).then((m) => m.SentinelPlantPlayback)
                  ),
                },
                {
                  path: url
                    .projectMonitorPlantExplorer(":projectId")
                    .toString(),
                  lazy: makeLazyAuthRoute(() =>
                    import(
                      "./features/sentinelDashboards/SentinelPlantExplorer"
                    ).then((m) => m.SentinelPlantExplorer)
                  ),
                },
                {
                  path: url.projectMonitorOverview(":projectId").toString(),
                  lazy: makeLazyAuthRoute(() =>
                    import(
                      "./features/sentinelDashboards/SentinelOverview"
                    ).then((m) => m.SentinelOverview)
                  ),
                },
                {
                  path: url.projectMonitorPerformance(":projectId").toString(),
                  lazy: makeLazyAuthRoute(() =>
                    import(
                      "./features/sentinelDashboards/SentinelPerformance"
                    ).then((m) => m.SentinelPerformance)
                  ),
                },
                {
                  path: url.projectMonitorIrradiance(":projectId").toString(),
                  lazy: makeLazyAuthRoute(() =>
                    import(
                      "./features/sentinelDashboards/SentinelIrradiance"
                    ).then((m) => m.SentinelIrradiance)
                  ),
                },
                {
                  path: url
                    .projectMonitorModelVsActual(":projectId")
                    .toString(),
                  lazy: makeLazyAuthRoute(() =>
                    import(
                      "./features/sentinelDashboards/SentinelModelVsActual"
                    ).then((m) => m.SentinelModelVsActual)
                  ),
                },
                {
                  path: url.projectMonitorAlerts(":projectId").toString(),
                  lazy: makeLazyAuthRoute(() =>
                    import("./features/sentinelDashboards/SentinelAlerts").then(
                      (m) => m.SentinelAlerts
                    )
                  ),
                },
                {
                  path: url.projectMonitorIssues(":projectId").toString(),
                  lazy: makeLazyAuthRoute(() =>
                    import("./features/sentinelDashboards/SentinelIssues").then(
                      (m) => m.SentinelIssues
                    )
                  ),
                },
                {
                  path: url.projectPlantPlayback(":projectId").toString(),
                  lazy: makeLazyAuthRoute(() =>
                    import("./features/plantPlayback/PlantPlaybackPage").then(
                      (m) => m.PlantPlaybackPage
                    )
                  ),
                },
                {
                  path: url
                    .projectPlantPlayback(":projectId", ":id")
                    .toString(),
                  lazy: makeLazyAuthRoute(() =>
                    import("./features/plantPlayback/PlantPlaybackPage").then(
                      (m) => m.PlantPlaybackPage
                    )
                  ),
                },
                {
                  path: url
                    .projectMonitorStubPage(":projectId", ":title")
                    .toString(),
                  lazy: makeLazyAuthRoute(() =>
                    import(
                      "./features/sentinelDashboards/SentintelStubPage"
                    ).then((m) => m.SentinelStubPage)
                  ),
                },
                {
                  path: url
                    .projectMonitorTableIrradianceProfile(":projectId")
                    .toString(),
                  lazy: makeLazyAuthRoute(() =>
                    import(
                      "./features/sentinelDashboards/SentinelTableIrradianceProfiles"
                    ).then((m) => m.SentinelTableIrradianceProfiles)
                  ),
                },
              ],
            },
            {
              path: url.settingsIndexPage().toString(),
              lazy: makeLazyAuthRoute(() =>
                import("./features/settings/SettingsIndexPage").then(
                  (m) => m.SettingsIndexPage
                )
              ),
            },
            {
              path: url.settingsUserApiTokens().toString(),
              lazy: makeLazyAuthRoute(() =>
                import("./features/userApiTokens/UserApiTokensPage").then(
                  (m) => m.UserApiTokensPage
                )
              ),
            },
            {
              path: url.settingsUserAccountEdit().toString(),
              lazy: makeLazyAuthRoute(() =>
                import("./features/user/UserAccountEditPage").then(
                  (m) => m.UserAccountEditPage
                )
              ),
            },
            {
              path: url.pv3Playground("panel").toString(),
              lazy: makeLazyAuthRoute(() =>
                import("./features/pv3Playground/Pv3Panel").then(
                  (m) => m.Pv3Panel
                )
              ),
            },
            {
              path: url.pv3Playground("array").toString(),
              lazy: makeLazyAuthRoute(() =>
                import("./features/pv3Playground/Pv3Array").then(
                  (m) => m.Pv3Array
                )
              ),
            },
            {
              path: url.onboardingIntakeForm().toString(),
              lazy: makeLazyAuthRoute(() =>
                import("./features/onboarding/UserOnboardingIntakePage").then(
                  (m) => m.UserOnboardingIntakePage
                )
              ),
            },
            {
              path: url.emailVerification().toString(),
              lazy: makeLazyAuthRoute(() =>
                import("./features/auth/pages/EmailVerificationIntercept").then(
                  (m) => m.EmailVerificationIntercept
                )
              ),
            },
            {
              path: url.organizationPicker().toString(),
              lazy: makeLazyAuthRoute(() =>
                import(
                  "./features/organization/pages/OrganizationPickerPage"
                ).then((m) => m.OrganizationPickerPage)
              ),
            },
            {
              path: url.settingsOrganizationEdit().toString(),
              lazy: makeLazyAuthRoute(() =>
                import(
                  "./features/organization/pages/organizationSettings/OrganizationSettingsPage"
                ).then((m) => m.OrganizationSettingsPage)
              ),
            },
            {
              path: "*",
              lazy: lazyNotFoundPage,
            },
            {
              path: url.loginRoute().toString(),
              element: <LoginPage />,
            },
            {
              path: url.registerRoute().toString(),
              element: <RegisterPage />,
            },
            {
              path: url.passwordReset().toString(),
              element: <PasswordResetPage />,
            },
            {
              path: url.invitationRegister(":token").toString(),
              element: <InvitationRegisterPage />,
            },
          ],
        },
        {
          path: "*",
          lazy: lazyNotFoundPage,
        },

        // dev only routes

        ...[
          {
            path: "/_email",
            lazy: makeLazyRoute(() =>
              import("./features/emailLocalDev/EmailPreviewRoute").then(
                (m) => m.EmailPreviewRoute
              )
            ),
          },
        ].filter(Boolean),
      ]),
    []
  );

  return <RouterProvider router={router} />;
}
