import { upperFirst } from "lodash";

export function extractEmailDomain(email: string): string | undefined {
  const [, domain] = email.split("@");
  return domain;
}

export function emailToOrgName(email: string) {
  const domain = extractEmailDomain(email);
  return domain ? upperFirst(domain.split(".")[0]) : undefined;
}
