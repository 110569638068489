import { Box, Center, Stack, Text } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { SolarCellCool, SolarCellWarm } from "./SolarCell";

export function LoadingOverlay({ isLoading }: { isLoading: boolean }) {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      h="100%"
      w="100%"
      bgColor={isLoading ? "whiteAlpha.700" : undefined}
      backdropFilter={isLoading ? "blur(3px)" : undefined}
      zIndex={10}
      transitionProperty={"backdrop-filter, background-color"}
      transitionDuration={"0.2s"}
      transitionTimingFunction={"ease-in-out"}
      pointerEvents={"none"}
    >
      <Center h="100%">{isLoading && <CellLoader />}</Center>
    </Box>
  );
}

function CellLoader() {
  return (
    <Stack css={cellLoaderCss} align={"center"}>
      <Box
        width={10}
        height={10}
        my={2}
        className="cell-loader"
        position={"relative"}
      >
        <Box
          className="dark"
          position={"absolute"}
          filter={"saturate(0)"}
          width={"inherit"}
          opacity={"33%"}
        >
          <SolarCellCool />
        </Box>
        <Box className="cool">
          <SolarCellCool />
        </Box>
        <Box className="warm">
          <SolarCellWarm />
        </Box>
        <Box
          className="shadow"
          position={"absolute"}
          bottom={-3}
          left={0}
          bg="blackAlpha.400"
          width="100%"
          height={1}
          borderRadius={"33%"}
          filter={"blur(5px)"}
        />
      </Box>
      <Text fontFamily={"mono"}>Loading</Text>
    </Stack>
  );
}

const duration = 0.66;
const delay = 0.1;

const cellLoaderCss = css`
  .cell-loader .cool {
    position: absolute;
    width: inherit;
  }

  .cell-loader .warm {
    position: absolute;
    width: inherit;
  }

  .cell-loader .warm svg path,
  .cell-loader .cool svg path {
    opacity: 0%;
  }

  .cell-loader .cool svg path,
  .cell-loader .warm svg path {
    animation-name: opacity;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
  }

  .cell-loader .cool svg path {
    animation-duration: ${duration}s;
  }

  ${Array.from(Array(18))
    .map(
      (_, i) => `
    .cell-loader .cool svg path:nth-last-child(${i}n) {
      animation-delay: ${duration * delay + i * 0.02}s;
    }
    
  `
    )
    .join("\n")}

  .cell-loader .warm svg path {
    animation-duration: ${duration}s;
    animation-delay: ${duration * delay}s;
  }

  ${Array.from(Array(18))
    .map(
      (_, i) => `
    .cell-loader .warm svg path:nth-child(${i}n) {
      animation-delay: ${duration + i * 0.02}s;
    }
  `
    )
    .join("\n")}

  @keyframes opacity {
    0% {
      opacity: 0%;
    }
    25% {
      opacity: 0%;
    }
    75% {
      opacity: 100%;
    }
    100% {
      opacity: 100%;
    }
  }
`;
