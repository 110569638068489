import * as z from "zod";

export const errorMap: z.ZodErrorMap = (error, ctx) => {
  /*

  If error.message is set, that means the user is trying to
  override the error message. This is how method-specific
  error overrides work, like this:

  z.string().min(5, { message: "TOO SMALL 🤬" })

  It is a best practice to return `error.message` if it is set.

  */
  if (error.message) return { message: error.message };

  /*
  This is where you override the various error codes
  */
  switch (error.code) {
    case z.ZodIssueCode.too_small:
      if (error.inclusive) {
        return {
          message:
            error.minimum === 0
              ? "must be non-negative"
              : `minimum ${error.minimum}`,
        };
      } else {
        return {
          message:
            error.minimum === 0
              ? "must be positive"
              : `must be greater than ${error.minimum}`,
        };
      }
    case z.ZodIssueCode.too_big:
      if (error.inclusive) {
        return {
          message:
            error.maximum === 0
              ? "must be non-positive"
              : `maximum ${error.maximum}`,
        };
      } else {
        return {
          message:
            error.maximum === 0
              ? "must be negative"
              : `must be less than ${error.maximum}`,
        };
      }
    case z.ZodIssueCode.invalid_type:
      if (error.expected === "integer") {
        return { message: "must be integer" };
      }
      if (error.received === "null") {
        return { message: "required" };
      }
  }

  // fall back to default message!
  return { message: ctx.defaultError };
};
