import { Alert, Box, Button, Input, Link, Stack } from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { useAppUrls } from "~/appUrls";
import { FormField } from "~/components/FormField";
import { RouterLink } from "~/components/Link";
import { useAuth } from "~/features/auth/AuthContext";
import { ampli } from "~/tracking/amplitude";
import { AuthFormContainer } from "../components/AuthFormContainer";
import { AuthPageContainer } from "../components/AuthPageContainer";

export function PasswordResetPage() {
  const urls = useAppUrls();
  const form = useForm({
    defaultValues: {
      username: "",
    },
  });
  const auth = useAuth();
  const [isSent, setIsSent] = useState(false);

  if (auth.isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <AuthPageContainer>
      <AuthFormContainer title="Forgot Password">
        <Box w="100%">
          <form
            onSubmit={form.handleSubmit(async ({ username }) => {
              await auth.firebaseSendPasswordResetEmail(username);
              setIsSent(true);
              ampli.passwordReset({
                email: username,
              });
            })}
          >
            <Stack direction="column" spacing={5}>
              <FormField label="Email">
                <Input
                  type="email"
                  placeholder="user@enurgen.com"
                  {...form.register("username")}
                />
              </FormField>

              {form.formState.isSubmitted &&
                form.formState.isDirty &&
                !auth.isLoading &&
                !!auth.err && (
                  <>
                    <Alert status="error">Incorrect username or password</Alert>
                  </>
                )}
              <Button
                type="submit"
                variant={"primary"}
                isLoading={auth.isLoading}
              >
                Request Password Reset
              </Button>
              {isSent && (
                <Alert>
                  Please check your email for password reset instructions.
                </Alert>
              )}
              <Link as={RouterLink} to={urls.loginRoute()} textAlign="center">
                &larr; Back to Login
              </Link>
            </Stack>
          </form>
        </Box>
      </AuthFormContainer>
    </AuthPageContainer>
  );
}
