import {
  ComponentType,
  LineLossCalculationMethod,
  LineMetalType,
} from "@duet/shared/pvComponents/projectParameters";
import { ComponentInputMetadata } from "@duet/shared/pvComponents/types";

export const acWireInputMetadata: ComponentInputMetadata<ComponentType.AcWire> =
  {
    ac_line_calculation_method: {
      labelText: "Calculation Method",
      options: [
        {
          value: LineLossCalculationMethod.PercentageLoss,
          label: "Percentage Loss",
        },
        {
          value: LineLossCalculationMethod.TotalResistance,
          label: "Custom Resistance",
        },
        {
          value: LineLossCalculationMethod.ComputedResistance,
          label: "Computed Resistance",
        },
      ],
    },
    ac_line_loss_percentage: {
      labelText: "Loss Percentage at STC",
      fullLabelText: "DC Line Loss Percentage",
      unit: "%",
      hideIf: ({ ac_line_calculation_method }) => {
        return ac_line_calculation_method !== "percentage_loss";
      },
    },
    total_ac_resistance_override: {
      labelText: "Total Resistance (1 wire)",
      unit: "Ω",
      hideIf: ({ ac_line_calculation_method }) => {
        return ac_line_calculation_method !== "total_resistance";
      },
    },
    ac_line_metal_type: {
      labelText: "Line Metal Type",
      fullLabelText: "AC Line Metal Type",
      options: [
        { value: LineMetalType.Cu, label: "Copper" },
        { value: LineMetalType.Al, label: "Aluminum" },
      ],
      hideIf: ({ ac_line_calculation_method }) => {
        return ac_line_calculation_method !== "computed_resistance";
      },
    },
    ac_line_length: {
      labelText: "Line Length",
      fullLabelText: "AC Line Length",
      unit: "m",
      hideIf: ({ ac_line_calculation_method }) => {
        return ac_line_calculation_method !== "computed_resistance";
      },
    },
    ac_per_km_resistance: {
      labelText: "Resistance Per Kilometer at 20°C",
      fullLabelText: "AC Resistance Per Kilometer at 20°C",
      unit: "Ω/km",
      hideIf: ({ ac_line_calculation_method }) => {
        return ac_line_calculation_method !== "computed_resistance";
      },
    },
    ac_line_cross_section: {
      labelText: "Line Cross Section",
      fullLabelText: "AC Line Cross Section",
      unit: "mm²",
      hideIf: ({ ac_line_calculation_method }) => {
        return ac_line_calculation_method !== "computed_resistance";
      },
    },
  };
