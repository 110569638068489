import { Box, chakra } from "@chakra-ui/react";

export const Well = chakra(Box, {
  baseStyle: {
    bg: "gray.50",
    p: 4,
    borderRadius: 6,
    shadow: "inner",
  },
});
