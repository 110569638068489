import { SVGProps } from "react";

export const TransformerSvgIcon = (p: SVGProps<any>) => {
  return (
    <svg
      id="svg5"
      version="1.1"
      viewBox="0 0 74.526611 52.061989"
      height="20"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g
        id="layer1"
        style={{ display: "inline" }}
        transform="translate(-40.330647,-81.39974)"
      >
        <g id="g9139">
          <g
            id="g13255"
            style={{ strokeWidth: 2.10415, strokeDasharray: "none" }}
            transform="matrix(0.95050211,0,0,0.95050211,5.556991,-15.513956)"
          >
            <g
              id="g13230"
              style={{ strokeWidth: 2.10415, strokeDasharray: "none" }}
            >
              <path
                style={{
                  fill: "none",
                  fillOpacity: 1,
                  stroke: "#000000",
                  strokeWidth: 2.10415,
                  strokeDasharray: "none",
                }}
                d="m 38.636671,154.75086 10.150147,-0.0131 c 0,0 20.022384,0.82057 20.022382,-5.18947 -2e-6,-6.62513 -20.056942,-12.34411 -20.056942,-6.74515 0,5.59855 20.056942,-0.12003 20.056942,-6.74516 0,-6.62513 -20.056942,-12.34385 -20.056942,-6.74516 0,5.59299 20.056942,-0.12003 20.056942,-6.74516 0,-6.62513 -20.056942,-12.34385 -20.056942,-6.74516 0,5.59554 19.973516,0.99256 19.973516,-5.63257 0,-6.01 -19.942246,-6.23498 -19.942246,-6.23498 l -10.168956,-0.0114"
                id="path13216"
              />
              <ellipse
                style={{
                  fill: "#ffffff",
                  fillOpacity: "1",
                  stroke: "#000000",
                  strokeWidth: "2.10415",
                  strokeDasharray: "none",
                }}
                id="ellipse13218"
                cx="103.94474"
                cy="-38.636585"
                rx="0.93213737"
                ry="1"
                transform="rotate(90)"
              />
              <ellipse
                style={{
                  fill: "#ffffff",
                  fillOpacity: "1",
                  stroke: "#000000",
                  strokeWidth: "2.10415",
                  strokeDasharray: "none",
                }}
                id="ellipse13220"
                cx="154.74947"
                cy="-38.682739"
                rx="0.93213737"
                ry="1"
                transform="rotate(90)"
              />
            </g>
            <path
              style={{
                fill: "#ffffff",
                fillOpacity: "1",
                stroke: "#000000",
                strokeWidth: "2.10415",
                strokeDasharray: "none",
              }}
              d="m 73.025442,103.94474 -0.111236,50.80472"
              id="path13240"
            />
          </g>
          <g
            id="g13283"
            transform="matrix(-0.95050211,0,0,0.95050211,149.6309,-15.513956)"
            style={{ strokeWidth: 2.10415, strokeDasharray: "none" }}
          >
            <g
              id="g13279"
              style={{ strokeWidth: 2.10415, strokeDasharray: "none" }}
            >
              <path
                style={{
                  fill: "#ffffff",
                  fillOpacity: "1",
                  stroke: "#000000",
                  strokeWidth: "2.10415",
                  strokeDasharray: "none",
                }}
                d="m 38.636671,154.75086 10.150147,-0.0131 c 0,0 20.022384,0.82057 20.022382,-5.18947 -2e-6,-6.62513 -20.056942,-12.34411 -20.056942,-6.74515 0,5.59855 20.056942,-0.12003 20.056942,-6.74516 0,-6.62513 -20.056942,-12.34385 -20.056942,-6.74516 0,5.59299 20.056942,-0.12003 20.056942,-6.74516 0,-6.62513 -20.056942,-12.34385 -20.056942,-6.74516 0,5.59554 19.973516,0.99256 19.973516,-5.63257 0,-6.01 -19.942246,-6.23498 -19.942246,-6.23498 l -10.168956,-0.0114"
                id="path13273"
              />
              <ellipse
                style={{
                  fill: "#ffffff",
                  fillOpacity: "1",
                  stroke: "#000000",
                  strokeWidth: "2.10415",
                  strokeDasharray: "none",
                }}
                id="ellipse13275"
                cx="103.94474"
                cy="-38.636585"
                rx="0.93213737"
                ry="1"
                transform="rotate(90)"
              />
              <ellipse
                style={{
                  fill: "#ffffff",
                  fillOpacity: "1",
                  stroke: "#000000",
                  strokeWidth: "2.10415",
                  strokeDasharray: "none",
                }}
                id="ellipse13277"
                cx="154.74947"
                cy="-38.682739"
                rx="0.93213737"
                ry="1"
                transform="rotate(90)"
              />
            </g>
            <path
              style={{
                fill: "#ffffff",
                fillOpacity: "1",
                stroke: "#000000",
                strokeWidth: "2.10415",
                strokeDasharray: "none",
              }}
              d="m 73.025442,103.94474 -0.111236,50.80472"
              id="path13281"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
