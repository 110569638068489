import Cookie from "js-cookie";
import { useState } from "react";

type ParsedCookies<T extends string[]> = {
  [key in T[number]]: string | undefined;
};

export function useCookies<T extends string[]>(
  keys: T
): [ParsedCookies<T>, typeof Cookie.set, typeof Cookie.remove] {
  const [cookies, setCookies] = useState(parseCookies<T>(keys));

  function set(...args: Parameters<typeof Cookie.set>) {
    setCookies((prev) => {
      const [name, value] = args;
      return {
        ...prev,
        [name]: value,
      };
    });
    return Cookie.set(...args);
  }

  function remove(...args: Parameters<typeof Cookie.remove>) {
    setCookies((prev) => {
      const [name] = args;
      return {
        ...prev,
        [name]: undefined,
      };
    });
    return Cookie.remove(...args);
  }

  return [cookies, set, remove];
}

function parseCookies<T extends string[]>(keys: T): ParsedCookies<T> {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const target = {} as ParsedCookies<T>;
  return keys.reduce<ParsedCookies<T>>((acc, key: keyof ParsedCookies<T>) => {
    acc[key] = Cookie.get(key);
    return acc;
  }, target);
}
