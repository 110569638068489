import { createSlice, isAnyOf, type PayloadAction } from "@reduxjs/toolkit";
import { assign, pick } from "lodash";
import { type Project } from "~/gql/generated";
import { api, type RunningProjectSimQuery } from "./../../gql/generated";

export type RunningProjectSim = NonNullable<
  RunningProjectSimQuery["projectDuetSim"][number]
>;

export type BlocksBoundingBox = [number, number, number, number];
export interface ProjectState {
  selectedProject: Pick<
    Project,
    "id" | "name" | "created_at" | "blocks_bounding_box"
  > & {
    owner: Pick<Project["owner"], "first_name" | "last_name">;
  };
  blocksBoundingBox: BlocksBoundingBox | null;
  simulationInProgress: RunningProjectSim | null;
}

const initialState: ProjectState = {
  selectedProject: {
    name: "",
    created_at: new Date().toISOString(),
    owner: {
      first_name: "",
      last_name: "",
    },
    id: "",
  },
  blocksBoundingBox: null,
  simulationInProgress: null,
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProject: (
      state,
      action: PayloadAction<Partial<ProjectState["selectedProject"]>>
    ) => {
      assign(
        state.selectedProject,
        pick(action.payload, ["id", "name", "owner", "created_at"])
      );
    },
    setBlocksBoundingBox: (
      state,
      action: PayloadAction<BlocksBoundingBox | null>
    ) => {
      state.blocksBoundingBox = action.payload;
    },
    setSimulationInProgress: (
      state,
      action: PayloadAction<RunningProjectSim | null>
    ) => {
      state.simulationInProgress = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(api.endpoints.RunningProjectSim.matchFulfilled),
      (state, action) => {
        state.simulationInProgress = action.payload.projectDuetSim[0] ?? null;
      }
    );
    builder.addMatcher(
      isAnyOf(api.endpoints.StopRunningSimulation.matchFulfilled),
      (state, action) => {
        if (
          state.simulationInProgress?.id === action.meta.arg.originalArgs.id
        ) {
          state.simulationInProgress = null;
        }
      }
    );
    builder.addMatcher(
      isAnyOf(api.endpoints.RunProjectBlockSimulation.matchFulfilled),
      (state, action) => {
        if (
          action.payload.runProjectBlockSimulation.success &&
          action.payload.runProjectBlockSimulation.duetSimulation
        ) {
          state.simulationInProgress =
            action.payload.runProjectBlockSimulation.duetSimulation;
        }
      }
    );
  },
});

export const { setProject, setBlocksBoundingBox, setSimulationInProgress } =
  projectSlice.actions;
