import { useState } from "react";

export function useStorageState<T>(
  storageKey: string,
  defaultState: T,
  { storage }: { storage: Storage }
) {
  const [state, setState] = useState<T>(() => {
    const storedState = storage.getItem(storageKey);
    if (storedState) {
      return JSON.parse(storedState);
    }
    if (!defaultState) return null;
    storage.setItem(storageKey, JSON.stringify(defaultState));
    return defaultState;
  });

  const setLocalStorageState: typeof setState = (nextState) => {
    setState((cur) => {
      // @ts-expect-error
      const next = typeof nextState === "function" ? nextState(cur) : nextState;

      storage.setItem(storageKey, JSON.stringify(next));

      return next;
    });
  };

  return [state, setLocalStorageState] as const;
}

export function useLocalStorageState<T>(storageKey: string, defaultState: T) {
  return useStorageState<T>(storageKey, defaultState, {
    storage: localStorage,
  });
}

export function useSessionStorageState<T>(storageKey: string, defaultState: T) {
  return useStorageState<T>(storageKey, defaultState, {
    storage: sessionStorage,
  });
}
