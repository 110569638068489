import { DeepRequired } from "react-hook-form";
import { z } from "zod";

export const DateObjectFieldsTMY: Array<keyof TimeRange["start"]> = [
  "month",
  "day",
  "hour",
  "minute",
];

export const DateObjectFieldsSequential: Array<keyof TimeRange["start"]> = [
  "year",
  "month",
  "day",
  "hour",
  "minute",
];

export const defaultTimeRange: DeepRequired<TimeRange> = {
  start: { year: 2023, month: 1, day: 1, hour: 0, minute: 0 },
  end: { year: 2023, month: 12, day: 31, hour: 23, minute: 59 },
};

const timeRange = z.object({
  start: z.object({
    year: z.number().optional(),
    month: z.number(),
    day: z.number(),
    hour: z.number(),
    minute: z.number(),
  }),
  end: z.object({
    year: z.number().optional(),
    month: z.number(),
    day: z.number(),
    hour: z.number(),
    minute: z.number(),
  }),
});

export type TimeRange = z.infer<typeof timeRange>;
