import { SVGProps } from "react";

export const AcWireSvgIcon = (p: SVGProps<any>) => {
  return (
    <svg
      id="svg5"
      version="1.1"
      viewBox="0 0 74.164093 23.022381"
      height="20"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <g
        id="layer1"
        style={{ display: "inline" }}
        transform="translate(-40.330647,-43.581744)"
      >
        <g
          id="g12488"
          transform="translate(-2.8348017,-43.523476)"
          style={{ strokeWidth: "2", strokeDasharray: "none" }}
        >
          <path
            style={{
              fill: "none",
              fillOpacity: "1",
              stroke: "#000000",
              strokeWidth: "2",
              strokeDasharray: "none",
            }}
            d="m 115.40067,108.1276 h -9.60709 c 0,0 0.82057,-20.022384 -5.18947,-20.022382 -6.625129,2e-6 -12.344117,20.056942 -6.745156,20.056942 5.598557,0 -0.12003,-20.056942 -6.745159,-20.056942 -6.625129,0 -12.343852,20.056942 -6.74516,20.056942 5.592992,0 -0.120029,-20.056942 -6.745158,-20.056942 -6.625129,0 -12.343852,20.056942 -6.74516,20.056942 5.595543,0 0.99256,-19.973516 -5.632569,-19.973516 -6.010002,0 -6.23498,19.942246 -6.23498,19.942246 l -9.939107,-0.009"
            id="path12359"
          />
          <ellipse
            style={{
              fill: "#ffffff",
              fillOpacity: "1",
              stroke: "#000000",
              strokeWidth: "2",
              strokeDasharray: "none",
            }}
            id="circle12361"
            cx="45.097584"
            cy="108.05267"
            rx="0.93213737"
            ry="1"
          />
          <ellipse
            style={{
              fill: "#ffffff",
              fillOpacity: "1",
              stroke: "#000000",
              strokeWidth: "2",
              strokeDasharray: "none",
            }}
            id="circle12363"
            cx="115.39739"
            cy="108.1276"
            rx="0.93213737"
            ry="1"
          />
        </g>
      </g>
    </svg>
  );
};
