import { Flex } from "@chakra-ui/react";
import { extractEmailDomain } from "@duet/shared/utils/email";
import { compact, kebabCase, startCase } from "lodash";
import { useEffect } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useAppUrls } from "~/appUrls";
import { LoadingOverlay } from "~/components/LoadingOverlay/LoadingOverlay";
import { NoAccess } from "~/components/NoAccess/NoAccess";
import { useSessionStorageState } from "~/hooks/useStorageState";
import { useAppDispatch } from "~/redux/store";
import { useAuth } from "../auth/AuthContext";
import { useFeatureFlags, useIsFeatureFlagsLoaded } from "../featureFlag/hooks";
import { useActiveUser } from "../user/hooks";
import { setOrganization } from "./organizationSlice";
import { useUserOrganizations } from "./useOrganization";

export const ORG_SESSION_STORAGE_KEY = "organization";

export function OrganizationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { isLoggedIn } = useAuth();
  const urls = useAppUrls();
  const location = useLocation();
  const { organization: orgRouteParam } = useParams();
  const { isOrganizationsEnabled } = useFeatureFlags(["organizations"]);
  const isFeatureFlagsLoaded = useIsFeatureFlagsLoaded();
  const { user } = useActiveUser();
  const { userOrganizations, isLoading } = useUserOrganizations();

  const orgSlugs = userOrganizations.map((org) => org.slug);
  const hasOrgs = orgSlugs.length > 0;
  const isUserInOrg = orgSlugs.includes(orgRouteParam ?? "");

  const [selectedOrganizationSlug, setSelectedOrganizationSlug] =
    useSessionStorageState<string | null>(
      ORG_SESSION_STORAGE_KEY,
      orgRouteParam ?? null
    );

  if (
    orgRouteParam &&
    orgRouteParam !== selectedOrganizationSlug &&
    isUserInOrg &&
    isLoggedIn
  ) {
    setSelectedOrganizationSlug(orgRouteParam);
  }

  if (!isLoggedIn && selectedOrganizationSlug) {
    setSelectedOrganizationSlug(null);
  }

  const selectedOrg = userOrganizations.find(
    (uo) => uo.slug === selectedOrganizationSlug
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isFeatureFlagsLoaded && !isLoading && !isOrganizationsEnabled && user) {
      const domainOrg = extractOrganizationFromEmail(user.email);
      const org = {
        id: domainOrg,
        name: startCase(domainOrg),
        slug: kebabCase(domainOrg),
        allowed_domains: compact([extractEmailDomain(user.email)]),
        user_organization: [
          {
            id: `${user.id}-${domainOrg}`,
            user,
            role: {
              id: "org:owner",
              name: "Organization Owner",
              slug: "org:owner",
            },
            role_id: "org:owner",
          },
        ],
      };

      dispatch(setOrganization(org));
      return;
    }

    dispatch(setOrganization(selectedOrg ?? null));
  }, [selectedOrg, isLoggedIn]);

  const defaultRender = <>{children}</>;

  if (!isFeatureFlagsLoaded || isLoading) {
    return (
      <Flex w="100vw" h="100vh">
        <LoadingOverlay isLoading />;
      </Flex>
    );
  }

  if (!isOrganizationsEnabled || !isLoggedIn) {
    return defaultRender;
  }

  if (!hasOrgs) {
    const to = urls.organizationPicker();

    // this is fucking terrible
    if (
      location.pathname !== to.pathname &&
      !location.pathname.includes("/auth") &&
      !location.pathname.includes("/onboarding")
    ) {
      return <Navigate to={to} />;
    }
    return defaultRender;
  }

  if (!orgRouteParam) {
    if (location.pathname === urls.indexRoute().pathname) {
      const targetOrg = selectedOrganizationSlug || orgSlugs[0];
      const to = urls.setOrganizationSlug(targetOrg).projectOpenRoute();
      return <Navigate to={to} />;
    }

    return defaultRender;
  }

  if (!isUserInOrg) {
    return <NoAccess resource="organization" resourceName={orgRouteParam} />;
  }

  return defaultRender;
}

// rm after implementing organizations
function extractOrganizationFromEmail(email: string) {
  const domain = email.split("@")[1];
  if (!domain) {
    return "organization";
  }
  // trim tld
  const organization = kebabCase(domain.split(".").slice(0, -1).join("-"));
  return organization;
}
