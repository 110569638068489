// import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyD8P09utTF3GSLvWPmx1Z30RtYITzWnZI8",
  authDomain: "enurgen-duet.firebaseapp.com",
  projectId: "enurgen-duet",
  storageBucket: "enurgen-duet.appspot.com",
  messagingSenderId: "43137322719",
  appId: "1:43137322719:web:522aeff24e99411c38236a",
  measurementId: "G-F3L2P5BY2F",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
