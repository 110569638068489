import { skipToken } from "@reduxjs/toolkit/dist/query";

export function skipQuery<T>(
  options: T | string | boolean | null | undefined
): T | typeof skipToken {
  if (!options) {
    return skipToken as typeof skipToken;
  }
  return options as T;
}

export function isAnyQueryLoading(...queries: { isLoading: Boolean }[]) {
  return queries.some((query) => query.isLoading);
}

export function isAnyQueryFetching(...queries: { isFetching: Boolean }[]) {
  return queries.some((query) => query.isFetching);
}
