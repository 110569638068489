import { useEffect } from "react";
import { useLazyUserMeQuery } from "~/gql/generated";
import { useAppSelector } from "~/redux/store";
import { useAuth } from "../auth/AuthContext";

export function useActiveUser() {
  const { isLoggedIn } = useAuth();
  const activeUser = useAppSelector((state) => state.user.user);
  const [fetchUserMe, { isLoading }] = useLazyUserMeQuery();

  useEffect(() => {
    if (activeUser) return;
    if (!isLoggedIn) return;
    fetchUserMe();
  }, [activeUser, isLoggedIn]);

  return {
    user: activeUser,
    isLoading,
    refetch: async () => fetchUserMe(),
  };
}
