import { parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { padStart } from "lodash";

export function formatDate(ts: string | Date, formatString?: string): string;
export function formatDate(
  ts: string | Date | undefined,
  formatString?: string
) {
  if (!ts) return undefined;
  if (ts instanceof Date) ts = ts.toISOString();
  return formatInTimeZone(parseISO(ts), "utc", formatString ?? "yyyy-MM-dd");
}

export function formatLocalTimezone(timezone: number) {
  if (timezone === 0) {
    return "UTC";
  }

  const sign = timezone > 0 ? "+" : "-";
  const absoluteValue = Math.abs(timezone);
  const isHalfHour = absoluteValue - Math.floor(absoluteValue) === 0.5;

  return `UTC${sign}${padStart(String(Math.floor(absoluteValue)), 2, "0")}:${
    isHalfHour ? "30" : "00"
  }`;
}
