import { camelCase, upperFirst } from "lodash";
import { useMemo } from "react";
import { type PascalCase } from "type-fest";
import { useAppSelector } from "~/redux/store";
import { type FlagId } from "./featureFlagSlice";

// TODO: this should be alot more robust, but for now we're just doing a naive storage check
export function useFeatureFlag(flag: FlagId): boolean {
  const flags = useAppSelector((state) => state.featureFlags.flags);
  return flags.includes(flag);
}

type BooleanFlagKey<T extends FlagId> = `is${PascalCase<T>}Enabled`;

type FeatureFlagReturn<T extends FlagId[]> = Record<
  BooleanFlagKey<T[number]>,
  boolean
>;

export function useFeatureFlags<T extends FlagId>(
  flags: T[]
): FeatureFlagReturn<T[]> {
  const _flags = useAppSelector((state) => state.featureFlags.flags);

  return useMemo(() => {
    const featureFlagReturn = flags.reduce<Record<string, boolean>>(
      (acc, flag) => {
        const key = `is${upperFirst(camelCase(flag))}Enabled`;
        acc[key] = _flags.includes(flag);
        return acc;
      },
      {}
    );
    return featureFlagReturn as FeatureFlagReturn<T[]>;
  }, [_flags]);
}

export function useIsFeatureFlagsLoaded() {
  return useAppSelector((state) => state.featureFlags.isLoaded);
}
