import { Button, Kbd } from "@chakra-ui/react";
import { useEffect } from "react";
import { useAuth } from "./AuthContext";

export function useDevLogin() {
  const auth = useAuth();

  function handleLogin(su?: boolean) {
    let user = import.meta.env.VITE_USERNAME;
    if (su) user = user.replace("dev", "dev+su");
    if (import.meta.env.VITE_FIREBASE_DEV_LOGIN === "1") {
      auth.firebaseSignInWithEmailAndPassword(
        user,
        import.meta.env.VITE_PASSWORD
      );
      return;
    }
    auth.localLogin(user, import.meta.env.VITE_PASSWORD);
  }

  useEffect(() => {
    // dev login shortcut
    if (import.meta.env.DEV) {
      const keydownListener = (evt: KeyboardEvent) => {
        const isDevLoginShortcut =
          evt.key === "Enter" && (evt.ctrlKey || evt.metaKey);

        if (isDevLoginShortcut) {
          const isSuper = evt.shiftKey;
          handleLogin(isSuper);
        }
      };
      window.addEventListener("keydown", keydownListener);
      return () => window.removeEventListener("keydown", keydownListener);
    }

    return () => {};
  }, [auth]);

  return (
    import.meta.env.DEV && (
      <Button
        variant={"ghost"}
        size="xs"
        onClick={() => handleLogin()}
        maxW="min-content"
        alignSelf={"center"}
      >
        Dev Login (<Kbd>⌘</Kbd> + <Kbd>ENTER</Kbd>)
      </Button>
    )
  );
}
